import request from '@/utils/request';
import api from '@/api/api';

/**
 * 获取优惠券列表
 * @params
 * merchantInfoId: m_id 店铺id
 */

export const getCorpCode = (params) => {
  return request({
    url: api.common.getCorpCode,
    method: 'get',
    params,
  });
};
