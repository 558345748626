import { showQyTab, getOpenSingle, wxConfig } from '@/ajax/merchant';

export default {
  namespaced: true,
  state: {
    showQyTab: '', // 去推广信息
    openSingle: 'F', // 获取单品推广
    pattern: false, // true 直接展示佣金 false 判断单品推广
    wxConfigFlag: false,
  },
  getters: {
    // 获取全员推广信息
    GET_SHOW_QY_TAB(state) {
      return state.showQyTab;
    },
    // 是否开启微信配置
    GET_WX_CONFIG_FLAG(state) {
      return state.wxConfigFlag;
    },
    GET_PATTERN(state) {
      return state.pattern;
    },
    // 单品推广
    GET_OPEN_SINGLE(state) {
      return state.openSingle;
    },
  },
  mutations: {
    // 设置全员数据
    SET_ShOW_QY_TAB(state, tabs) {
      state.showQyTab = tabs;
    },
    // 设置微信配置
    SET_WX_CONFIG_FLAG(state, flag) {
      state.wxConfigFlag = flag;
    },
    SET_PATTERN(state, pattern) {
      state.pattern = pattern;
    },
    // 单品推广
    SET_OPEN_SINGLE(state, openSingle) {
      state.openSingle = openSingle;
    },
  },
  actions: {
    /**
     * 获取展示推广按钮配置
     */
    async updateShowQyTab({ commit }) {
      try {
        const data = await showQyTab();
        if (data.status === 200) {
          commit('SET_ShOW_QY_TAB', data.message);
          return data.message;
        }
      } catch (error) {
        throw new Error(error);
      }
    },
    /**
     * 获取展示推广按钮配置
     */
    async updateOpenSingle({ commit }) {
      try {
        const data = await getOpenSingle();
        if (data.status === 200) {
          commit('SET_OPEN_SINGLE', data.data.openSingle);
          commit('SET_PATTERN', data.data.pattern);
          return data.openSingle;
        } else {
          commit('SET_OPEN_SINGLE', 'F');
          commit('SET_PATTERN', false);
        }
      } catch (error) {
        commit('SET_OPEN_SINGLE', 'F');
        commit('SET_PATTERN', false);
        throw new Error(error);
      }
    },
    /**
     * 微信授权配置
     * @param {String} url 当前页面链接，编码后
     */
    async openWxConfig({ commit }, url) {
      const { wx } = window;
      try {
        const data = await wxConfig(url);
        if (data.status === 200) {
          const { config } = data;
          wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: config.appid, // 必填，公众号的唯一标识
            timestamp: config.timestamp, // 必填，生成签名的时间戳
            nonceStr: config.nonceStr, // 必填，生成签名的随机串
            signature: config.signature, // 必填，签名，见附录1
            jsApiList: ['chooseImage', 'getLocalImgData'], // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
          });
        }
      } catch (error) {
        throw new Error(error);
      }

      wx.ready(() => {
        commit('SET_WX_CONFIG_FLAG', true);
        wx.error(() => {
          commit('SET_WX_CONFIG_FLAG', false);
        });
      });
    },
  },
};
