<template>
  <div
    v-if="isShow"
    class="btn-list-modal"
    @click.stop="onWrapperClick"
  >
    <div class="btn-wrapper">
      <template v-for="(item, index) in list">
        <Qyyx
          v-if="item.linkUrl === 'qyyx'"
          :key="index"
          :options="item"
        ></Qyyx>
        <div
          v-else
          :key="index"
          class="btn-item"
          @click.stop="onBtnClick(item)"
        >
          <img
            class="btn-icon"
            :src="item.defaultImgUrl"
            alt=""
          >
          <div class="btn-title">
            {{ item.title }}
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Qyyx from '../qyyx';

export default {
  components: {
    Qyyx,
  },
  props: {
    isShowModal: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
    };
  },
  computed: {
    isShow: {
      get() {
        return this.isShowModal;
      },
      set(val) {
        this.$emit('update:isShowModal', val);
      },
    },
    currentData() {
      const startIndex = (this.page.index - 1) * this.page.size;
      return this.allData.slice(startIndex, startIndex + this.page.size);
    },
  },
  methods: {
    onWrapperClick() {
      this.isShow = false;
    },
    onBtnClick(data) {
      this.gotoTarget(data);
      this.isShow = false;
    },
  },
};
</script>

<style scoped lang="scss">
.btn-list-modal {
  position: fixed;
  z-index: 5000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255,255,255,0.8);
  .btn-wrapper {
    position: absolute;
    bottom: 130px;
    right: 0;
    .btn-item {
      display: flex;
      align-items: center;
      width: 212px;
      height: 66px;
      margin-top: 20px;
      background: #fff;
      border-radius: 33px 0 0 33px;
      box-shadow:3px 3px 4px 3px rgba(0, 0, 0, 0.06);
      border: 1px solid #C8C9CC;
      border-right: 0;
      .btn-icon {
        margin-left: 13px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
      .btn-title {
        margin-left: 10px;
        font-weight: bold;
        font-size: 14px;
      }
    }
  }
}
</style>
