<template>
  <div
    v-show="isShow"
    class="pre-custom-footer"
    :class="{'border-r':pageData.radiusStyle&&pageData.radiusStyle==='fillet','distance-box':pageData.style&&pageData.style==='distance'}"
    :style="{background:pageData.backgroundColor,'border-color':pageData.backgroundLineColor,border:pageData.backgroundLineColor?'1px solid '+pageData.backgroundLineColor:'none',boxShadow:pageData.backgroundLineColor?'none':'0 0 15px rgba(0, 0, 0, 0.15)',height: `${pageData.height}px`}"
  >
    <div
      v-for="(item,index) in pageData.list"
      :key="index"
      class="footer-item"
      :style="{color:pageData.textColor,height: `${pageData.height}px`}"
      @click="onBtnClick(item)"
    >
      <template v-if="index === currentIndex">
        <img
          v-if="item.selectImgUrl"
          class="footer-item-img"
          :src="resizeImg(item.selectImgUrl, 'middle')"
          alt
        >
        <i
          v-else
          :style="{color:pageData.selectTextColor}"
          class="xx-icon"
          :class="iconData[item.linkUrl]"
        ></i>
      </template>
      <template v-else>
        <div v-if="item.defaultImgUrl">
          <img
            class="footer-item-img"
            :src="resizeImg(item.defaultImgUrl, 'middle')"
            alt
          >
        </div>
        <i
          v-else
          class="xx-icon"
          :style="{color:pageData.textColor}"
          :class="iconData[item.linkUrl]"
        ></i>
      </template>
      <img
        v-if="pageData.selectImg && currentIndex === index"
        :src="pageData.selectImg"
        class="select-active-img"
      >
      <div
        :style="{color:(index==currentIndex)?pageData.selectTextColor:''}"
        class="footer-item-title"
      >
        {{ item.title }}
      </div>
    </div>
  </div>
</template>
<script>
import miniProgram from '@/common/miniProgram';

export default {
  props: {
    pageData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      currentIndex: -1,
      iconData: {
        home: 'icon-nav-home',
        order: 'icon-nav-orders',
        personal: 'icon-member',
        shopcart: 'icon-shopping-car',
        ticket: 'icon-nav-ticket',
        hotel: 'icon-nav-hotel',
        shop: 'icon-nav-goods',
        strategy: 'icon-nav-raiders',
        route: 'icon-nav-tours',
        repast: 'icon-nav-foods',
        ai_p: 'icon-AIpaiyipai',
        customtel: 'icon-bohao',
      },
      isShow: !(miniProgram.isMP() && miniProgram.isMP() !== 'wx'),
    };
  },
  mounted() {
    // 底部导航被使用页面
    // 页面name和类型对应关系
    const linkObj = {
      Home: 'home',
      orderListV2: 'order',
      Personal: 'personal',
      cart: 'shopcart',
      Custompage: 'custompage',
      ListTicket: 'ticket', // 门票列表
      ListPmsHotel: 'pmsHotel', // 酒店列表
      ListMdse: 'shop', // 商品列表
      ListRepast: 'repast', // 餐饮列表
      ListRoute: 'route', // route列表
    };
    const routeName = this.$route.name;
    const linkObjRouteName = linkObj[routeName];
    const { length } = this.pageData.list;
    if (linkObjRouteName) {
      for (let i = 0; i < length; i++) {
        if (linkObjRouteName === this.pageData.list[i].linkUrl) {
          this.currentIndex = i;
          break;
        }
      }
    }
  },
  methods: {
    select(item, index) {
      this.currentIndex = index;
    },
    onBtnClick(item) {
      // 小程序特定跳转
      if (miniProgram.isMP() && item.linkUrl === 'weChatAppletRouter') {
        miniProgram.redirectTo(item.path);
        return;
      }
      // 小程序内订单列表跳转特殊处理
      if (miniProgram.isMP() && item.linkUrl === 'order') {
        miniProgram.redirectTo('/pages/member/order/index?state=all');
        return;
      }
      this.gotoTarget(item);
    },
  },
};
</script>
<style lang="scss" scoped>
.pre-custom-footer {
  z-index: 999;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  &.distance-box {
    left: 12px;
    right: 12px;
  }
  &.border-r {
    border-radius: 10px 10px 0 0;
  }
  .footer-item {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .select-active-img {
      position: absolute;
      z-index: -5;
      height: 5.5px;
      width: 26px;
      bottom: 10px;
      left: 48%;
      transform: translateX(-50%);
    }
    .footer-item-title {
      font-size: 12px;
    }
    .footer-item-img {
      height: 32px;
      width: 32px;
    }
    .xx-icon {
      font-size: 22px;
      height: 32px;
      line-height: 32px;
    }
  }
}
</style>

