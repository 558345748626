export default {
  namespaced: true,
  state: {
    currentIndex: 0,
  },
  getters: {

  },
  mutations: {
    /**
     * 设置用户信息
     * @param {Object} userInfo 用户信息
     */
    setIndex(state, info) {
      state.currentIndex = info;
    },
  },
  actions: {
    updateUserInfo({ commit }, info) {
      commit('setIndex', info);
    },
  },
};
