import { getAxios, putAxios, postAxios, deleteAxios } from './axios';
import api from './api';
import enscrypt from '@/common/encrypt';
import { idFilter, nameFilter, phoneFilter } from '@/common/common';

const bonusDeductionApi = '/leaguer/api/leaguer/pubs/bonus/deduction';
/**
 * 获取积分抵扣信息
 * @param {string} type 订单类型
 * @param {string} couponCode 优惠券编码
 * @param {string} date 日期
 * @param {number} price 总价格
 * @param {array} ticketList 票信息 [{"productCode":"MP2020120110420319039","num":2,"price":0.51}]
 */
function getBonusDeductionInfo({ type = 'park', couponCode = '', date = '', price, ticketList }) {
  const bonusDeductionJson = JSON.stringify({ totalPrice: price, deductions: ticketList });
  return postAxios(bonusDeductionApi, {
    bonusDeductionJson,
    orderType: type,
    couponCode,
    startTime: date,
  }).then(res => {
    return res.data;
  });
}

/**
 * 获取常用联系人列表
 */
const getContactsList = () => {
  return getAxios(api.contacts.getContactsList, { cipherText: 2 }).then(res => {
    // certNo 兼容老数据，后端目前统一返回使用idCard
    res.data = res.data.map(item => {
      const name = enscrypt.getRSA(item.name);
      const phone = enscrypt.getRSA(item.phone);
      const idCard = enscrypt.getRSA(item.idCard);
      let cyName = '';
      let cyPhone = '';
      let cyIdCard = '';
      if (name) {
        cyName = nameFilter(name);
      }
      if (phone) {
        cyPhone = phoneFilter(phone);
      }
      if (idCard) {
        cyIdCard = idFilter(idCard);
      }
      return {
        ...item,
        cyCertNo: cyIdCard,
        cyName,
        cyPhone,
        cyIdCard,
        certNo: idCard,
        name,
        phone,
        idCard,
      };
    });
    return res;
  });
};

/**
 * 联系人详情
  * @param {int} id 联系人id
 */
const getContactById = (id) => {
  return getAxios(api.contacts.selectContactsById + id, { cipherText: 2 }).then(res => {
    const name = enscrypt.getRSA(res.data.name);
    const phone = enscrypt.getRSA(res.data.phone);
    const idCard = enscrypt.getRSA(res.data.idCard);
    res.data = {
      ...res.data,
      name,
      phone,
      idCard,
    };
    return res;
  });
};

/**
 * 删除常用联系人
  * @param {int} id 联系人id
 */
const deleteContacts = (id) => {
  return deleteAxios(api.contacts.deleteContacts + id);
};

/**
 * 保存联系人
  * @param {int} id 联系人id
  * @param {string} name 名字
  * @param {string} phone 联系电话
  * @param {int} certNo 证件号
  * @param {imgUrl}  faceUrl 人脸
 */
const saveContact = (params = {}) => {
  const para = {
    ...params,
    name: enscrypt.setRSA(params.name),
    phone: enscrypt.setRSA(params.phone),
    idCard: enscrypt.setRSA(params.idCard),
    cipherText: 2,
  };
  return postAxios(api.contacts.saveContacts, para);
};

/**
 * 编辑联系人
  * @param {int} id 联系人id
  * @param {string} name 名字
  * @param {string} phone 联系电话
  * @param {int} certNo 证件号
  * @param {imgUrl}  faceUrl 人脸
 */
const editContact = (params = {}) => {
  const para = {
    ...params,
    name: enscrypt.setRSA(params.name),
    phone: enscrypt.setRSA(params.phone),
    idCard: enscrypt.setRSA(params.idCard),
    cipherText: 2,
  };
  return putAxios(api.contacts.saveContacts, para);
};


/**
  * 获取常用地址
 */
const getAddressList = () => {
  return getAxios('/up/api/user/address/list', { cipherText: 2 }).then(res => {
    res.data = res.data.map(item => {
      const contactsName = enscrypt.getRSA(item.contactsName);
      const contactsPhone = enscrypt.getRSA(item.contactsPhone);
      let cyContactsName = '';
      let cyContactsPhone = '';
      if (contactsName) {
        cyContactsName = nameFilter(contactsName);
      }
      if (contactsPhone) {
        cyContactsPhone = phoneFilter(contactsPhone);
      }
      return {
        ...item,
        contactsName,
        contactsPhone,
        cyContactsName,
        cyContactsPhone,
      };
    });
    return res;
  });
};

const addressUrl = '/up/api/user/address';

/**
  * 新增常用地址
  * @param {string} contactsName 联系人名称
  * @param {string} contactsPhone 联系人手机号
  * @param {string} area 区域
  * @param {string} areaCode 区域编号
  * @param {string} address 详细地址
  * @param {string}  postCode 邮政编码
  * @param {string}  defaulted 是否默认
 */
const addAdress = (params = {}) => {
  const para = {
    ...params,
    contactsName: enscrypt.setRSA(params.contactsName),
    contactsPhone: enscrypt.setRSA(params.contactsPhone),
    cipherText: 2,
  };
  return postAxios(addressUrl, para);
};

/**
  * 编辑常用地址
  * @param {int} id 联系人名称
  * @param {string} contactsName 联系人名称
  * @param {string} contactsPhone 联系人手机号
  * @param {string} area 区域
  * @param {string} areaCode 区域编号
  * @param {string} address 详细地址
  * @param {string}  postCode 邮政编码
  * @param {string}  defaulted 是否默认
 */
const editAdress = (params = {}) => {
  const para = {
    ...params,
    contactsName: enscrypt.setRSA(params.contactsName),
    contactsPhone: enscrypt.setRSA(params.contactsPhone),
    cipherText: 2,
  };
  return putAxios(addressUrl, para);
};

/**
  * 编辑常用地址
  * @param {int} id 联系人名称
 */
const deleteAdress = (id) => {
  return deleteAxios(`${addressUrl}/${id}`);
};


/**
  * 获取用户基础信息
 */
const getUserBaseInfo = () => {
  return getAxios('/up/api/user/info');
};

/**
  * 更新真实姓名
 */
const updateUserRealName = (params = {}) => {
  const para = {
    ...params,
    realName: enscrypt.setRSA(params.realName),
    cipherText: 2,
  };
  return putAxios('/up/api/user/realName', para);
};

/**
  * 更新性别
 */
const updateUserGender = (params = {}) => {
  return putAxios('/up/api/user/gender', params);
};

/**
  * 更新身份证号
 */
const updateUserIdCard = (params = {}) => {
  const para = {
    ...params,
    idCard: enscrypt.setRSA(params.idCard),
    cipherText: 2,
  };
  return putAxios('/up/api/user/idCard', para);
};

/**
  * 更新真实姓名
 */
const updateUserMailbox = (params = {}) => {
  return putAxios('/up/api/user/mailbox', params);
};

/**
  * 获取用户信息
 */
const getLeaguerInfo = (params = {}) => {
  return getAxios('/leaguer/api/userLeaguer/manage/leaguerInfo', { ...params, cipherText: '2' }).then(res => {
    const { data } = res;
    if (data.idcard) {
      data.idcard = enscrypt.getRSA(data.idcard);
    }
    if (data.linkmanMobile) {
      data.linkmanMobile = enscrypt.getRSA(data.linkmanMobile);
    }
    if (data.linkmanName) {
      data.linkmanName = enscrypt.getRSA(data.linkmanName);
    }
    if (data.mobile) {
      data.mobile = enscrypt.getRSA(data.mobile);
    }
    if (data.realName) {
      data.realName = enscrypt.getRSA(data.realName);
    }
    return res;
  });
};


/**
 * 绑定手机号
  * @param {string} phone 联系人id
 */
const bindPhone = (params = {}) => {
  return putAxios('/up/api/user/bind/phone', params);
};
/**
 * 检测手机号
 */
const validationPhone = (params = {}) => {
  return getAxios('/up/api/user/bind/validation', params);
};
export {
  getLeaguerInfo,
  getBonusDeductionInfo,
  getContactsList,
  deleteContacts,
  getContactById,
  saveContact,
  editContact,
  getAddressList,
  addAdress,
  editAdress,
  deleteAdress,
  getUserBaseInfo,
  updateUserRealName,
  updateUserGender,
  updateUserIdCard,
  updateUserMailbox,
  bindPhone,
  validationPhone,
};
