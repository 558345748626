<template>
  <div
    v-if="qyyxFlag"
  >
    <div
      class="btn-item"
      @click.stop="goQyyx"
    >
      <img
        class="btn-icon"
        :src="options.defaultImgUrl"
        alt=""
      >
      <div class="btn-title">
        {{ options.title }}
      </div>
    </div>
    <!-- 是否需要创建全员账号 -->
    <div
      v-if="isShowFlag"
      class="promote-limit"
      @click="close"
    >
      <div class="limit">
        <h3 class="title">
          推广员招募令
        </h3>
        <div class="contain">
          <p class="title-desc mb">
            邀请好友购买产品并核销完成后，即可赚取对应推广佣金!多推多赚,上不封顶
          </p>
          <div
            v-if="keybindingFlag"
            class="item"
          >
            <span class="left-icon">NEW</span>
            <p
              class="main"
              @click="bindPromoter"
            >
              <span class="name">一键成为推广员</span>
              <span class="desc">自动生成推广账号,降低门槛</span>
            </p>
            <i class="xx-icon icon-iconfont-jiantou"></i>
          </div>
          <div
            v-if="bindPromoterFlag"
            class="item vip"
            @click="createdBind"
          >
            <span class="left-icon">VIP</span>
            <p class="main">
              <span class="name">绑定全员营销账号</span>
              <span class="desc">绑定已有账号,同步推广数据</span>
            </p>
            <i class="xx-icon icon-iconfont-jiantou"></i>
          </div>
        </div>
      </div>
      <div
        class="close"
        @click="close"
      >
        <span class="vux-close"></span>
      </div>
    </div>
  </div>
</template>
<script>
/*eslint-disable*/
import axios from 'axios';
import { isWxAli } from '@/common/common';
import { linkBaseUrl, baseUrl, qyyxUrl } from '@/common/config.js';
import vueCookie from 'vue-cookies';
import miniProgram from '@/common/miniProgram'
export default {
  props: {
    options: {
      type: Object,
      default: () => ({})
    },
    left: {
      type: String | Number,
      default: 270,
    },
    top: {
      type: String | Number,
      default: 100,
    },
  },
  data () {
    return {
      merchantPattern: false, // 新老全员
      qyyxFlag: false,
      userImg: '',
      topFlag: false,
      listHeight: 250,
      widthHeight: 115,
      listShow: false,
      distance: 10,
      innerHeight: 0,
      flags: false,
      position: { x: 0, y: 0 },
      nx: '', // x移动距离
      ny: '', // y移动距离
      dx: '',
      dy: '',
      xPum: this.left,
      yPum: this.top,
      screenHeight: window.screen.height,
      screenWidth: window.screen.width,
      keybindingFlag: false,
      bindPromoterFlag: false,
      isShowFlag: false,
      // 是否为小程序环境
      isMiniProgram: miniProgram.isMP(),
    };
  },
  computed: {
    rightFlag () {
      return this.xPum <= this.widthHeight;
    },
  },
  created () {
    axios
      .get(
        `${baseUrl
        }${this.api.main.merchant.getCorpCode
        }?merchantInfoId=${localStorage.getItem('xjsc_vue_2018_12_19_mid')}`,
        {},
      )
      .then(res => {
        this.marketDisAccount =
          res.data && res.data.marketDisAccount
            ? res.data.marketDisAccount
            : '';
      });
    this.getAxios('/merchant/api/merchantInfo/showQyTab').then(res => {
      console.log(123123)
      this.qyyxFlag = !this.isMiniProgram && res.message == 'T';
    });
    axios
      .get(
        `${baseUrl}leaguer/api/userLeaguer/manage/leaguerInfo?merchantInfoId=${localStorage.getItem('xjsc_vue_2018_12_19_mid')}`,
        {},
      )
      .then(
        res => {
          // 是否展示推广
          this.userImg = res.data && res.data.headImg ? res.data.headImg : '';
          // this.qyyxFlag = res.data.qyyxFlag === 'T';
          this.$store.commit('user/setUser', {
            nickName: res.data.nickName,
          });
        },
        error => {
          this.userImg = '';
        },
      );
  },
  mounted () {
    const _this = this;
    _this.innerHeight = _this.getInnerHeight();
    window.addEventListener('scroll', () => {
      _this.innerHeight = _this.getInnerHeight();
    });
  },
  methods: {
    salesPromotion () {
      if (this.merchantPattern) {
        this.$router.push("/workBench");
        return;
      }
      const env = isWxAli();
      let flag = null;
      if (env.isWx) {
        flag = '1';
      } else if (env.isAliPay) {
        flag = '2';
      } else {
        flag = '3';
      }
      const data = {
        flag,
        corpCode: this.marketDisAccount, // req.session.merchantInfo.code
        openId:
          vueCookie.get(`leaguerInfoId_${localStorage.getItem("xjsc_vue_2018_12_19_mid")}`) ||
          localStorage.getItem('xjsc_login_userId_2019_3_8'),
        ixmgUrl: '',
        merchantInfoId: localStorage.getItem('xjsc_vue_2018_12_19_mid'),
      };
      this.getAxios('/leaguer/api/userLeaguer/manage/leaguerInfo').then(res => {
        if (res.status === 200) {
          data.openId = res.data.id;
          if (!this.qyyxFlag) {
            this.getAxios(this.api.member.qyyxOper).then(res => { });
          }
          const url = `${qyyxUrl}/?corpCode=${data.corpCode}&openId=${data.openId
            }&redirectUrl=${encodeURIComponent(window.location.href)}`;
          window.location.href = url;
        }
      });
    },
    goHome () {
      if (this.listShow) {
        this.go('/');
      }
    },
    goCenter () {
      // 如果已经登录，跳转个
      if (this.listShow) {
        let nextPath = '';
        const m_id = localStorage.getItem('xjsc_vue_2018_12_19_mid');

        nextPath =
          `${document.location.protocol
          }//${window.location.host
          }/vue/personal?m_id=${m_id}`;
        localStorage.setItem("xjsc_nextpath_2019_4_20", nextPath);
        window.location.href = nextPath;

      }
    },
    // 判断小鲸用户是否绑定手机号
    checkBindingMobile () {
      let _this = this;
      _this.postAxios(_this.api.sqfx.checkBindingMobile)
        .then(response => {
          if (response.data) {
            _this.bindPromoter();
          } else {
            _this.$router.push('/bind/mobile');
          }
        });
    },
    /**
     * 只有一件创建推广员时不展示，默认创建；
     * @binded : 是否绑定推广员
     * @corpAllowBindPromoter : 是否允许企业绑定推广员
     * @AllowUpPromoter : 是否允许游客成为推广员
     * @pagePromoterFlag ：区分是页面中推广员还是小球中推广员的逻辑
     * @flag :默认false页面中全员营销逻辑，否则是小球逻辑
     */
    goQyyx (flag = false) {
      return new Promise(async (resolve, reject) => {
        const checkInfo = await this.postAxios(
          '/leaguer/api/promoter/checkUserPromoter',
        );
        if (checkInfo.status === 200 && checkInfo.data.isRelationBind) {
          this.bindPromoter();
          return;
        }
        const merchantPattern = await this.postAxios(this.api.sqfx.getMerchantPattern);
        this.merchantPattern = merchantPattern.data;
        if (checkInfo.status === 200 && !checkInfo.data.binded) {
          if (merchantPattern.data) { // true 新全员
            this.checkBindingMobile();
          } else {
            const corpConfig = await this.postAxios(
              '/leaguer/api/promoter/getCorpConfig',
            );
            if (corpConfig.status !== 200) return;
            const corpConfigInfo = corpConfig.data;
            const { corpAllowBindPromoter } = corpConfigInfo;
            const AllowUpPromoter = corpConfigInfo.corpAllowUpPromoter;
            this.isShowFlag = corpAllowBindPromoter;
            if (corpAllowBindPromoter && AllowUpPromoter) {
              this.keybindingFlag = this.bindPromoterFlag = true;
            } else if (corpAllowBindPromoter && !AllowUpPromoter) {
              this.keybindingFlag = false;
              this.bindPromoterFlag = true;
            } else if (!corpAllowBindPromoter && AllowUpPromoter) {
              this.bindPromoterFlag = false;
              this.keybindingFlag = true;
              this.bindPromoter();
            } else if (!corpAllowBindPromoter && !AllowUpPromoter) {
              this.keybindingFlag = this.bindPromoterFlag = false;
              this.$vux.toast.text(
                '未拥有全员营销账号，当前店铺不允许注册与绑定全员营销',
                'top',
              );
            }
          }
        } else if (checkInfo.status == 200 && checkInfo.data.binded) {
          this.keybindingFlag = this.bindPromoterFlag = false;
          if (flag) {
            // 小球全员逻辑
            this.hide();
            this.salesPromotion();
          } else {
            // 执行已绑定全员详情页面中逻辑
            resolve(true);
          }
        }
      });
    },
    hide () {
      this.listShow = false;
      this.topFlag = false;
    },
    show () {
      // console.log(this.xPum);
      // console.log(this.yPum);
      if (this.yPum <= this.innerHeight - this.listHeight) {
        // console.log("下弹");
        this.topFlag = false;
      } else {
        // console.log("上弹");
        this.topFlag = true;
      }
      // if (this.xPum <= this.widthHeight) {
      //   console.log("右弹");
      //   this.rightFlag = true;
      // } else {
      //   console.log("左弹");
      //   this.rightFlag = false;
      // }
      this.$nextTick(() => {
        this.listShow = !this.listShow;
        // this.topFlag = this.listShow;
      });
    },
    getInnerHeight () {
      if (window.innerHeight != undefined) {
        return window.innerHeight;
      } else {
        const B = document.body;
        const D = document.documentElement;
        return Math.min(D.clientHeight, B.clientHeight);
      }
    },
    close () {
      this.isShowFlag = this.keybindingFlag = this.bindPromoterFlag = false;
    },
    // 创建新账号绑定
    bindPromoter () {
      this.postAxios('/leaguer/api/promoter/createUserAuth', {
        accType: 3,
        regChannel: 2,
      }).then(res => {
        if (res.status === 200) {
          this.salesPromotion();
        }
      });
    },
    // 已有账号绑定
    createdBind () {
      const url =
        `${linkBaseUrl
        }/marketing?m_id=${localStorage.getItem('xjsc_vue_2018_12_19_mid')}`;
      window.location.href = url;
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-item {
  display: flex;
  align-items: center;
  width: 212px;
  height: 66px;
  margin-top: 20px;
  background: #fff;
  border-radius: 33px 0 0 33px;
  box-shadow: 3px 3px 4px 3px rgba(0, 0, 0, 0.06);
  border: 1px solid #c8c9cc;
  border-right: 0;
  .btn-icon {
    margin-left: 13px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  .btn-title {
    margin-left: 10px;
    font-weight: bold;
    font-size: 14px;
  }
}
.promote-limit {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1999;
  .limit {
    width: 315px;
    margin: 95px auto 0;
    padding-bottom: 15px;
    background-color: #f7f7f7;
    border-radius: 6px;
    .title {
      line-height: 56px;
      color: #413838;
      text-align: center;
      font-size: 15px;
      background-color: #fff;
      border-bottom: 1px solid #e1e1e1;
    }
    .title-desc {
      padding-top: 14px;
      font-size: 13px;
      line-height: 25px;
      color: #413838;
      background-color: #f7f7f7;
    }
    .contain {
      margin: 0 15px;
      .mb {
        margin-bottom: 20px;
      }
      .item {
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 81px;
        padding-left: 15px;
        background-color: #fff;
        border-radius: 4px;
        .left-icon {
          width: 60px;
          height: 60px;
          border-radius: 30px;
          background-color: #009ded;
          text-align: center;
          line-height: 60px;
          color: #fff;
          font-size: 14px;
        }
        .main {
          flex: 1;
          padding: 15px 0 15px 10px;
          span {
            display: block;
            color: #999;
          }
          .name {
            font-size: 15px;
            line-height: 25px;
            margin-bottom: 3px;
            color: #009ded;
          }
          .desc {
            font-size: 13px;
            line-height: 23px;
            background: #fff;
          }
        }
        .icon-iconfont-jiantou {
          width: 18px;
          padding-right: 10px;
        }
      }
      .vip {
        margin-top: 15px;
        .left-icon {
          background-color: #eb5c56;
        }
        .name {
          color: #eb5c56;
        }
      }
    }
  }
  .close {
    display: flex;
    justify-content: center;
    margin-top: 24px;
  }
  .vux-close {
    position: relative;
    color: #999;
    width: 24px;
    height: 24px;
    &:before,
    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 11px;
      width: 24px;
      height: 1px;
      background-color: #fff;
      transform: rotate(-45deg);
    }
    &:after {
      transform: rotate(45deg);
    }
  }
}
</style>
