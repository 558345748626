/**
 * 订单路由
 */
const orderRoute = [
  {
    path: '/order/list',
    name: 'orderListV2',
    component: () => import(/* webpackChunkName: "route-order" */ '@/pages/order/list'),
  },
  {
    path: '/order/detail',
    name: 'orderDetailV2',
    component: () => import(/* webpackChunkName: "route-order" */ '@/pages/order/index'),
  },
  {
    path: '/order/rate',
    name: 'orderRateV2',
    component: () => import(/* webpackChunkName: "route-order" */ '@/pages/order/rate'),
  },
  {
    path: '/order/refund',
    name: 'orderRefundV2',
    component: () => import(/* webpackChunkName: "route-order" */ '@/pages/order/refund'),
  },
  {
    path: '/order/qrcode',
    name: 'orderQrcodeV2',
    component: () => import(/* webpackChunkName: "route-order" */ '@/pages/order/qrcode'),
  },
  {
    path: '/importOrder',
    name: 'importOrder',
    component: () => import(/* webpackChunkName: "route-order" */ '@/pages/order/importOrder'),
  },
];

export default orderRoute;
