const preSaleRoute = [{
  path: '/preList',
  name: 'preList',
  component: () => import(/* webpackChunkName: "route-pre-sale" */ '@/pages/preSale/preList'),
  meta: {
    title: '预售列表',
  },
},
{
  path: '/preDetail/:id',
  name: 'preDetail',
  component: () => import(/* webpackChunkName: "route-pre-sale" */ '@/pages/preSale/preDetail'),
  meta: {
    title: '预售酒店详情',
  },
},
{
  path: '/preOrder/:id',
  name: 'preOrder',
  component: () => import(/* webpackChunkName: "route-pre-sale" */ '@/pages/preSale/preOrder'),
  meta: {
    title: '预售订单',
  },
},
{
  path: '/preOrderPay',
  name: 'preOrderPay',
  redirect: '/preOrderPay/refund',
  component: () => import(/* webpackChunkName: "route-pre-sale" */ '@/pages/preSale/preOrderPay'),
  children: [{
    path: 'refundNew/:id',
    name: 'refundNew',
    component: () => import(/* webpackChunkName: "route-pre-sale" */ '@/pages/preSale/preOrderPay/refund'),
    meta: {
      title: '退款',
    },
  },
  {
    path: 'appointment/:id',
    name: 'appointment',
    component: () => import(/* webpackChunkName: "route-pre-sale" */ '@/pages/preSale/preOrderPay/appointment'),
    meta: {
      title: '预约',
    },
  },
  ],
},
{
  path: '/appointmentView/:id',
  name: 'appointmentView',
  component: () => import(/* webpackChunkName: "route-pre-sale" */ '@/pages/preSale/preOrderPay/appointmentView'),
  meta: {
    title: '预约券列表',
  },
},
// {
//   path: '/preOrderList',
//   name: 'preOrderList',
//   component: () => import(/* webpackChunkName: "route-pre-sale" */ '@/pages/preSale/preOrderList'),
//   meta: {
//     title: '预约券列表'
//   }
// },
{
  path: '/preOrderDetail/:id',
  name: 'preOrderDetail',
  component: () => import(/* webpackChunkName: "route-pre-sale" */ '@/pages/preSale/preOrderList/orderDetail'),
  meta: {
    title: '预售订单详情',
  },
},
{
  path: '/prePayResult/:id',
  name: 'prePayResult',
  component: () => import(/* webpackChunkName: "route-pre-sale" */ '@/pages/preSale/prePayResult'),
  meta: {
    title: '支付结果',
  },
},
{
  path: '/payFailed/:id',
  name: 'payFailed',
  component: () => import(/* webpackChunkName: "route-pre-sale" */ '@/pages/preSale/prePayResult/payFailed'),
  meta: {
    title: '支付失败',
  },
},
{
  path: '/preBuyerOrder',
  name: 'preBuyerOrder',
  component: () => import(/* webpackChunkName: "route-pre-sale" */ '@/pages/preSale/preBuyerOrder'),
  meta: {
    title: '订单列表',
  },
},
];

export default preSaleRoute;
