const state = () => ({
  // 门票对应日期
  ticketDate: '',
  // 订单门票信息
  ticketOrder: [],
  // 订单套票信息
  ticketsOrder: [],
});

const getters = {
  ticketsTotalNum: (baseState) => baseState.ticketsOrder.reduce((result, current) => {
    return result + current.num;
  }, 0),
  // 套票总价
  ticketsTotalPrice: (baseState) => baseState.ticketsOrder.reduce((result, current) => {
    return result + (current.num * current.salePrice * 100);
  }, 0) / 100,
  // 门票总价
  ticketTotalPrice: (baseState) => baseState.ticketOrder.reduce((result, current) => {
    return result + (current.num * current.priceSettle * 100);
  }, 0) / 100,
  // 门票优惠券编码
  ticketCouponCodes: (baseState) => {
    const resultList = [];
    baseState.ticketOrder.forEach(item => {
      const totalPrice = item.priceSettle * item.num;
      resultList.push(`${item.modelCode}-${totalPrice.toFixed(2)}`);
    });
    return resultList.join(',');
  },
};

const actions = {

};

const mutations = {
  updateTicketDate(baseState, ticketDate) {
    baseState.ticketDate = ticketDate;
  },
  updateTicketOrder(baseState, ticketOrder) {
    baseState.ticketOrder = ticketOrder;
  },
  updateTicketsOrder(baseState, ticketsOrder) {
    baseState.ticketsOrder = ticketsOrder;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
