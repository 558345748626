/* eslint-disable array-callback-return */
<template>
  <transition name="slide-left">
    <div
      v-show="pickerShow"
      class="_wb-date-picker"
    >
      <div class="_wb-date-header">
        <div
          v-for="(d, i) in daysOrder"
          :key="i"
        >
          {{ d }}
        </div>
      </div>
      <div
        v-for="(l, i) in daysList"
        :key="i"
        class="_wb-date"
      >
        <div class="_wb-date-title">
          {{ l.time }}
        </div>
        <div class="_wb-date-lists">
          <div
            v-for="(d, index) in l.list"
            :key="index"
            :data-d="`${i}-${index}`"
            :first-label="selectFirstLabel"
            :last-label="selectLastLabel"
            :current-label="selectCurrentLabel"
            class="_wb-date-item"
            :class="[
              d.isToday ? '_wb-date-today' : '',
              d.isMonthOverdue ? '_wb-disabled' : '',
              d.isInExpirationDate ? '' : '_wb-disabled',
              selectArray[0] === `${i}-${index}` ? '_wb-first' : '',
              (selectArray[selectArray.length - 1] === `${i}-${index}`) && selectArray.length > 1 ? '_wb-last' : '',
              selectArray.indexOf(`${i}-${index}`) > -1 ? '_wb-select' : ''
            ]"
            @click="dayClick(d, i, index)"
          >
            <slot v-if="showDefault">
              <span>
                <span v-if="selectArray[0] === `${i}-${index}`">{{ selectFirstLabel }}</span>
                <span v-if="(selectArray[selectArray.length - 1] === `${i}-${index}`) && selectArray.length > 1">{{ selectLastLabel }}</span>
              </span>
              <span>{{ d.isToday?'今天':d.d }}</span>
            </slot>
            <slot
              v-else
              :d="d"
              :isFirst="selectArray[0] === `${i}-${index}`"
              :isLast="(selectArray[selectArray.length - 1] === `${i}-${index}`) && selectArray.length > 1"
              name="custom"
            >
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { Toast } from '@sdi/fish';

export default {
  name: 'DatePicker',
  props: {
    // 操作day生成，用于接口返回数据的调整
    dayFn: {
      type: Function,
      default: () => { return {}; },
    },
    value: {
      type: Boolean,
      default: false,
    },
    /*
     * 选择开始时间后，往后自动选取多少天
     * Infinity 表示禁用此功能
     */
    autoSelectRange: {
      type: Number,
      default: Infinity,
    },
    /*
     * 选择的天数是否需要等于autoSelectRange
     * eg:autoSelectRange=10
     */
    selectedFixed: {
      type: Boolean,
      default: false,
    },
    /*
     * 选择日历可选取的有效时间段，无效日期会被禁用
     */
    expirationStartDate: {
      type: String,
      default: '',
    },
    expirationEndDate: {
      type: String,
      default: '',
    },
    /**
     * 开始，结束，以及当前label配置
     */
    selectFirstLabel: {
      type: String,
      default: '开始',
    },
    selectLastLabel: {
      type: String,
      default: '结束',
    },
    selectCurrentLabel: {
      type: String,
      default: '',
    },
    // 是否对日期选择结果做判断提示，针对autoSelectRange>1的情况
    isNeedJudge: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      today: {},
      daysOrder: ['日', '一', '二', '三', '四', '五', '六'],
      daysList: [],
      prevDate: new Date(),
      nextDate: new Date(),
      selectArray: [],
      firstIndex: '',
      lastIndex: '',
      pickerShow: false,
      showDefault: true, // 默认使用组件自有的slot
    };
  },
  computed: {
    startEndTime() {
      return `${this.expirationStartDate || ''}||${this.expirationEndDate || ''}`;
    },
  },
  watch: {
    selectArray(value) {
      if (value.length === 0) {
        return;
      }
      const [fi, findex] = value[0].split('-');
      const [li, lindex] = value[value.length - 1].split('-');
      const { y: fy, m: fm, d: fd } = this.daysList[fi].list[findex];
      const { y: ly, m: lm, d: ld } = this.daysList[li].list[lindex];
      if (value.length >= 2) {
        this.$emit('handleSelect', {
          startTime: `${fy}/${fm}/${fd}`,
          endTime: `${ly}/${lm}/${ld}`,
        });
      } else if (value.length === 1 && this.autoSelectRange === 1) {
        /**
         * 处理单选
         */
        this.$emit('handleSelect', {
          startTime: `${fy}/${fm}/${fd}`,
          endTime: `${ly}/${lm}/${ld}`,
        });
      }
    },
    value(value) {
      this.pickerShow = value;
    },
    startEndTime() {
      this.startInit();
    },
  },
  mounted() {
    this.pickerShow = this.value;
    this.slotShow();
    this.setToday();
    this.startInit();
  },
  methods: {
    slotShow() {
      if (this.$slots.custom || this.$scopedSlots.custom) {
        this.showDefault = false;
      } else {
        this.showDefault = true;
      }
    },
    startInit() {
      // 重置
      this.daysList = [];
      this.selectArray = [];
      this.preDate = new Date();
      this.nextDate = new Date();
      this.loadMonthList();
      this.loadMonthList('next');
      this.loadMonthList('next');
    },
    setToday() {
      const t = new Date();
      this.today = {
        y: t.getFullYear(),
        m: t.getMonth() + 1,
        d: t.getDate(),
      };
    },
    loadMonthList(type) {
      if (type === 'pre') {
        this.prevDate = this.getPreMonth(this.prevDate);
        const currentMonthList = this.initDate({
          myDate: new Date(this.prevDate),
        });
        this.daysList.unshift({
          time: this.prevDate.slice(0, 7),
          list: currentMonthList,
          type,
        });
      } else if (type === 'next') {
        this.nextDate = this.getNextMonth(this.nextDate);
        const currentMonthList = this.initDate({
          myDate: new Date(this.nextDate),
          isCurrent: true,
        });
        this.daysList.push({
          time: this.nextDate.slice(0, 7),
          list: currentMonthList,
          type,
        });
      } else {
        const myDate = new Date();
        const y = myDate.getFullYear();
        const m = myDate.getMonth() + 1;
        // const d = myDate.getDate();
        const currentMonthList = this.initDate({
          myDate: new Date(),
        });
        this.daysList.unshift({
          time: `${y}/${m < 10 ? `0${ m}` : m}`,
          list: currentMonthList,
          type: 'current',
        });
      }
    },
    initDate({ myDate = new Date() }) {
      const y = myDate.getFullYear();
      const m = myDate.getMonth() + 1;
      // const d = myDate.getDate();
      let firstLine = 0;
      const daysList = [];
      const currentfirstDay = new Date(`${y}/${m}/1`).getDay() || 7;
      const currentTotalDays = this.getMonthLength(`${y}/${m}/1`);
      // const prevTotalDays = this.getMonthLength(`${y}/${m - 1}/1`);
      /**
       * 日， 一， 二， 三， 四， 五，六 ----> currentfirstDay === 7
       * 一， 二， 三， 四， 五， 六，日 ----> currentfirstDay === 1
       */
      if (currentfirstDay === 7) {
        // 若生成的当月的第一天是周日则第一行日期都是上个月的
        firstLine = 7;
      } else {
        /**
         * 日， 一， 二， 三， 四， 五，六 ----> firstLine = currentfirstDay
         * 一， 二， 三， 四， 五， 六，日 ----> firstLine = currentfirstDay - 1
         */
        firstLine = currentfirstDay;
      }
      if (firstLine < 7) {
        for (let i = firstLine; i > 0; i--) {
          daysList.push({});
        }
      }
      for (let i = 0; i < currentTotalDays; i++) {
        const isToday =
          i + 1 === this.today.d && y === this.today.y && m === this.today.m;
        const d = i + 1 < 10 ? `0${ i + 1}` : i + 1;
        const dayString = `${y}-${m < 10 ? (`0${m}`) : m}-${d}`;
        const dayObj = {
          d,
          y,
          m,
          dayString,
          bottomLabel: isToday ? '今天' : '',
          isToday,
          isSelect: false,
          isMonthOverdue:
            new Date(`${y}/${m}/${i + 1}`).getTime() <
            new Date(
              `${this.today.y}/${this.today.m}/${this.today.d}`,
            ).getTime(),
          isInExpirationDate: this.isInExpirationDate(y, m, i + 1),
        };
        daysList.push({ ...dayObj, ...this.dayFn(dayObj) });
      }
      return daysList;
    },
    isInExpirationDate(y, m, d) {
      // 判断当前日期是否处于有效期

      const current = new Date(`${y}/${m < 10 ? `0${m}` : m}/${d < 10 ? `0${d}` : d}`).getTime();
      const start = this.expirationStartDate
        ? new Date(this.expirationStartDate.replace(/-/g, '/')).getTime()
        : 0;
      const end = this.expirationEndDate
        ? new Date(this.expirationEndDate.replace(/-/g, '/')).getTime()
        : 0;

      if (start > end) {
        return;
      }

      if (start !== 0 && end !== 0) {
        return current >= start && current <= end;
      } else if (start !== 0 && end === 0) {
        return current >= start;
      } else if (start === 0 && end === 0) {
        return current <= end;
      } else {
        return true;
      }
    },
    getMonthLength(date) {
      const d = new Date(date);
      // 将日期设置为下月一号
      d.setMonth(d.getMonth() + 1);
      d.setDate('1');
      // 获取本月最后一天
      d.setDate(d.getDate() - 1);
      return d.getDate();
    },
    getNextMonth(nowdays) {
      nowdays = typeof nowdays === 'object' ? nowdays : new Date(nowdays);
      let year = nowdays.getFullYear();
      let month = nowdays.getMonth() + 2;
      if (month === 13) {
        month = 1;
        year += 1;
      }
      if (month < 10) {
        month = `0${ month}`;
      }
      // const myDate = new Date(year, month, 0);
      const lastDay = `${year }/${ month }/01`; // 下个月的最后一天
      return lastDay;
    },
    dayClick(item, i, index) {
      /**
       * 点击函数
       */
      if (Object.keys(item).length === 0 || item.isMonthOverdue) {
        return;
      }
      // 缓存原始数据，用来处理点击失败后，重置数据
      this.originSelectArr = JSON.parse(JSON.stringify(this.selectArray));
      const l = this.selectArray.length;

      if (l < 2) {
        if (this.autoSelectRange === 1) {
          /**
           * 自动选取(autoSelectRange) === 1 时，处理单选逻辑
           */
          this.selectArray = [`${i}-${index}`];
          this.$emit('input', false);
          return;
        }
        /**
         * 第一二次点击逻辑
         */
        const [_index, _i] = this.firstIndex.split('-');
        const isSameClick = `${i}-${index}` === `${_index}-${_i}`;

        l === 0 && this.setFirst(item, i, index);

        if (l === 1 && !isSameClick) {
          this.setLast(item, i, index);
          this.selectArray.splice(1, 0, ...this.getDealDayList());
        }

        {
          const [_sindex, _si] = this.firstIndex.split('-');
          // console.log(_index, _i);
          const { y, m, d } = this.daysList[_sindex].list[_si];

          if (
            new Date(`${item.y}/${item.m}/${item.d}`) <=
            new Date(`${y}/${m}/${d}`).getTime()
          ) {
          /**
             * 当第二次点击的时间小于第一次点击的时间，重置
             */
            this.selectArray = [`${i}-${index}`];
            this.setFirst(item, i, index);
          } else {
            !isSameClick && this.selectArray.push(`${i}-${index}`);
          }

          this.autoSelectRangeDeal(item, i, index);
        }
      } else {
        this.setFirst(item, i, index);
        // const [_index, _i] = this.lastIndex.split('-');

        this.selectArray = [`${i}-${index}`]; // 每三次点击，重置选中项

        this.autoSelectRangeDeal(item, i, index);
      }
    },
    setFirst(item, i, index) {
      this.firstIndex = `${i}-${index}`;
    },
    setLast(item, i, index) {
      this.lastIndex = `${i}-${index}`;
    },
    getDealDayList() {
      const [_FIndex, _Fi] = this.firstIndex.split('-');
      const [_Lindex, _Li] = this.lastIndex.split('-');
      const listArray = JSON.parse(JSON.stringify(this.daysList)).slice(
        _FIndex,
        Number(_Lindex) + 1,
      );
      const lastArray = [];
      const l = listArray.length;

      listArray.forEach((item, index) => {
        const realIndex = Number(_FIndex) + index;
        if (index === 0) {
          if (l === 1) {
            /**
             * 长度1，表示不跨月
             */
            item.list.forEach((d, i) => {
              i > _Fi &&
                i < _Li &&
                Object.keys(d).length > 0 &&
                lastArray.push(`${realIndex}-${i}`);
            });
          } else {
            item.list.forEach((d, i) => {
              i > _Fi &&
                Object.keys(d).length > 0 &&
                lastArray.push(`${realIndex}-${i}`);
            });
          }
        } else if (index === listArray.length - 1) {
          item.list.forEach((d, i) => {
            i < _Li &&
              Object.keys(d).length > 0 &&
              lastArray.push(`${realIndex}-${i}`);
          });
        } else {
          item.list.forEach((d, i) => {
            Object.keys(item).length > 0 && lastArray.push(`${realIndex}-${i}`);
          });
        }
      });

      return lastArray;
    },
    autoSelectRangeDeal(item, i, index) {
      /**
       * 自动选取处理函数
       */
      if (
        this.autoSelectRange === Infinity ||
        this.autoSelectRange === Infinity
      ) {
        return;
      }
      let expirationEndDateTime = 0;
      this.expirationEndDate &&
        (expirationEndDateTime = new Date(this.expirationEndDate).getTime());
      const canChooseTime =
        expirationEndDateTime + 24 * 60 * 60 * 1000 * this.autoSelectRange;
      const listArray = [];
      this.daysList.forEach((day, d) => {
        d >= i &&
          day.list.forEach((_day, _d) => {
            const curTime = new Date(`${_day.y}/${_day.m}/${_day.d}`).getTime();
            if (d === i) {
              listArray.length < this.autoSelectRange - 1 &&
                Object.keys(_day).length > 0 &&
                _d > index &&
                expirationEndDateTime !== 0 &&
                curTime <= canChooseTime &&
                listArray.push(`${d}-${_d}`);
            } else {
              listArray.length < this.autoSelectRange - 1 &&
                Object.keys(_day).length > 0 &&
                expirationEndDateTime !== 0 &&
                curTime <= canChooseTime &&
                listArray.push(`${d}-${_d}`);
            }
          });
      });
      // 设置selectArray的值
      this.setSelectArrayValue(listArray);
    },
    setSelectArrayValue(listArray) {
      const concatArr = this.selectArray.concat(listArray);
      // 酒店离住日期不包含最后一天的是否有效校验
      const sliceArr = concatArr.slice(0, -1);
      const canSeletBollean = sliceArr.some(it => {
        const itArr = it.split('-');
        return !this.daysList[itArr[0]].list[itArr[1]].isInExpirationDate;
      });
      if (canSeletBollean && this.isNeedJudge) {
        Toast('预约时间超出有效期时间，请选择其他时间');
        this.selectArray = this.originSelectArr;
        return false;
      }

      if (this.selectedFixed && concatArr.length !== this.autoSelectRange) {
        Toast('预约时间超出有效期时间，请选择其他时间');
        this.selectArray = this.originSelectArr;
        return false;
      }

      this.selectArray = concatArr;
      // 返回所有选中日期数据
      this.returnSeletedDaysInfo();
      this.$emit('input', false);
    },
    returnSeletedDaysInfo() {
      // 选择的天数一定要和range长度相等
      const returnDaysArr = this.selectArray.map(item => {
        const indexArr = item.split('-');
        const dayInfo = this.daysList[indexArr[0]].list[indexArr[1]];
        return dayInfo;
      });
      this.$emit('getDateRange', JSON.parse(JSON.stringify(returnDaysArr)));
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
.slide-left-enter-active {
  animation: left-in 300ms linear;
}

.slide-left-leave-active {
  animation: left-in 300ms reverse linear;
}

@keyframes left-in {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
</style>
