import vm from 'vue';
import { getDiscount } from '@/ajax/member';
import {
  getCartList,
  getMerchantMdseDetailByCode,
  removeCartItem,
  getSkuList,
  getReviewSkuList,
} from '@/ajax/mdse';

export default {
  namespaced: true, // 不要修改
  state: {
    // 购物车
    cartInfo: {}, // 原始购物车数据
    cartlist: [], // 初始化后的购物车列表
    selectCarts: [], // 选中的购物车
    totalPrice: 0, // 购物车商品总价
    // 详情
    skuList: [],
    sellPriceSpace: null,
    priceShowSpace: [],
    // 订单
    orderSkuList: [], // 下单缓存数据
  },
  getters: {
    // 购物车原始数据
    GET_CART_INFO(state) {
      return state.cart;
    },
    // 购物车列表
    GET_CART_LIST(state) {
      return state.cartlist;
    },
    // 购物车数量
    GET_CART_NUM(state) {
      return state.cartlist.length;
    },
    GET_SELECT_CART(state) {
      return state.selectCarts;
    },
    // 获取选中购物车商品总价
    GET_TOTAL_PRICE(state) {
      return state.totalPrice;
    },
    GET_ORDER_SKU_LIST(state) {
      return state.orderSkuList;
    },
    // 获取商品SKU
    GET_SKU_LIST(state) {
      return state.skuList;
    },
    // 获取商品价格区间
    GET_SELLPRICE_SPACE(state) {
      return state.sellPriceSpace;
    },
    // 获取商品门市价格区间
    GET_PRICESHOW_SPACE(state) {
      return state.priceShowSpace;
    },

  },
  mutations: {
    /* --------------------------商品详情-----------------------------*/
    /**
     * 设置SKU列表
     * @param {Array} skuList 传入数据
     */
    SET_SKU_LIST(state, skuList) {
      vm.set(state, 'skuList', skuList);
    },
    /**
     * 设置价格区间
     * @param {Number | Array} value
     */
    SET_SELLPRICE_SPACE(state, value) {
      vm.set(state, 'sellPriceSpace', value);
    },
    /**
     * 设置门市价格区间
     * @param {Number | Array} value
     */
    SET_PRICESHOW_SPACE(state, value) {
      vm.set(state, 'priceShowSpace', value);
    },
    /* ---------------------------购物车-----------------------------*/
    /**
     * 设置购物车数据
     * @param {Object} cartInfo 购物车数据
     */
    SET_CART_INFO(state, cartInfo) {
      state.cartInfo = cartInfo;
    },
    /**
     * 设置购物车列表
     * @param {Array} cartList 传入数据
     */
    SET_CART_LIST(state, cartList) {
      vm.set(state, 'cartlist', cartList);
    },
    /**
     * 设置单个购物车商品的属性
     * @param {Number} index 位置
     * @param {Array} target 修改的属性组 [{key: ,value: }]
     */
    SET_CART(state, { index, target = {} }) {
      for (const key in target) {
        if (Object.hasOwnProperty.call(target, key)) {
          vm.set(state.cartlist[index], key, target[key]);
        }
      }
    },
    /**
     * 设置选中的购物车商品列表
     * @param {Array} list 选中的商品列表
     */
    SET_SELECT_CART(state, list) {
      vm.set(state, 'selectCarts', list);
    },
    /**
     * 删除指定购物车商品
     * @param {Number} index 位置
     */
    SET_DELETE_CART(state, index) {
      state.cartlist.splice(index, 1);
    },
    /**
     * 计算购物车总价
     */
    SET_TOTAL_PRICE(state, isSetSelectCarts) {
      const cartList = state.cartlist;
      let total = 0;
      const seleltCart = [];
      for (let index = 0; index < cartList.length; index++) {
        const cart = cartList[index];
        if (!cart.isSelected) continue;
        let price;
        if (cart.useLowerPrice !== 'normal') {
          // 超过限购数量，按照销售价处理
          if (cart.useLowerPrice === 'active' && cart.amount > cart.marketingActivityPurchaseLimit) {
            price = cart.sellPrice;
          } else {
            price = cart.lowerPrice;
          }
        } else {
          price = cart.sellPrice;
        }
        total += price * cart.amount;
        if (isSetSelectCarts) {
          seleltCart.push(cart);
        }
      }
      vm.set(state, 'totalPrice', total);

      if (isSetSelectCarts) {
        vm.set(state, 'selectCarts', seleltCart);
      }
    },
    /**
     * 缓存下单数据
     * @param {Array} list 缓存去下单的sku数据
     */
    SET_ORDER_SKU_LIST(state, list = []) {
      vm.set(state, 'orderSkuList', list);
    },
  },
  actions: {
    /* --------------------------商品详情-----------------------------*/
    /**
     * 获取商品SKU
     * @param {Number | String} merchantMsdeInfoId 商品id
     */
    async getSkuList({ commit, dispatch }, merchantMsdeInfoId) {
      try {
        const result = await getSkuList(merchantMsdeInfoId);
        if (result.status === 200) {
          const codePrices = [];
          const priceArray = [];
          const priceShowArray = [];
          let index = 0;
          const leng = result.data.length;
          for (; index < leng; index++) {
            const sku = result.data[index];
            priceArray.push(sku.sellPrice);
            priceShowArray.push(sku.priceShow);
            const specParam = sku.specParam.split(',').map(it => JSON.parse(it));
            vm.set(sku, 'specParam', specParam);
            vm.set(sku, 'selected', false);

            codePrices.push(`${sku.modelCode}:${sku.sellPrice}`);
          }

          let priceSpace;
          if (priceArray.length > 1) {
            const maxPrice = Math.max(...priceArray);
            const minPrice = Math.min(...priceArray);
            priceSpace = maxPrice === minPrice ? maxPrice : [minPrice, maxPrice];
          } else if (priceArray.length === 1) {
            priceSpace = priceArray[0];
          } else {
            priceSpace = '--';
          }


          commit('SET_SKU_LIST', result.data);
          commit('SET_SELLPRICE_SPACE', priceSpace);
          commit('SET_PRICESHOW_SPACE', priceShowArray);
          dispatch('setMemberPrice', { codePrices: codePrices.join(','), from: 'detail' });
        }
      } catch (error) {
        throw new Error(error);
      }
    },
    /**
     * 获取商品审核SKU
     * @param {Number | String} merchantMsdeInfoId 商品id
     */
    async getReviewSkuList({ commit, dispatch }, merchantMsdeInfoId) {
      try {
        const result = await getReviewSkuList(merchantMsdeInfoId);
        if (result.status === 200) {
          const codePrices = [];
          const priceArray = [];
          let index = 0;
          const leng = result.data.length;
          for (; index < leng; index++) {
            const sku = result.data[index];
            priceArray.push(sku.sellPrice);
            const specParam = sku.specParam.split(',').map(it => JSON.parse(it));
            vm.set(sku, 'specParam', specParam);
            vm.set(sku, 'selected', false);

            codePrices.push(`${sku.modelCode}:${sku.sellPrice}`);
          }
          if (leng > 0) {
            vm.set(result.data[0], 'selected', true); // sku默认数据
          }

          let priceSpace;

          if (priceArray.length > 1) {
            const maxPrice = Math.max(...priceArray);
            const minPrice = Math.min(...priceArray);
            priceSpace = maxPrice === minPrice ? maxPrice : [minPrice, maxPrice];
          } else if (priceArray.length === 1) {
            priceSpace = priceArray[0];
          } else {
            priceSpace = '--';
          }

          commit('SET_SKU_LIST', result.data);
          commit('SET_SELLPRICE_SPACE', priceSpace);
          dispatch('setMemberPrice', { codePrices: codePrices.join(','), from: 'detail' });
        }
      } catch (error) {
        throw new Error(error);
      }
    },
    /* ---------------------------购物车-----------------------------*/
    /**
     * 初始化购物数据
     */
    async initCartData({ commit, dispatch }) {
      let cartItemDtos;
      try {
        // 获取购车数据
        const cartInfo = await getCartList();
        if (cartInfo && cartInfo.status === 200) {
          cartItemDtos = cartInfo.data.cartItemDtos;
          commit('SET_CART_INFO', cartInfo);
        }
      } catch (error) {
        throw new Error(error);
      }
      const codeArray = [];
      if (cartItemDtos instanceof Array && cartItemDtos.length > 0) {
        commit('SET_CART_LIST', cartItemDtos);
        for (let index = 0; index < cartItemDtos.length; index++) {
          const skuItem = cartItemDtos[index];
          vm.set(skuItem, 'amount', skuItem.num);
          codeArray.push(skuItem.modelCode);
          commit('SET_CART', { index, target: { isSelected: false } });// 是否被选中
        }
      } else {
        return false;
      }

      if (codeArray.length > 0) {
        await dispatch('getNewMdseInfo', codeArray.join(','));
      }
    },
    /**
     * 更新商品信息
     * @param {String} moduleCodes
     */
    async getNewMdseInfo({ commit, dispatch }, moduleCodes) {
      try {
        const mdseDetail = await getMerchantMdseDetailByCode(moduleCodes);
        if (mdseDetail.status === 200) {
          const mdseDetailList = mdseDetail.data;
          const moduleCodesArray = moduleCodes.split(',');
          const codePrices = [];
          for (let index = 0; index < mdseDetailList.length; index++) {
            const mdse = mdseDetailList[index];
            codePrices.push(`${moduleCodesArray[index]}:${mdse.sellPrice}`);
            commit('SET_CART', { target: mdse, index });
          }
          await dispatch('setMemberPrice', { codePrices: codePrices.join(','), from: 'cart' });
        }
      } catch (error) {
        throw new Error(error);
      }
    },
    /**
     * 从购物车删除
     * @param {String} mdseDetailIds 需要删除的id
     */
    async deleteCart({ getters, commit }, mdseDetailIds) {
      try {
        const del = await removeCartItem(mdseDetailIds);
        if (del.status === 200) {
          // 接口删除成功后
          const mdseDetailIdsArr = mdseDetailIds.split(',');
          const cartList = getters.GET_CART_LIST;
          for (let index = 0; index < cartList.length; index++) {
            const cart = cartList[index];
            for (let i = 0; i < mdseDetailIdsArr.length; i++) {
              const id = parseInt(mdseDetailIdsArr[i]);
              if (cart.mdseDetailId === id) {
                commit('SET_DELETE_CART', index);
              }
            }
          }
          return del.message;
        } else {
          return false;
        }
      } catch (error) {
        throw new Error(error);
      }
    },
    /**
     * 获取商品会员价
     * @param {String} codePrices eg:spec4645444634:0.01,spec564654646445:0.02
     * @param {String} from 处理来源
     */
    async setMemberPrice({ getters, commit }, { codePrices, from }) {
      // 来源配置
      let target;
      let setArray;
      if (from === 'cart') {
        target = 'GET_CART_LIST';
        setArray = 'SET_CART_LIST';
      } else {
        target = 'GET_SKU_LIST';
        setArray = 'SET_SKU_LIST';
      }

      // 处理会员价请求
      const targetList = getters[target]; // 目标数组
      try {
        const discountInfo = await getDiscount(codePrices);
        if (discountInfo.status === 200 && discountInfo.message) {
          const specialPriceArr = discountInfo?.data || [];
          const specialPriceObj = {};
          // 特殊权益
          specialPriceArr.forEach(item => {
            const { productCode } = item;
            specialPriceObj[productCode] = { ...item };
          });
          const getTag = productCode => specialPriceObj[productCode]?.priceTag;
          const priceArray = discountInfo.message.split(',');
          for (let index = 0; index < priceArray.length; index++) {
            const element = priceArray[index];
            if (codePrices.indexOf(element) === -1) {
              const tergetItem = targetList[index];
              const [productCode, price] = element.split(':');
              vm.set(tergetItem, 'memberPrice', parseFloat(price)); // 会员价
              vm.set(tergetItem, 'isMemberPrice', true); // 会员标识
              vm.set(tergetItem, 'priceTag', getTag(productCode)); // 特殊权益标识
            }
          }
        }
      } catch (error) {
        throw new Error(error);
      }

      // 处理会员价与活动价选择的问题
      for (let i = 0; i < targetList.length; i++) {
        const sku = targetList[i];
        let lowerPrice = sku.sellPrice;
        let useLowerPrice = 'normal';
        let isActive = false;
        if (sku.marketingActivityPrice || sku.marketingActivityPrice === 0) {
          isActive = true;
          useLowerPrice = 'active';
          lowerPrice = sku.marketingActivityPrice;
          if (sku.isMemberPrice) {
            isActive = sku.marketingActivityPrice < sku.memberPrice;
          }
        }
        if (sku.isMemberPrice && sku.memberPrice < lowerPrice) {
          lowerPrice = sku.memberPrice;
          useLowerPrice = 'member';
        }
        vm.set(sku, 'isActive', isActive);
        vm.set(sku, 'useLowerPrice', useLowerPrice); // 使用哪个价格结算
        vm.set(sku, 'lowerPrice', lowerPrice);
      }
      commit(setArray, targetList);
    },
  },
};

