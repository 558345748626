<template>
  <div
    ref="floatNav"
    class="circle-nav"
    :style="{ top: yPum + 'px', left: xPum + 'px' }"
    @mousedown="down"
    @touchstart="down"
    @mousemove="move"
    @touchmove="move"
    @mouseup="end"
    @touchend="end"
    @click="onWrapperClick"
  >
    <img :src="options.defaultImgUrl">
    <BtnListModal
      :is-show-modal.sync="isShowModal"
      :list="options.list"
    ></BtnListModal>
  </div>
</template>

<script>
import BtnListModal from './btnListModal';

export default {
  components: {
    BtnListModal,
  },
  props: {
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      flags: false,
      position: { x: 0, y: 0 },
      nx: '', // x移动距离
      ny: '', // y移动距离
      dx: '',
      dy: '',
      distance: 10,
      screenHeight: document.body.clientHeight,
      screenWidth: window.screen.width,
      xPum: window.screen.width - 74,
      yPum: document.body.clientHeight - 200,
      isShowModal: false,
    };
  },
  mounted() {
    this.floatNavWidth = this.$refs.floatNav.offsetWidth;
    this.floatNavHeight = this.$refs.floatNav.offsetHeight;
  },
  methods: {
    down(event) {
      this.flags = true;
      let touch;
      if (event.touches) {
        touch = event.touches[0];
      } else {
        touch = event;
      }
      this.position.x = touch.clientX || event.changedTouches[0].clientX;
      this.position.y = touch.clientY || event.changedTouches[0].clientY;
      this.dx = this.$refs.floatNav.offsetLeft;
      this.dy = this.$refs.floatNav.offsetTop;
    },
    move(event) {
      if (this.isShowModal) {
        event.preventDefault();
        return;
      }
      if (this.flags) {
        let touch;
        if (event.touches) {
          touch = event.touches[0];
        } else {
          touch = event;
        }
        this.nx = touch.clientX - this.position.x;
        this.ny = touch.clientY - this.position.y;
        this.xPum = this.dx + this.nx;
        this.yPum = this.dy + this.ny;
        event.preventDefault();
      }
    },
    end() {
      this.flags = false;
      if (this.xPum < 0) {
        this.xPum = this.distance;
      }
      if (this.yPum < 0) {
        this.yPum = this.distance;
      }
      console.log(this.xPum, this.screenWidth, this.floatNavWidth, this.screenWidth - this.floatNavWidth);
      if (this.xPum > this.screenWidth - this.floatNavWidth) {
        this.xPum = this.screenWidth - this.distance - this.floatNavWidth;
      }
      if (
        this.yPum >
        this.screenHeight -
        this.floatNavHeight -
        (this.screenHeight - this.innerHeight)
      ) {
        this.yPum =
          this.screenHeight -
          this.distance -
          this.floatNavHeight -
          (this.screenHeight - this.innerHeight);
      }
    },
    onWrapperClick() {
      if (this.options.ableUnfold !== 'T') {
        this.gotoTarget(this.options);
      } else {
        this.isShowModal = true;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.circle-nav {
  position: fixed;
  z-index: 500;
  width: 56px;
  height: 56px;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 50%;
  border: 1px solid #eaeaea;
  img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border-radius: 50%;
    width: 38px;
    height: 38px;
  }
}
</style>
