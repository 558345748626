import Vue from 'vue';

const vm = new Vue();

/* ---------------------------------  店铺公用  --------------------------------- */

/**
 * 获取小鲸用户信息
 */
export const showQyTab = () => vm.getAxios('/merchant/api/merchantInfo/showQyTab');
/**
 * 获取单品推广信息
 */
export const getOpenSingle = () => vm.getAxios('/merchant/api/merchantWholeMarket/getOpenSingle');

/**
 * 微信授权配置
 * @param {String} url 当前的网址
 */
export const wxConfig = (url) => vm.getAxios('/merchant/api/merchantInfo/getWxSignature', {
  url,
});

/**
 * 获取自定义页面数据
 * @param {String} pageType 页面类似
 */
export const getUsePage = (pageType) => vm.getAxios('/merchant/api/merchantRenovationToc/getUsePage', {
  pageType,
  useRange: 'wap',
});
