import { getTimeRange } from './time';

function setStorage(key, value) {
  const data = JSON.stringify({
    time: new Date().getTime(),
    value,
  });
  localStorage.setItem(key, data);
}
// 这个方法取的数据需要使用上面那个方法存
function getStorage(key, limitTime) {
  const data = localStorage.getItem(key);
  if (!data) {
    return null;
  }
  const { time, value } = JSON.parse(data);

  if ([time, value].some(item => item === undefined)) {
    throw new Error('data should use setStorage save');
  }

  if (!limitTime) {
    return value;
  }

  if (time + getTimeRange(limitTime) >= new Date().getTime()) {
    return value;
  }

  return null;
}

function removeStorage(key) {
  localStorage.removeItem(key);
}

export {
  setStorage,
  getStorage,
  removeStorage,
};
