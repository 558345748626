const preOrder = {
  namespaced: true,//不要修改
  state: {
    order: {},
    activityInfo: {}
  },
  mutations: {
    SET_ORDER(state, info) {
      state.order = info
    },
    SET_ACT_INFO(state, info) {
      state.activityInfo = info
    }
  },
  actions: {
    setOrder: {
      root: true,
      handler({ commit }, info) {
        sessionStorage.setItem("order", JSON.stringify(info))
        commit('SET_ORDER', info)
      }
    },
    setActInfo: {
      root: true,
      handler({ commit }, info) {
        sessionStorage.setItem("activityInfo", JSON.stringify(info))
        commit('SET_ACT_INFO', info)
      }
    }
  }
}

export default preOrder
