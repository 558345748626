const { VUE_APP_MODE } = process.env;
const linkBaseUrl = `${window.location.protocol}//${window.location.host}`;
const baseUrl = '/lotsapi/';
let qyyxUrl = '';
let sqfxBaseUrl = '/sqfxapi';
let pubUrl = '';
let xjtUrl = '';
let yearCardBaseUrl = ''; // 全域年卡
const hostStr = window.location.host.split('.')[0];
switch (VUE_APP_MODE) {
  case 'prod':
    pubUrl = '//pubswap.zhiyoubao.com';
    xjtUrl = 'http://traffic.lotsmall.cn/manage/';
    qyyxUrl = 'https://qyyxwap.lotsmall.cn';
    sqfxBaseUrl = 'https://wap.lotsmall.cn/sqfxapi';
    yearCardBaseUrl = 'https://nkwap.lotsmall.cn';
    break;
  case 'proddev':
    pubUrl = '//pubswap.zhiyoubao.com';
    xjtUrl = 'http://traffic.lotsmall.cn/manage/';
    qyyxUrl = 'http://devqyyxwap.lotsmall.cn';
    sqfxBaseUrl = 'https://prewap.lotsmall.cn/sqfxapi';
    yearCardBaseUrl = '//saasnkwap.sendinfo.com.cn';
    break;
  case 'tmh':
    pubUrl = '//pubswap.zhiyoubao.com';
    xjtUrl = 'http://traffic.lotsmall.cn/manage/';
    qyyxUrl = 'https://qyyxwap.lotsmall.cn';
    sqfxBaseUrl = 'https://wap.lotsmall.cn/sqfxapi';
    yearCardBaseUrl = 'https://nkwap.lotsmall.cn';
    break;
  case 'tmhtest':
    pubUrl = '//pubswap.sendinfo.com.cn';
    xjtUrl = 'http://xjt.sendinfo.com.cn/';
    qyyxUrl = 'https://testqyyxwap.lotsmall.cn';
    sqfxBaseUrl = 'https://testwap.lotsmall.cn/sqfxapi';
    yearCardBaseUrl = '//saasnkwap.sendinfo.com.cn';
    break;
  case 'test':
    pubUrl = '//pubswap.sendinfo.com.cn';
    xjtUrl = 'http://xjt.sendinfo.com.cn/';
    qyyxUrl = 'https://testqyyxwap.lotsmall.cn';
    sqfxBaseUrl = 'https://testwap.lotsmall.cn/sqfxapi';
    yearCardBaseUrl = '//saasnkwap.sendinfo.com.cn';
    break;
  default:
    pubUrl = '//pubswap.sendinfo.com.cn';
    xjtUrl = 'http://traffic.lotsmall.cn/manage/';
    qyyxUrl = 'https://qyyxwap.lotsmall.cn';
    sqfxBaseUrl = '/sqfxapi';
    yearCardBaseUrl = '//nkwap.lotsmall.cn';
}

// if (VUE_APP_MODE === 'tmh') {
//   sqfxBaseUrl = 'https://wap.lotsmall.cn/sqfxapi';
// }
// if (VUE_APP_MODE === 'tmhtest') {
//   sqfxBaseUrl = 'https://testwap.lotsmall.cn/sqfxapi';
// }
// pre 环境配置特殊处理
// master分支直接使用的pre的包，所以配置信息无法通过编译重新配置，故特殊处理；
if (hostStr.match(RegExp(/pre/))) {
  qyyxUrl = 'https://preqyyxwap.lotsmall.cn';
  sqfxBaseUrl = 'https://prewap.lotsmall.cn/sqfxapi';
  yearCardBaseUrl = 'https://pre-nkwap.lotsmall.cn';
  pubUrl = '//prepubswap.zhiyoubao.com';
}

if (hostStr.match(RegExp(/test/))) {
  sqfxBaseUrl = 'https://testwap.lotsmall.cn/sqfxapi';
}

export {
  baseUrl,
  linkBaseUrl,
  qyyxUrl,
  sqfxBaseUrl,
  pubUrl,
  xjtUrl,
  yearCardBaseUrl,
};
