import Vue from 'vue';
import qs from 'qs';
import vueCookie from 'vue-cookies';
import axios from 'axios';
import { httpError } from './index.js';
import {
  baseUrl,
} from '@/common/config.js';
import { getToken } from '@/utils';

const service = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? baseUrl : '',
  withCredentials: true,
  headers: { 'content-type': 'application/json;charset=UTF-8' },
});
const errorState = httpError(service);

service.interceptors.request.use(
  config => {
    /** **********************************axios.js参数未标注各字段说明，暂时看不懂*************************** */
    const token = getToken();
    const trace_device_id = vueCookie.get('trace_device_id');
    const trace_sess_id = vueCookie.get('trace_sess_id');
    const web_browser_trace_id = vueCookie.get('web_browser_trace_id');
    const M_ID = localStorage.getItem('xjsc_vue_2018_12_19_mid') || '';

    // 获取全员营销的登录账号和密码，绝对不能添加请求头的access-token，不然请求会报错
    if (config.url !== '//qyyxcs.sendinfo.com.cn/wap/enterPromote.htm' && config.url !== '//qyyx.zhiyoubao.com/wap/enterPromote.htm') {
      config.headers['access-token'] = token || '';
      config.headers.trace_device_id = trace_device_id || '';
      config.headers.trace_sess_id = trace_sess_id || '';
      config.headers.web_browser_trace_id = web_browser_trace_id || '';
    }
    config.headers['m-id'] = M_ID;
    /** ***********************************End******************************************************* */
    config.formData && (config.headers = { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' });
    const defaultParams = {
      merchantInfoId: M_ID,
      xj_time_stamp_2019_11_28: new Date().getTime(),
    };
    if (config.method === 'get') {
      config.params = config.params ? Object.assign({}, config.params, defaultParams) : config.params = defaultParams;
    } else if (config.method === 'post') {
      let data = null;
      data = config.data ? Object.assign({}, config.data, defaultParams) : defaultParams;
      config.formData ? config.data = qs.stringify(data) : config.data = data; // 模拟form表单提交时使用qs模块
    }

    return config;
  },
  error => {
    // errorState(error.response)
    Vue.$vux.alert.show({
      title: '提示',
      content: error.message || '请求失败',
    });
    return Promise.reject(error);
  },
);

service.interceptors.response.use( // 拦截返回response
  response => {
    const res = response.data;
    if (res.status === 200) {
      return res;
    } else if (res.code === 1004001005) {
      errorState({
        status: 400,
      });
    } else {
      Vue.$vux.alert.show({
        title: '提示',
        content: res.message,
      });
      return Promise.reject(res);
    }
  },
  error => {
    errorState(error.response);
    return Promise.reject(error);
  },
);


export default service;
