const advanceSaleTheaterRoute = [{
  path: '/theater/list',
  name: 'preListTheater',
  component: () => import(/* webpackChunkName: "route-pre-sale-theater" */ '@/pages/advanceSale/theater/list'),
  meta: {
    title: '预售列表'
  }
},
{
  path: '/theater/detail/:id',
  name: 'preDetailTheater',
  component: () => import(/* webpackChunkName: "route-pre-sale-theater" */ '@/pages/advanceSale/theater/detail'),
  meta: {
    title: '预售剧院详情'
  }
},
{
  path: '/theater/createOrder/:id',
  name: 'createOrderTheater',
  component: () => import(/* webpackChunkName: "route-pre-sale-theater" */ '@/pages/advanceSale/theater/createOrder'),
  meta: {
    title: '预售订单'
  }
},
{
  path: '/theater/appoint',
  name: 'preAppointTheater',
  component: () => import(/* webpackChunkName: "route-pre-sale-theater" */ '@/pages/advanceSale/theater/appoint'),
  children: [
    {
      path: 'appointment/:id',
      name: 'theaterAppointment',
      component: () => import(/* webpackChunkName: "route-pre-sale-theater" */ '@/pages/advanceSale/theater/appoint/appointment'),
      meta: {
        title: '填写预订信息'
      }
    },
  ]
},
{
  path: '/theater/selectSeat/:id',
  name: 'selectSeatTheater',
  component: () => import(/* webpackChunkName: "route-pre-sale-theater" */ '@/pages/advanceSale/theater/selectSeat'),
  meta: {
    title: '选座'
  }
},
{
  path: '/theater/selectSeat-test',
  name: 'selectSeatTheatertest',
  component: () => import(/* webpackChunkName: "route-pre-sale-theater" */ '@/pages/advanceSale/theater/selectSeat/index_test'),
  meta: {
    title: '选座'
  }
},
{
  path: '/theater/orderDetail/:id',
  name: 'preOrderDetailTheater',
  component: () => import(/* webpackChunkName: "route-pre-sale-theater" */ '@/pages/advanceSale/theater/orderDetail'),
  meta: {
    title: '预售订单详情'
  }
},
{
  path: '/theater/payResult/:id',
  name: 'prePayResultTheater',
  component: () => import(/* webpackChunkName: "route-pre-sale-theater" */ '@/pages/advanceSale/theater/payResult'),
  meta: {
    title: '支付结果'
  }
},
]

export default advanceSaleTheaterRoute
