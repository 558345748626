import Vue from 'vue';
import queryString from 'query-string';
import moment from 'moment';
import {
  baseUrl,
  linkBaseUrl,
} from '@/common/config.js';
import {
  isWxAli,
} from '@/common/common';
import router from '@/router';
import miniProgram from '@/common/miniProgram.js';
import VueCookies from 'vue-cookies';

const { NODE_ENV } = process.env;

// eslint-disable-next-line import/prefer-default-export
export function resizeImg(url, type = 'small', quality = '90') {
  if (!url) {
    return url;
  }
  const res = queryString.parseUrl(url);
  if (!res) {
    return url;
  }
  let size = type.trim();
  if (size[size.length - 1] !== 'x') {
    switch (size) {
      case 'tiny':
        size = '50x';
        break;
      case 'big':
        size = '750x';
        break;
      case 'middle':
        size = '300x';
        break;
      case 'small':
      default:
        size = '100x';
        break;
    }
  }
  if (res.query) {
    let query;
    if (canUseWebP()) {
      query = `imageMogr2/thumbnail/${size}/strip/quality/${quality}/format/webp`;
    } else {
      query = `imageMogr2/thumbnail/${size}/strip/quality/${quality}`;
    }
    res.query[query] = null;
    return queryString.stringifyUrl(res);
  }
  return url;
}

let isSupportWebP = null;
export function canUseWebP() {
  if (isSupportWebP !== null) {
    return isSupportWebP;
  }
  const elem = document.createElement('canvas');

  if (elem.getContext && elem.getContext('2d')) {
    isSupportWebP = elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
  } else {
    isSupportWebP = false;
  }

  return isSupportWebP;
}

export function formatDay(time) {
  if (!time) {
    return '';
  }
  return moment(time).format('YYYY-MM-DD');
}

export function formatTime(time) {
  if (!time) {
    return '';
  }
  return moment(time).format('YYYY-MM-DD HH:mm:ss');
}

export function formatHour(time) {
  if (!time) {
    return '';
  }
  return moment(time).format('HH:mm');
}

/**
 * 姓名格式校验
 * @param {string} val 姓名
 */
export function checkName(val) {
  let name = val;
  if (typeof val === 'string') {
    name = val.trim();
  }
  return name && (/^([a-zA-Z\u4e00-\u9fa5\s]{1,200})$/.test(name));
}

/**
 * 手机号校验
 * @param {Number | String} val 值
 */
export function checkMobile(val) {
  if (!val) return false;
  return /^1[3-9][0-9]{9}$/.test(val);
}

/**
 * 邮箱校验
 * @param {String} val 值
 */
export function checkEmail(val) {
  if (!val) return false;
  // eslint-disable-next-line no-useless-escape
  return /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(val);
}

export function getUrlQuery(url) {
  const index = url.indexOf('?');
  if (index === -1) return {};
  const queryList = url.substr(index + 1).split('&');
  return queryList.reduce((result, curr) => {
    const [key, value] = curr.split('=');
    return { ...result, [key]: value };
  }, {});
}

export function debounce(func, delay) {
  let timer;
  return function (...args) {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}
export function wxAliLogin(channl) {
  let rediurl;
  const nextPath = localStorage.getItem('xjsc_nextpath_2019_4_20');
  if (nextPath) {
    rediurl = nextPath;
    localStorage.removeItem('xjsc_nextpath_2019_4_20');
  } else {
    rediurl = window.location.href;
  }
  const params = {
    channl,
    redirectUrl: encodeURIComponent(rediurl),
    merchantInfoId: localStorage.getItem('xjsc_vue_2018_12_19_mid'),
  };
  const url =
    `${baseUrl }leaguer/api/authApi/auth?${ queryString.stringify(params)}`;
  window.location.href = url;
}

const env = isWxAli();

export function httpError(http) {
  return (response) => {
    if (response) {
      if (response.config.url === '/marketing/api/log/save' ||
          response.config.url === '/marketing/api/log/error' ||
          response.config.url.indexOf('/merchant/api/merchantInfo/getGraytatus') >= 0) {
        return false;
      }
      if (response.status === 400 || response.data.code === 1004001005) {
        // token过期、token不存在
        let channl = '';
        if (env.isWx) {
          // 微信内置浏览器
          http({
            url: '/pay/api/getPayInfo',
            method: 'get',
            params: {
              payCode: 'wxZyb',
            },
          }).then(res => {
            const wxAccount = {
              appid: '',
              appsecret: '',
            };
            if (res && res.data && res.data.details && res.data.details.length) {
              res.data.details.forEach(element => {
                if (element.payKey === 'appid') { wxAccount.appid = element.payValue; }
                if (element.payKey === 'appsecret') { wxAccount.appsecret = element.payValue; }
              });
            }
            localStorage.setItem('xjsc_vue_2019_3_19_wxAccount', JSON.stringify(wxAccount));
            channl = 'WEIXIN';
            wxAliLogin(channl);
          });
        } else if (env.isAliPay) {
          channl = 'ALI';
          wxAliLogin(channl);
        } else if (env.isYunPay) {
          channl = 'YUNSHANFU';
          wxAliLogin(channl);
        } else {
          // 其他浏览器
          router.push({
            path: `/login?m_id=${ localStorage.getItem('xjsc_vue_2018_12_19_mid')}`,
          });
        }
      } else if (response.status === 410) {
        // 手机浏览器登录用户直接用手机号码登录不需要绑定手机号码，也就支付宝和微信H5用户需要
        // 410状态微信h5及支付宝h5用户登录后未绑定手机号码
        // 需要跳转手机号码绑定页面
        const rediurl = encodeURIComponent(window.location.href);
        if (NODE_ENV === 'production') {
          const url = `${linkBaseUrl }/vue/bind/mobile?m_id=${ localStorage.getItem('xjsc_vue_2018_12_19_mid') }&rediurl=${ rediurl}`;
          if (window.location.href !== url) {
            window.location.href = url;
          }
        } else {
          router.push({
            path: `/bind/mobile?rediurl=${ rediurl}`,
          });
        }
      } else {
        Vue.$vux.alert.show({
          title: '提示',
          content: response.data.message || '请求失败',
        });
      }
    }
  };
}

export function dealTime(time) {
  if (!time) return;
  const _time = time.split(' ');
  const date1 = _time[0].split('-');
  const date2 = _time[1].slice(0, -3);
  const year = date1[0];
  const month = date1[1];
  const day = date1[2];
  return `${year}年${month}月${day}日 ${date2}`;
}

// 对字符串进行简单加密
export function compileStr(code) {
  let c = String.fromCharCode(code.charCodeAt(0) + code.length);
  for (let i = 1; i < code.length; i++) {
    c += String.fromCharCode(code.charCodeAt(i) + code.charCodeAt(i - 1));
  }
  return escape(c);
}

// 字符串进行简单解密
export function uncompileStr(code) {
  code = unescape(code);
  let c = String.fromCharCode(code.charCodeAt(0) - code.length);
  for (let i = 1; i < code.length; i++) {
    c += String.fromCharCode(code.charCodeAt(i) - c.charCodeAt(i - 1));
  }
  return c;
}
// 获取token
export function getToken() {
  const m_id = localStorage.getItem('xjsc_vue_2018_12_19_mid');
  if (miniProgram.isMP()) {
    return VueCookies.get(`mini_token_${m_id}`);
  } else {
    return VueCookies.get(`token_${m_id}`);
  }
}
// 清除用户信息
export function clearToken() {
  localStorage.removeItem('xjsc_login_access_token_2019_3_7');
  localStorage.removeItem('xjsc_login_userId_2019_3_8');
  localStorage.setItem('token_type', '');
  // 清除旧的token和leaguerInfoId
  const keys = this.$cookies.keys();
  keys && keys.forEach(item => {
    if (item.includes('token') || item.includes('leaguerInfoId')) {
      this.$cookies.remove(item, null, window.location.hostname);
    }
  });
}
