import { isWxAli, urlParse, setUrlQuery } from '@/common/common';
import { postAxios, getAxios } from '@/api/axios';
import VueCookies from 'vue-cookies';

const env = isWxAli();
/**
 * 根据环境 获取app授权配置字典
 * @return {authConfigEnum}
 * channl app渠道 （老的授权接口用到）
 * type 授权类别 （新的授权接口用到）
 * grayKey  灰度判断字段 （新的授权接口用到）
 * interceptToken 为true表示授权回调到前端页面，路由后面会拼接token，需要拦截token获取用户信息
 * path  前端发起授权的路由地址 带有path 的为前端授权页面发起授权
 * isHasAppToken 表示是否有第三方app的token拼接到路由后
 * appTokenKey 第三方app的token拼接到路由的key只 isHasAppToken为true时存在
 */
function getAppAuthConfig() {
  // 授权配置字典
  const authConfigEnum = {
    // 微信
    isWx: {
      channl: 'WEIXIN',
      type: 4,
      grayKey: 'WeChat_login', // 灰度判断字段
      interceptToken: true, // 为true表示路由后面有token需要做拦截
    },
    // 支付宝
    isAliPay: {
      channl: 'ALI',
      interceptToken: true, // 为true表示路由后面有token需要做拦截
    },
    // 云闪付h5
    isYunPayH5: {
      type: 8,
      interceptToken: true, // 为true表示路由后面有token需要做拦截
      grayKey: 'noNeed', // 灰度判断字段 noNeed表示不需要灰度
    },
    // 云闪付小程序
    isYunPayMiniApp: {
      channl: 'yunPayMiniApp',
      path: '/yunPayMiniAuth', // 带有path 的为前端授权页面发起授权
      type: 18,
    },
    // 农行掌银
    isABCPay: {
      // channl: 'NONGHANG',
      type: 9,
      interceptToken: true, // 为true表示路由后面有token需要做拦截
      grayKey: 'noNeed', // 灰度判断字段 noNeed表示不需要灰度
    },
    // 丰收互联
    isMFS: {
      type: 11,
      path: '/MFSLogin',
    },
    // 皖事通
    isCroods: {
      type: 10,
      path: '/croodsAuth',
    },
    // 智乐山
    isZhileshan: {
      type: 12,
      path: '/zhileshanAuth',
    },
    // 常州社保卡
    isChangZhouRS: {
      channl: 'SOCIAL_SECURITY_CARD',
      path: '/changZhouRSAuth',
      type: 15,
    },
    // 游山西app
    isYouShanXiApp: {
      channl: 'youShanXiApp',
      path: '/youShanXiAuth',
      type: 16, // 游山西app传
    },
    // 华为
    isHuaWei: {
      path: '/huaweiAuth',
      type: 17,
    },
    // 苏周到
    isSZDApp: {
      path: '/szdAppAuth',
      type: 19,
      interceptToken: true, // 为true表示路由后面有token需要做拦截
    },
    // i宜兴
    isiyixing: {
      path: '/iyixingAuth',
      type: 20,
      interceptToken: true, // 为true表示路由后面有token需要做拦截
    },
    // 六安人社
    isLuAnRenShe: {
      path: '/luAnRenSheAuth',
      type: 21,
      interceptToken: true, // 为true表示路由后面有token需要做拦截
    },
  };
  // 当前授权环境
  const envChannlKey = Object.keys(authConfigEnum).find(key => {
    if (typeof env[key] === 'function') {
      return env[key]();
    } else {
      return env[key];
    }
  });
  if (envChannlKey) {
    const envChannlItem = authConfigEnum[envChannlKey];
    return envChannlItem;
  }
}
// const appAuthItem = getAppAuthConfig();
/**
 * 统一授权回调
 */
async function appLoginInfo(
  {
    params,
    url = '/up/api/login',
    redirectUrl,
  },
) {
  params.regChannel = getRegChannel();
  const res = await postAxios(url, params);
  const { accessToken: token, accountId: leaguerId } = res.data;

  // localStorage.setItem(
  //   'xjsc_login_access_token_2019_3_7',
  //   token,
  // );
  // localStorage.setItem('xjsc_login_userId_2019_3_8', leaguerId);
  let rediurl = redirectUrl;
  // 同步到node
  const urlNode = `${window.location.protocol }//${ window.location.host }/merchant/api/node/changeInfo`;
  const paramsNode = {
    token,
    leaguerId,
  };
  await getAxios(urlNode, paramsNode);
  const { query } = urlParse(rediurl);
  if (query && query.needToken === 'T') {
    // 回调地址中如果带有needToken=T,则需要在回调地址后面拼接token
    rediurl = setUrlQuery(rediurl, { token });
  }
  window.location.href = rediurl;
}
/**
 * 预授权接口
 */
async function authSupport({
  merchantId,
  type,
  redirectUrl,
}) {
  const appParams = {
    merchantId,
    type,
    redirectUrl,
    regChannel: getRegChannel(),
  };
  const authRes = await getAxios('/up/api/auth/support', appParams);
  return authRes;
}
/**
 * 第三方授权，路径参数拦截,存储
 */
function getParamsFromAppPath({
  to,
}) {
  const m_id = VueCookies.get('m_id');
  if (to.query.token && to.query.from === 'luAnRenSheApp') {
    // 六安人社
    VueCookies.set(`appType_${m_id}`, 'luAnRenSheApp');
    VueCookies.set(`appType_token_${m_id}`, to.query.token);
  } else if (env.isYunPay && to.query.from === 'yunPayMiniApp') {
    // 银联云闪付小程序

    // 小鲸嵌入app，部分app未提供判断环境的方法
    // 需要在入口添加入参的方式，判断所处app的环境
    // from 银联云闪付小程序 (yunPayMiniApp)
    // sessionStorage.setItem(`appType`, from);
    VueCookies.set(`appType_${m_id}`, 'yunPayMiniApp');
  } else if (env.isYouShanXiApp && to.query.yjyToken) {
    // 游山西
    VueCookies.set(`appType_token_${m_id}`, to.query.yjyToken.replace(/\s+/g, '+'));
  }
  // 拦截渠道来源，授权、注册时传入、关闭网页清空该存储(node中存储该字段有问题，没有关闭浏览器清除数据方案)
  if (to.query.regChannel) {
    sessionStorage.setItem(`regChannel_${m_id}`, to.query.regChannel);
  }
}
// 获取注册渠道
function getRegChannel() {
  const m_id = VueCookies.get('m_id');
  const regChannel = sessionStorage.getItem(`regChannel_${m_id}`);
  return regChannel || 'WAP';
}
export {
  getAppAuthConfig,
  appLoginInfo,
  authSupport,
  getParamsFromAppPath,
  getRegChannel,
  // appAuthItem,
};
