// import { postAxios } from '@/api/axios.js';

const user = {
  namespaced: true, // 不要修改
  state: {
    count: 0,
    info: {},
  },
  getters: {

  },
  mutations: {
    add(state, num) {
      state.count += num;
    },
    setUser(state, value) {
      state.info = value;
    },
  },
  actions: {
    add: {
      root: true,
      handler({
        commit,
      }, num) {
        commit('add', num);
      },
    },
    // async getZhileshanAppToken(content, { accessToken }) {
    //   // 获取智乐山appAuth
    //   const url = '/leaguer/api/authApi/zhileshanLogin';
    //   const params = {
    //     merchantInfoId: localStorage.getItem('xjsc_vue_2018_12_19_mid'),
    //     accessToken,
    //     // clientId: 0, // 无用值
    //     // openId: 0, // 无用值
    //   };
    //   const data = await postAxios(url, params);
    //   const { token, leaguerId } = data.data;
    //   console.log(`用户信息:${ JSON.stringify(data)}`);
    //   localStorage.setItem(
    //     'xjsc_login_access_token_2019_3_7',
    //     token,
    //   );
    //   localStorage.setItem('xjsc_login_userId_2019_3_8', data.data);
    //   return { token, leaguerId };
    // },
  },
};

export default user;
