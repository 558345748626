// 商家 相关页面路由
const routes = [
  {
    path: '/shops/list',
    name: 'shopsList',
    meta: {
      title: '商家列表',
    },
    component: () => import(/* webpackChunkName: "route-shops" */ '@/pages/shops/list'),
  },
  {
    path: '/shops/detail',
    name: 'shopsDetail',
    meta: {
      title: '商家详情',
    },
    component: () => import(/* webpackChunkName: "route-shops" */ '@/pages/shops/detail'),
  },
];

export default routes;
