import md5 from 'js-md5';
import VueCookies from 'vue-cookies';
import sqfxApi from '@/api/sqfx';
import { getUserInfo } from '@/ajax/member';
import miniProgram from '@/common/miniProgram';

const {
  getQyyxToken,
  getDeployInfo,
  getMarketWholeMarketShareConfig,
  queryUserInfo,
  queryIsOpenServiceCharge,
  isIdCardDisplay,
  queryCWxParam,
  queryCFreshNoticeInfo,
  freshCNoticeInfo,
  queryCNoticeInfoPage,
  queryCNoticeInfoDetail,
  queryPersonalPolymerizeCode,
  selectSysParamByCode,
  selectYSPayAstrict,
  getIncomingPartsUrl,
  getIncomingPartsStatus,
  selectBankCardInfo,
  yeePayAccountInfo,
  yeePayIncomingPartsUrl,
  zCentPayIncomingPartsFlag,
  zCentPayIncomingPartsUrl,
  zCentPayIncomingPartsStatus,
  zCentPayBankCardInfo,
  selectAppointConfig,
} = sqfxApi;

const getToken = () => {
  const m_id = localStorage.getItem('xjsc_vue_2018_12_19_mid');
  if (miniProgram && miniProgram.isMP()) {
    return VueCookies.get(`mini_token_${m_id}`);
  } else {
    return VueCookies.get(`token_${m_id}`);
  }
};


const state = {
  qyyxToken: null, // 全员营销独立部署token
  isDeploySwitch: sessionStorage.getItem('deploy_switch') || null, // 当前环境是否为独立部署
  signKey: 'qyyx_sign_key', // 签名固定字符
  source: sessionStorage.getItem('source') || '', // 来源
  corpCode: sessionStorage.getItem('corp_code') || '', // 企业码
  thirdUserId: '',
  lotsToken: getToken() || '',
  isThirdPart: false,
  isOpenShare: false,
  userInfo: {
    headImgUrl: '',
    nickName: '',
    mobile: '',
    accName: null, // 用户名
    accessToken: null, // token
    corpCode: '', // 全员企业码
    sysSource: '', // 系统来源
    userId: null, // 全员用户id
    promoterType: 0, // 推广员类型，0-直客推广员，1-分销商推广员
    accType: 3, // 全员用户类型， 2-分销商账号，3-推广员
  },
  isShowIdcard: false,
  isBindMobile: '',
  isOpenServiceCharge: false,
  wxAccInfo: {
    accType: '2',
    wxAcc: '',
    wxName: '',
    wxIdcard: '',
    customerServiceUrl: '',
    isRecommend: '',
  },
  latestNotice: null,
  noticeList: [],
  noticeListTotal: 0,
  noticeDetails: {},
  polymerizeInfo: {
    merchantInfoId: '',
    wxQrCodeUrl: '',
    promoteCode: '',
    promoteUrl: '',
    shortPromoteUrl: '',
    polymerizePosters: [],
  },
  spreadCodeExpireConfig: [],
  spreadCodeExpire: '',
  isUseEasyPay: false, // 是否使用易生支付
  easyPaySettleUrl: '', // 易生支付进件支付中心url地址
  easyPaySettleStatus: '', // 易生支付进件绑卡状态
  easyPayBankInfo: {
    bankCode: '',
    bankCardNo: '',
    bankName: '',
  },
  yeePayInfo: null, // 易宝账户信息
  yeePaySettleUrl: '', // 易宝绑卡支付中心url地址
  zCentPayFlag: false,
  zCentPaySettleStatus: '',
  zCentPaySettleUrl: '',
  zCentPayBankInfo: {
    bankCode: '',
    bankCardNo: '',
    bankName: '',
    repeatApply: '',
  },
  promoteQrCodeType: 3, // 1 - 二维码；2 - 公众号二维码；3 - 小程序码
};

const getters = {
  qyyxToken: (state, getters) => {
    if (state.qyyxToken) return state.qyyxToken;
    if (state.isThirdPart) {
      return sessionStorage.getItem(`qyyx_${md5(getters.thirdUserId)}`) || '';
    } else {
      return sessionStorage.getItem(`qyyx_${md5(getToken())}`) || '';
    }
  },
  lotsToken: (state) => {
    if (!state.isThirdPart) return getToken();
    return state.lotsToken;
  },
  isThirdPart: state => state.isThirdPart,
  isDeployAlone: (state) => state.isDeploySwitch,
  isOpenShare: (state) => {
    const miniType = miniProgram.isMP();
    if (!miniType) return state.isOpenShare; // h5 由配置项决定
    if (miniType !== 'wx') return false; // 不是微信小程序不显示
    return state.isOpenShare;// 微信小程序 由配置项决定
  },
  thirdUserId: (state) => {
    if (state.thirdUserId) return state.thirdUserId;
    if (state.isThirdPart) {
      return sessionStorage.getItem('third_user_id') || '';
    } else {
      return localStorage.getItem('xjsc_login_userId_2019_3_8') || '';
    }
  },
  mobile: state => state.userInfo.mobile,
  promoterType: state => state.userInfo.promoterType,
  accType: state => state.userInfo.accType,
  userInfo: state => state.userInfo,
  isBindMobile: state => state.isBindMobile,
  isShowIdcard: state => state.isShowIdcard,
  isOpenServiceCharge: state => state.isOpenServiceCharge,
  wxIdcard: state => state.wxAccInfo.wxIdcard,
  polymerizeInfo: state => state.polymerizeInfo,
  source: state => state.userInfo.sysSource || state.source,
  latestNotice: state => state.latestNotice,
  noticeList: state => state.noticeList,
  noticeListTotal: state => state.noticeListTotal,
  noticeDetails: state => state.noticeDetails,
  spreadCodeExpireConfig: state => state.spreadCodeExpireConfig,
  spreadCodeExpire: state => state.spreadCodeExpire,
  wxAccInfo: state => state.wxAccInfo,
  isUseEasyPay: state => state.isUseEasyPay,
  easyPaySettleUrl: state => state.easyPaySettleUrl,
  easyPaySettleStatus: state => state.easyPaySettleStatus,
  easyPayBankInfo: state => state.easyPayBankInfo,
  yeePayInfo: state => state.yeePayInfo,
  yeePaySettleUrl: state => state.yeePaySettleUrl,
  zCentPayFlag: state => state.zCentPayFlag,
  zCentPaySettleStatus: state => state.zCentPaySettleStatus,
  zCentPaySettleUrl: state => state.zCentPaySettleUrl,
  zCentPayBankInfo: state => state.zCentPayBankInfo,
  promoteQrCodeType: state => state.promoteQrCodeType,
};

const mutations = {
  SET_USERINFO(state, payload = {}) {
    state.userInfo = Object.assign(state.userInfo, payload);
  },
  SET_QYYXTOKEN(state, payload) {
    state.qyyxToken = payload;
    if (!state.isThirdPart) {
      // 通过lots token缓存
      const getKey = () => (state.lotsToken ? md5(state.lotsToken) : null);
      if (payload && getKey()) {
        Object.keys(sessionStorage).forEach(item => {
          if (item.includes('qyyx_')) {
            sessionStorage.removeItem(item);
          }
        });
        sessionStorage.setItem(`qyyx_${getKey()}`, payload);
      }
    } else {
      // 通过第三方id缓存
      const thirdUserId = state.thirdUserId || sessionStorage.getItem('third_user_id');
      const getKey = () => (thirdUserId ? md5(thirdUserId) : null);
      if (payload && getKey()) {
        Object.keys(sessionStorage).forEach(item => {
          if (item.includes('qyyx_')) {
            sessionStorage.removeItem(item);
          }
        });
        sessionStorage.setItem(`qyyx_${getKey()}`, payload);
      }
    }
  },
  SET_DEPLOYINFO(state, payload) {
    const { isDeploySwitch, source, corpCode } = payload;
    const deploy = isDeploySwitch === 'true' ? '1' : '2';
    sessionStorage.setItem('deploy_switch', deploy);
    state.isDeploySwitch = deploy;
    sessionStorage.setItem('source', source);
    state.source = source;
    sessionStorage.setItem('corp_code', corpCode);
    state.corpCode = corpCode;
  },
  SET_USERID(state, payload) {
    if (payload) {
      sessionStorage.setItem('third_user_id', payload);
      state.thirdUserId = payload;
    }
  },
  SET_SHARE_SWITCH(state, payload) {
    state.isOpenShare = payload === 'T';
  },
  SET_THIRD_PART(state, payload) {
    state.isThirdPart = !!payload;
  },
  SET_IDCARDSWITCH(state, payload) {
    state.isShowIdcard = !!payload;
  },
  SET_IS_BIND_MOBILE(state, payload) {
    state.isBindMobile = payload;
  },
  SET_SERVICE_CHARGE(state, payload) {
    state.isOpenServiceCharge = !!payload;
  },
  SET_WXACCINFO(state, payload = {}) {
    state.wxAccInfo = Object.assign(state.wxAccInfo, payload);
  },
  SET_LATEST_NOTICE(state, payload) {
    if (payload) {
      state.latestNotice = Object.assign({}, payload);
    } else {
      state.latestNotice = null;
    }
  },
  SET_NOTICE_LIST(state, payload = []) {
    state.noticeList = [].concat(payload);
  },
  SET_NOTICE_LIST_TOTAL(state, payload) {
    state.noticeListTotal = payload;
  },
  SET_NOTICE_DETAILS(state, payload = {}) {
    state.noticeDetails = Object.assign({}, payload);
  },
  SET_POLYMERIZE_INFO(state, payload = {}) {
    state.polymerizeInfo = Object.assign(state.polymerizeInfo, payload);
  },
  SET_SPREAD_CODE_EXPIRE_CONFIG(state, payload = []) {
    state.spreadCodeExpireConfig = [].concat(payload);
  },
  SET_SPREAD_CODE_EXPIRE(state, payload = 24) {
    state.spreadCodeExpire = payload;
  },
  SET_EASY_PAY_USE_STATUS(state, payload = false) {
    state.isUseEasyPay = !!payload;
  },
  SET_EASY_PAY_SETTLE_URL(state, payload) {
    state.easyPaySettleUrl = payload;
  },
  SET_EASY_PAY_BIND_STATUS(state, payload) {
    state.easyPaySettleStatus = payload;
  },
  SET_EASY_PAY_BANK_CARD_INFO(state, payload = {}) {
    state.easyPayBankInfo = Object.assign(state.easyPayBankInfo, payload);
  },
  SET_YEE_PAY_ACCOUNT_INFO(state, payload = {}) {
    state.yeePayInfo = payload;
  },
  SET_YEE_PAY_SETTLE_URL(state, payload) {
    state.yeePaySettleUrl = payload;
  },
  SET_ZCENT_PAY_FLAG(state, payload) {
    state.zCentPayFlag = !!payload;
  },
  SET_ZCENT_PAY_SETTLE_STATUS(state, payload) {
    state.zCentPaySettleStatus = payload;
  },
  SET_ZCENT_PAY_SETTLE_URL(state, payload) {
    state.zCentPaySettleUrl = payload;
  },
  SET_ZCENT_PAY_BANK_INFO(state, payload = {}) {
    state.zCentPayBankInfo = Object.assign(state.zCentPayBankInfo, payload);
  },
  SET_SETTLE_DELAY_FLAG(state, payload) {
    state.settlementDelayFlag = !!payload;
  },
  SET_PROMOTE_QR_CODE_TYPE(state, payload) {
    state.promoteQrCodeType = payload;
  },
};

const actions = {
  async FETCH_SHARE_SWITCH({ commit }) {
    const res = await getMarketWholeMarketShareConfig();
    if (res.status === 200) {
      const { merchantShare } = res.data;
      commit('SET_SHARE_SWITCH', merchantShare);
    }
  },
  // lots登录获取全员信息
  async FETCH_QYYXTOKEN({ commit, state, getters, dispatch }) {
    // lots登录userId
    if (!getters.thirdUserId) {
      await dispatch('FETCH_USERINFO');
    }
    const paramString = { thirdUserId: getters.thirdUserId, corpCode: state.corpCode };
    // 签名规则：md5(入参 json字符串 + qyyx_sign_key)
    const sign = md5(`${JSON.stringify(paramString)}${state.signKey}`);
    // const requestParam = JSON.stringify({ paramString: JSON.stringify(paramString), sign });
    const res = await getQyyxToken({ paramString: JSON.stringify(paramString), sign });
    if (res.code === 200) {
      const data = JSON.parse(res.data);
      commit('SET_USERINFO', data);
      commit('SET_QYYXTOKEN', data.accessToken);
    }
  },
  // 获取独立部署信息
  async FETCH_DEPLOYINFO({ commit }) {
    const res = await getDeployInfo();
    if (res.status === 200) {
      commit('SET_DEPLOYINFO', res.data);
    }
  },
  // 获取小鲸用户登录信息
  async FETCH_USERINFO({ commit }) {
    const res = await getUserInfo();
    if (res.status === 200) {
      const userId = res.data.id;
      userId && commit('SET_USERID', userId);
      const { mobile } = res.data;
      mobile && commit('SET_USERINFO', { mobile });
    }
  },
  // 获取推广员身份证校验开关
  async FETCH_IDCARDSWITCH({ commit }) {
    const res = await isIdCardDisplay();
    commit('SET_IDCARDSWITCH', res.data);
  },
  // 获取推广员信息
  async FETCH_PROMOTER_USERINFO({ commit }) {
    const res = await queryUserInfo();
    const { isBindMobile, promoterType, accType } = res.data;
    commit('SET_IS_BIND_MOBILE', isBindMobile === 'T');
    commit('SET_USERINFO', { promoterType, accType });
  },
  // 获取推广员提现手续费开关
  async FETCH_SERVICE_CHARGE({ commit }) {
    const res = await queryIsOpenServiceCharge();
    commit('SET_SERVICE_CHARGE', res.data);
  },
  // 获取微信结算账户信息
  async FETCH_WXACCINFO({ commit }) {
    const res = await queryCWxParam();
    commit('SET_WXACCINFO', res.data || {});
  },
  async FETCH_LATEST_NOTICE({ commit }) {
    const res = await queryCFreshNoticeInfo();
    if (res.code === 200) {
      commit('SET_LATEST_NOTICE', res.data || '');
    }
  },
  async READ_LATEST_NOTICE({ commit }, params) {
    const res = await freshCNoticeInfo(params);
    if (res.code === 200 && res.success) {
      commit('SET_LATEST_NOTICE', '');
    }
  },
  async FETCH_NOTICE_LIST({ commit }, params) {
    const res = await queryCNoticeInfoPage(params);
    if (res.code === 200) {
      commit('SET_NOTICE_LIST', res.data);
      commit('SET_NOTICE_LIST_TOTAL', res.total);
    }
  },
  async FETCH_NOTICE_DETAILS({ commit }, params) {
    const res = await queryCNoticeInfoDetail(params);
    if (res.code === 200) {
      commit('SET_NOTICE_DETAILS', res.data);
    }
  },
  // 获取聚合码信息
  async FETCH_POLYMERIZE_CODE({ commit }, params) {
    const res = await queryPersonalPolymerizeCode(params);
    if (res.code === 200) {
      const { wxQrCodeUrl, promoteCode, promoteUrl, shortPromoteUrl, merchantInfoId } = res.data;
      const polymerizePosters = res.data?.polymerizePosters.map(item => {
        const { codingInfo, codingType, picUrl } = item;
        return {
          codingInfo: JSON.parse(codingInfo),
          codingType,
          posterPicUrl: picUrl,
        };
      });
      commit('SET_POLYMERIZE_INFO', { wxQrCodeUrl, promoteCode, promoteUrl, shortPromoteUrl, merchantInfoId, polymerizePosters });
    }
  },

  async FETCH_SPREAD_CODE_EXPIRE_CONFIG({ commit }) {
    const res = await selectSysParamByCode({ paramCode: 'cookie_prescription_config' });
    if (res.code === 200 && res.data) {
      const { value } = res.data;
      value && commit('SET_SPREAD_CODE_EXPIRE_CONFIG', JSON.parse(value));
    }
  },

  // 获取当前是否使用易生支付
  async FETCH_EASY_PAY_USE_STATUS({ commit }) {
    const res = await selectYSPayAstrict();
    if (res.code === 200) {
      commit('SET_EASY_PAY_USE_STATUS', res.data || false);
    }
  },

  // 获取易生支付进件支付中心地址
  async FETCH_EASY_PAY_SETTLE_URL({ commit }) {
    const res = await getIncomingPartsUrl();
    if (res.code === 200) {
      commit('SET_EASY_PAY_SETTLE_URL', res.data || '');
    }
  },

  // 获取易生支付绑卡状态
  async FETCH_EASY_PAY_BIND_STATUS({ commit }) {
    const res = await getIncomingPartsStatus();
    if (res.code === 200) {
      commit('SET_EASY_PAY_BIND_STATUS', res.data || '');
    }
  },

  // 获取易生支付进件绑卡信息
  async FETCH_EASY_PAY_BANK_CARD_INFO({ commit }) {
    const res = await selectBankCardInfo();
    if (res.code === 200) {
      commit('SET_EASY_PAY_BANK_CARD_INFO', res.data || {});
    }
  },

  // 获取易宝支付账户信息
  async FETCH_YEE_PAY_ACCOUNT_INFO({ commit }) {
    const res = await yeePayAccountInfo();
    if (res.code === 200) {
      commit('SET_YEE_PAY_ACCOUNT_INFO', res.data || {});
    }
  },

  // 获取易宝支付进件支付中心地址
  async FETCH_YEE_PAY_SETTLE_URL({ commit }) {
    const res = await yeePayIncomingPartsUrl();
    if (res.code === 200) {
      commit('SET_YEE_PAY_SETTLE_URL', res.data || '');
    }
  },

  // 获取是否开启众薪分佣
  async FETCH_ZCENT_PAY_FLAG({ commit }) {
    const res = await zCentPayIncomingPartsFlag();
    if (res.code === 200) {
      commit('SET_ZCENT_PAY_FLAG', res.data);
    }
  },

  // 获取众薪分佣进件状态
  async FETCH_ZCENT_PAY_SETTLE_STATUS({ commit }) {
    const res = await zCentPayIncomingPartsStatus();
    if (res.code === 200) {
      commit('SET_ZCENT_PAY_SETTLE_STATUS', res.data || '');
    }
  },

  // 获取众薪进件地址
  async FETCH_ZCENT_PAY_SETTLE_URL({ commit }) {
    const res = await zCentPayIncomingPartsUrl();
    if (res.code === 200) {
      commit('SET_ZCENT_PAY_SETTLE_URL', res.data || '');
    }
  },

  // 获取众薪绑卡信息
  async FETCH_ZCENT_PAY_BANK_INFO({ commit }) {
    const res = await zCentPayBankCardInfo();
    if (res.code === 200) {
      commit('SET_ZCENT_PAY_BANK_INFO', res.data || {});
    }
  },
  // 获取推广二维码类型
  async FETCH_PROMOTE_QR_CODE_TYPE({ commit }) {
    const res = await selectAppointConfig({ type: 'promote_qr_code_type' });
    if (res.code === 200 && res.data) {
      const { config } = res.data;
      const { qrCodetype } = JSON.parse(config);
      commit('SET_PROMOTE_QR_CODE_TYPE', +qrCodetype || 1);
    } else {
      commit('SET_PROMOTE_QR_CODE_TYPE', 1);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
