import { getIdCardServerStatus, getStoreStatus } from '@/api/merchant';

const moduleState = () => ({
  // 是否支持上传省份证 0--未初始化，1--支持，2--不支持
  idCardServer: '0',
  // 是否支持合并下单
  mergeOrderServer: '0',
});

const getters = {
  idCardServerStatus: (state) => state.idCardServer === '1',
  mergeOrderStatus: (state) => state.mergeOrderServer === '1',
};

const actions = {
  initIdCardServer({ commit, state }) {
    if (state.idCardServer === '0') {
      getIdCardServerStatus().then(res => {
        const status = res === 'T' ? '1' : '2';
        commit('updateIdCardServer', status);
      });
    }
  },
  initMergeOrderServer({ commit, state }) {
    if (state.mergeOrderServer === '0') {
      getStoreStatus('unifiedSwitch').then(res => {
        const status = res === 'T' ? '1' : '2';
        commit('updateMergeOrderServer', status);
      });
    }
  },
};

const mutations = {
  updateIdCardServer(state, idCardServer) {
    state.idCardServer = idCardServer;
  },
  updateMergeOrderServer(state, mergeOrderServer) {
    state.mergeOrderServer = mergeOrderServer;
  },
};

export default {
  namespaced: true,
  state: moduleState,
  getters,
  actions,
  mutations,
};
