import qs from 'qs';
import store from '@/store';
import router from '../router';
import querystring from 'query-string';
import { Dialog } from '@sdi/fish';
import { isWxAli } from '@/common/common';
// rsa加密与验签
import encrypt from '@/common/encrypt';
import md5 from 'js-md5';
// 在config.js文件中统一存放一些公共常量，方便之后维护
import { baseUrl, sqfxBaseUrl, linkBaseUrl } from '@/common/config.js';
import vueCookie from 'vue-cookies';
// Promise.polyfill()
import axios from 'axios';
import miniProgram from '@/common/miniProgram';
import { getToken } from '@/utils';
// app授权配置
import { getAppAuthConfig, authSupport } from '@/common/appAuth/auth';

// 阿里、wx登录
// console.log(vueCookie)
const env = isWxAli();

/**
 * 获取授权重定向地址
 * @return {String} 重定向地址
 */
function getRediurl() {
  let rediurl;
  const nextPath = localStorage.getItem('xjsc_nextpath_2019_4_20');
  if (nextPath) {
    rediurl = nextPath;
    localStorage.removeItem('xjsc_nextpath_2019_4_20');
  } else {
    rediurl = window.location.href;
  }
  // 判断是否是第三方小程序嵌入添加环境判断参数
  if (miniProgram.isOtherMP()) {
    rediurl += '&from=othermp';
  }
  return rediurl;
}

async function appAuthLogin(appAuthItem) {
  // 获取授权重定向地址
  const rediurl = getRediurl();

  // 授权方式一般有三种
  // 第一种是重定向到一个前端对应的app授权页面中写授权逻辑(为了兼容node)
  // 前端页面调用后端预授权接口，或app的sdk ，

  // 第二种授权方式为，后端直接重定向到app提供的授权地址中，
  // 后端处理完授权逻辑，拼接token 最后重定向到前端页面，
  // 前端路由拦截token，获取用户信息

  // 第三种直接拦截路由里app传入的token （第三方app在入口拼接token之类的参数）

  // 跳转前端页面授权
  if (appAuthItem.path) {
    router.push({
      path: appAuthItem.path,
      query: {
        rediurl: encodeURIComponent(rediurl),
        m_id: localStorage.getItem('xjsc_vue_2018_12_19_mid'),
      },
    });
    return;
  }

  // 后端重定向授权
  if (appAuthItem.grayKey) {
    const { grayKey } = appAuthItem;
    // 灰度判断
    const merchantId = localStorage.getItem('xjsc_vue_2018_12_19_mid');
    let isGoAuth = true;
    if (grayKey !== 'noNeed') {
      // grayKey===noNeed 表示无需灰度
      const { data } = await getAxios('/up/api/gray/info', { merchantId });
      isGoAuth = data?.[appAuthItem.grayKey];
    }

    if (isGoAuth) {
      const appParams = {
        merchantId,
        type: appAuthItem.type,
        redirectUrl: encodeURIComponent(rediurl),
      };
      const authRes = await authSupport(appParams);
      window.location.href = authRes.data;
      // console.log('统一授权地址', data);
      // // const url = `${baseUrl}up/api/auth?${querystring.stringify(appParams)}`;
      // console.log('统一授权地址', url);
      // window.location.href = url;
      return;
    }
  }

  // 以前老的授权
  const params = {
    channl: appAuthItem.channl,
    redirectUrl: encodeURIComponent(rediurl),
    merchantInfoId: localStorage.getItem('xjsc_vue_2018_12_19_mid'),
  };
  const url = `${baseUrl}leaguer/api/authApi/auth?${ querystring.stringify(params)}`;
  window.location.href = url;
}

axios.defaults.withCredentials = true;
// 添加请求拦截器，在发送请求之前做些什么(**具体查看axios文档**)--------------------------------------------
axios.interceptors.request.use(async (config) => {
  // console.log(config)
  // 显示loading
  // console.log('开始请求...')
  // cookie 存放的是node端存入的，本地缓存的是vue密码登录存入的
  const token = getToken();
  const trace_device_id = vueCookie.get('trace_device_id');
  const trace_sess_id = vueCookie.get('trace_sess_id');
  const web_browser_trace_id = vueCookie.get('web_browser_trace_id');
  // 获取全员营销的登录账号和密码，绝对不能添加请求头的access-token，不然请求会报错
  if (config.url !== '//qyyxcs.sendinfo.com.cn/wap/enterPromote.htm' && config.url !== '//qyyx.zhiyoubao.com/wap/enterPromote.htm') {
    config.headers['access-token'] = token || '';
    config.headers.trace_device_id = trace_device_id || '';
    config.headers.trace_sess_id = trace_sess_id || '';
    config.headers.web_browser_trace_id = web_browser_trace_id || '';
  }
  if (config.url.includes('/marketing/api/log/save')) {
    config.headers.traceId = store.state.point.traceId || '';
    config.headers.sessionId = store.state.point.sessionId || '';
    config.headers.browserId = store.state.point.browserId || '';
  }
  if (config.params) {
    // {boolean} unLoading 关闭接口加载loading动画
    if (!config.params.unLoading) {
      store.dispatch('updateLoadingStatus', true);

      delete config.params.unLoading;
    }
  }
  // saas 年卡  post json
  if (/^\/newapiyearcard/.test(config.url)) {
    if (!config.params) config.params = {};
    Object.assign(config.params, {
      merchantInfoId: localStorage.getItem('xjsc_vue_2018_12_19_mid'),
    });
  }

  // 全员独立部署拦截
  if (config.url.includes('sendinfo-promotion') && !config.url.includes('/cMarketingProductService/queryProductByPromoteCode')) {
    if (!store.state.sqfx.isThirdPart && !store.state.sqfx.isDeploySwitch) {
      await store.dispatch('sqfx/FETCH_DEPLOYINFO');
    }
    const { isDeploySwitch } = store.state.sqfx;
    if (isDeploySwitch === '1') {
      config.baseURL = sqfxBaseUrl;
      console.log('2222', store);

      if (!config.url.includes('source=qyyx')) {
        config.url = config.url.includes('?') ? `${config.url}&source=qyyx` : `${config.url}?source=qyyx`;
      }
      if (config.url.includes('/userService/getPromoterByPromoteCode')) {
        config.url = '/sendinfo-promotion/externalUserService/externalGetPromoterByPromoteCode?source=qyyx';
        const paramString = {
          promoteCode: config.data.promoteCode,
        };
        const sign = md5(`${JSON.stringify(paramString)}${store.state.sqfx.signKey}`);
        config.data = {
          paramString: JSON.stringify(paramString),
          sign,
        };
      } else {
        // const lotsToken = getToken();
        // const getKey = () => (lotsToken ? md5(lotsToken) : null);
        // const getSession = () => (getKey() ? sessionStorage.getItem(`qyyx_${getKey()}`) : null);
        if (!store.getters['sqfx/qyyxToken']) {
          await store.dispatch('sqfx/FETCH_QYYXTOKEN');
        }
        const qyyxToken = store.getters['sqfx/qyyxToken'];
        config.headers['qyyx-token'] = qyyxToken;
      }
    }
  }

  return config;
},
(error) => {
  // 请求错误时弹框提示，或做些其他事
  store.dispatch('updateLoadingStatus', false);
  return Promise.reject(error);
});

// 添加响应拦截器(**具体查看axios文档**)----------------------------------------------------------------
axios.interceptors.response.use(
  async (response) => {
    if (response.config && response.config.url.includes('/marketing/api/log/save')) {
      store.dispatch('point/changePointData', response.headers);
    }
    // 对响应数据做点什么，允许在数据返回客户端前，修改响应的数据
    // 如果只需要返回体中数据，则如下，如果需要全部，则 return response 即可
    // console.log('请求结束。。。')
    store.dispatch('updateLoadingStatus', false);
    if (response.data.status === 500) {
      Dialog.alert({
        title: '提示',
        message: `${response.data.status },${ response.data.message}`,
      });
      // error._message = response.data.status + ',' + response.data.message
    } else if (response.data.status === 400 || response.data.code === 1004001005) {
      const respon = response;
      respon.response = {
        status: 400,
      };
      errorState(respon);
    } else if (response.data.code === 60006 && store.state.sqfx.isDeploySwitch === '1') {
      // 全员独立部署token失效拦截
      await store.dispatch('sqfx/FETCH_QYYXTOKEN');
      return axios(response.config);
    } else if (response.data.code === 1005003113) {
      // 1005003113 用户中心弱口令整改
      Dialog.alert({
        title: '提示',
        message: `${ response.data.message}`,
      }).then(() => {
        const redirSign = window.location.href.split('redir=');
        const redir = redirSign.length > 1 ? redirSign[1] : '';
        router.push({
          path: '/forgetPw',
          query: {
            redir,
            type: 'weakPasswordUpdateNeed',
            ...router.currentRoute.query,
          },
        });
      });
    } else {
      return response.data;
    }
  },
  (error) => {
    // 对响应错误做点什么
    // console.log("error22", error)
    // 过滤400状态，410未绑定手机号暂时不过滤，看下是否接口稳定后再过滤
    if (!(error.response && Number(error.response.status) === 400)) {
      postRrrorData(error);
    }
    store.dispatch('updateLoadingStatus', false);
    if (error && error.response && error.response.status) {
      switch (error.response.status) {
        case 403:
          error._message = '拒绝访问';
          break;
        case 404:
          error._message = `请求地址出错:${ error.response.config.url}`;
          break;
        case 408:
          error._message = '请求超时';
          break;
        case 500:
          error._message = '服务器繁忙';
          break;
        case 501:
          error._message = '服务未实现';
          break;
        case 502:
          error._message = '网关错误';
          break;
        case 503:
          error._message = '服务不可用';
          break;
        case 504:
          error._message = '网关超时';
          break;
        case 505:
          error._message = 'HTTP版本不受支持';
          break;
        case 429:
          error._message = '活动火热进行中，请稍后再试';
          break;
        default:
          if (typeof error.response.data === 'object' && (error.response.data.message || error.response.data.respMsg)) {
            error._message = error.response.data.message || error.response.data.respMsg;
          }
          break;
      }
    }

    return Promise.reject(error);
  },
);
// 错误监听白名单，防止本身几问题导致错误回传死循环
const whiteUrlArr = [
  '/marketing/api/log/error',
  '/marketing/api/log/save',
];

function checkUrl(url) {
  return whiteUrlArr.some(item => {
    const reg = new RegExp(item);
    return reg.test(url);
  });
}

// 提交错误信息
function postRrrorData(error) {
  // 当前浏览器域名
  if (!checkUrl(error.request.responseURL)) {
    apiAxios('POST', '/marketing/api/log/error', {
      source: 'client',
      url: error.request.responseURL,
      traceType: 'front_error',
      errMsg: JSON.stringify(error.response.data),
    }, '', true, true);
  }
}


// 封装数据返回失败提示函数---------------------------------------------------------------------------
async function errorState(response) {
  // alert(JSON.stringify(response))
  // 隐藏loading
  // 如果http状态码正常，则直接返回数据
  // console.log(response.response.status)
  // 过滤接口，请求是埋点的接口主动过滤
  if (response.config && (response.config.url === '/marketing/api/log/save' || response.config.url === '/marketing/api/log/error')) {
    return false;
  }
  if (response && response.response) {
    if ([400, 403, 401].includes(response.response.status)) {
      // wxH5Auth字段存在并且等于T的时候即使是小程序环境内嵌H5即使token过期也不走小程序授权
      const { wxH5Auth = 'F' } = router.currentRoute.query;
      if (miniProgram.isMP() && wxH5Auth !== 'T') {
        if (miniProgram.isMP() === 'wx') {
          try {
            const q = querystring.parseUrl(window.location.href);
            if (q.query.token) {
              delete q.query.token;
            }
            if (q.query.cacheCode) {
              delete q.query.cacheCode;
            }

            const redirect = window.encodeURIComponent(querystring.stringifyUrl(q));
            miniProgram.navigateTo(
              `/pages/authorization/index?redirect=${ redirect }`,
            );
          } catch (e) {
            miniProgram.navigateTo(
              '/pages/authorization/index',
            );
          }
          return;
        } else {
          miniProgram.redirectTo('/pages/index/index');
          return;
        }
      }
      const appAuthItem = getAppAuthConfig();
      if (appAuthItem) {
        const { channl } = appAuthItem;
        if (channl === 'WEIXIN') {
          // 微信
          const res = await apiAxios('GET', '/pay/api/getPayInfo', {
            payCode: 'wxZyb',
          });
          const wxAccount = {
            appid: '',
            appsecret: '',
          };
          if (res?.data?.details?.length) {
            res.data.details.forEach(element => {
              if (element.payKey === 'appid') {
                wxAccount.appid = element.payValue;
              } else if (element.payKey === 'appsecret') {
                wxAccount.appsecret = element.payValue;
              }
            });
          }
          localStorage.setItem('xjsc_vue_2019_3_19_wxAccount', JSON.stringify(wxAccount));
        }
        appAuthLogin(appAuthItem);
        return;
      }
      // 其他浏览器
      localStorage.setItem('xjsc_nextpath_2019_4_20', window.location.href);
      router.push({
        path: `/login?m_id=${ localStorage.getItem('xjsc_vue_2018_12_19_mid')}`,
      });
    } else if (Number.parseFloat(response.response.status) === 410) {
      // 手机浏览器登录用户直接用手机号码登录不需要绑定手机号码，也就支付宝和微信H5用户需要
      // 410状态微信h5及支付宝h5用户登录后未绑定手机号码
      // 需要跳转手机号码绑定页面
      const rediurl = encodeURIComponent(window.location.href);
      if (process.env.NODE_ENV === 'production') {
        const url = `${linkBaseUrl }/vue/bind/mobile?m_id=${ localStorage.getItem('xjsc_vue_2018_12_19_mid') }&rediurl=${ rediurl}`;
        if (window.location.href !== url) {
          window.location.href = url;
        }
      } else {
        router.push({
          path: `/bind/mobile?rediurl=${ rediurl}`,
        });
      }
    } else {
      Dialog.alert({
        title: '提示',
        message: response._message ? response._message : '连接错误',
      });
    }
  } else {
    const originalRequest = response.config;
    if (response.code === 'ECONNABORTED' && response.message.indexOf('timeout') !== -1 && !originalRequest._retry) {
      response._message = '请求超时';
      // alert(response._message)
      Dialog.alert({
        title: '提示',
        message: response._message,
      });
    }
  }
}


function getOrderWayType() {
  const result = {};
  if (miniProgram.isMP() === 'wx') {
    result.wayType = '6';
  } else if (miniProgram.isMP() === 'tt') {
    result.wayType = 'D';
  } else if (env.isWx) {
    result.wayType = '2';
  } else if (env.isAliPay) {
    result.wayType = '3';
  }
  return result;
}

// 封装axios--------------------------------------------------------------------------------------
// igError （true | false） 是否启用公用错误处理
export function apiAxios(method, url, params, base, igError, jsonFlag) {
  // console.log(localStorage.getItem('xjsc_vue_2018_12_19_mid'))
  const merchantInfoId = localStorage.getItem('xjsc_vue_2018_12_19_mid');
  if (jsonFlag) {
    axios.defaults.headers.post['Content-Type'] =
      'application/json;charset=UTF-8';
  } else {
    axios.defaults.headers.post['Content-Type'] =
      'application/x-www-form-urlencoded;charset=UTF-8';
  }
  // 小程序环境修改下单渠道
  if (Object.keys(params).includes('wayType') && params.wayType !== 'G') {
    Object.assign(params, getOrderWayType());
  }

  // console.log(merchantInfoId)
  params.xj_time_stamp_2019_11_28 = new Date().getTime();
  if (!params.merchantInfoId) params.merchantInfoId = merchantInfoId;
  if (!params.merchantId) params.merchantId = merchantInfoId;

  // {boolean} sign 接口是否需要验签
  const { sk, sign } = encrypt.setSign(params, jsonFlag);
  axios.defaults.headers.sk = sk;
  axios.defaults.headers.sign = sign;

  const httpDefault = {
    method,
    url,
    // `params` 是即将与请求一起发送的 URL 参数
    // `data` 是作为请求主体被发送的数据
    params: method === 'GET' || method === 'DELETE' ? params : null,
    // eslint-disable-next-line no-nested-ternary
    data: method === 'POST' || method === 'PUT' ?
      jsonFlag ? params : qs.stringify(params) : null,
    timeout: 10000,
    baseURL: base || baseUrl,
  };
  // alert(JSON.stringify(httpDefault))
  // 注意**Promise**使用(Promise首字母大写)
  return new Promise((resolve, reject) => {
    axios(httpDefault)
      // 此处的.then属于axios
      .then(response => {
        // successState(response)
        if (
          response && (((Number(response.code) === 200) || (Number(response.status) === 200) || (response.status && response.status.success) || response.success) || (Number(response.respCode) === 200))
        ) {
          // 请求成功
          resolve(response);
        } else if (response.status === 1002001) {
          reject(response);
        } else {
          reject(response);

          if (!igError) {
            Dialog.alert({
              title: '提示',
              message: response.message || response.respMsg,
            });
          }
          // alert(response.message || response.respMsg);
          if (!igError && (response.status && Number(response.status) !== 500)) {
            Dialog.alert({
              title: '提示',
              message: response.message || response.respMsg,
            });
          }
          // alert(response.message || response.respMsg);
          // 阻止代码继续执行
        }
      })
      .catch((response) => {
        !igError && errorState(response);
        // 过滤410和400错误，410绑定手机号码和400未登录在errorState中已经针对这两个做了处理，不需要页面处理
        if (response && response.response && response.response.status && (Number(response.response.status) !== 400 && Number(response.response.status) !== 410 && Number(response.response.status) !== 401)) {
          reject(response);
        }
      });
  });
}

const jsonAxios = (url, params, base, igError) => apiAxios('POST', url, params || {}, base, igError, true);
const postAxios = (url, params, base, igError) => apiAxios('POST', url, params || {}, base, igError);
const getAxios = (url, params, base, igError) => apiAxios('GET', url, params || {}, base, igError);
const putAxios = (url, params, base, igError) => apiAxios('PUT', url, params || {}, base, igError);
const deleteAxios = (url, params, base, igError) => apiAxios('DELETE', url, params || {}, base, igError);

/* eslint-disable */
const jsonAxiosDel = (url, params, base, igError) => {
  const paramsTemp = params || {};
  const merchantInfoId = localStorage.getItem('xjsc_vue_2018_12_19_mid');
  paramsTemp.merchantInfoId = Number(merchantInfoId);
  for (const key in paramsTemp) {
    if (Object.prototype.hasOwnProperty.call(paramsTemp, key)) {
      const element = paramsTemp[key];
      if (element === '' || element === null || element === undefined) {
        delete paramsTemp[key];
      }
    }
  }
  return apiAxios('POST', url, paramsTemp, base, igError, true);
};
/* eslint-enable */
export {
  jsonAxios,
  jsonAxiosDel,
  postAxios,
  getAxios,
  putAxios,
  deleteAxios,
};

// 输出函数getAxios、postAxios、putAxios，供其他文件调用-----------------------------
// Vue.js的插件应当有一个公开方法 install。这个方法的第一个参数是 Vue 构造器，第二个参数是一个可选的选项对象。
export default {
  install(Vue) {
    Vue.prototype.getAxios = (url, params, base, igError) => apiAxios('GET', url, params || {}, base, igError);
    Vue.prototype.jsonAxios = jsonAxios;
    Vue.prototype.jsonAxiosDel = jsonAxiosDel;
    Vue.prototype.postAxios = (url, params, base, igError) => apiAxios('POST', url, params || {}, base, igError);
    Vue.prototype.putAxios = (url, params, base, igError) => apiAxios('PUT', url, params || {}, base, igError);
    Vue.prototype.deleteAxios = (url, params, base, igError) => apiAxios('DELETE', url, params || {}, base, igError);
  },
};
