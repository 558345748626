/**
 * 将vuex的模块数据存到缓存里
 * @param {*} data 要传的数据
 * @param {*} store vuex store对象
 * @param {*} Fn 调用的接口
 */
export function saveData(keyList, store, fn) {
  const data = [];
  keyList.forEach(item => {
    data.push({
      key: item,
      data: store.state[item],
    });
  });
  return fn(data);
}
/**
 * 初始化vuex数据
 * @param {*} dataList 接口获取的数据
 * @param {*} store vuex store对象
 */
export function initData(dataList, store) {
  if (!dataList) return;
  const data = {};
  dataList.forEach(item => {
    data[item.key] = item.data;
  });
  store.replaceState(Object.assign({}, store.state, data));
}


export default {
  saveData,
  initData,
};
