const virtualProductsRouter = [
  {
    path: '/virtualCard/list',
    name: 'VirtualCardList',
    component: () => import(/* webpackChunkName: "route-cart" */ '@/pages/virtualProduct/list'),
    meta: {
      title: '我的票券',
    },
  },
  {
    path: '/virtualCard/refund',
    name: 'VirtualCardRefund',
    component: () => import(/* webpackChunkName: "route-cart" */ '@/pages/virtualProduct/refund'),
    meta: {
      title: '票券退单',
    },
  },
  {
    path: '/virtualCard/appointment',
    name: 'appointment',
    component: () => import(/* webpackChunkName: "route-pre-sale" */ '@/pages/virtualProduct/secondOrder'),
    meta: {
      title: '票券兑换',
    },
  },
];

export default virtualProductsRouter;
