import vm from 'vue';
import { getUserInfo } from '@/ajax/member';

export default {
  namespaced: true,
  state: {
    userInfo: {},
  },
  getters: {
    // 获取用户信息
    GET_USER_INFO(state) {
      return state.userInfo;
    },
  },
  mutations: {
    /**
     * 设置用户信息
     * @param {Object} userInfo 用户信息
     */
    SET_USER_INFO(state, userInfo) {
      vm.set(state, 'userInfo', userInfo);
    },
  },
  actions: {
    async updateUserInfo({ commit }) {
      try {
        const user = await getUserInfo();
        if (user.status === 200) {
          commit('SET_USER_INFO', user.data);
          return user;
        }
      } catch (error) {
        throw new Error(error);
      }
    },
  },
};
