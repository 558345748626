class Chain {
  constructor(fn) {
    this.fn = fn;
    this.successor = null;
  }

  setNextSuccessor(successor) {
    this.successor = successor;
  }

  passRequset(...arg) {
    const ret = this.fn.call(this, ...arg);
    if (ret === 'next') {
      return (
        this.successor && this.successor.passRequset.call(this.successor, ...arg)
      );
    }
    return ret;
  }

  next(...arg) {
    return (
      this.successor && this.successor.passRequset.call(this.successor, ...arg)
    );
  }
}
// 后续有需要可以添加命令回滚，中止等功能
class Command {
  constructor(receiver, needAwait = false) {
    this.receiver = receiver;
    this.needAwait = needAwait;
  }

  async execute(...arg) {
    if (this.needAwait) {
      await this.receiver.call(this.receiver, ...arg);
    } else {
      this.receiver.call(this.receiver, ...arg);
    }
  }
}

class MacroCommand {
  constructor() {
    this.commandsList = [];
  }

  add(command) {
    this.commandsList.push(command);
  }

  async execute(...arg) {
    for (let index = 0; index < this.commandsList.length; index++) {
      const command = this.commandsList[index];
      if (command.needAwait) {
        await command.execute(...arg);
      } else {
        command.execute(...arg);
      }
    }
  }
}

class Event {
  constructor() {
    this.clientList = {};
  }

  listen(key, fn) {
    if (!this.clientList[key]) {
      this.clientList[key] = [];
    }
    this.clientList[key].push(fn);
  }

  remove(key, fn) {
    const fns = this.clientList[key];
    if (!fns) {
      return false;
    }
    if (!fn) {
      fns && (fns.length = 0);
    } else {
      for (let i = fns.length - 1; i >= 0; i--) {
        const currentFn = fns[i];
        if (currentFn === fn) {
          fns.splice(i, 1);
        }
      }
    }
  }

  trigger(key, ...args) {
    const fns = this.clientList[key];
    if (!fns || fns.length === 0) {
      return false;
    }
    fns.forEach((fn) => {
      fn.apply(this, args);
    });
  }
}


export {
  Chain,
  Command,
  MacroCommand,
  Event,
};
