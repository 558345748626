/**
 * 全员营销路由
 */
 const sqfxRoute = [
  {
    path: '/workBench',
    name: 'WorkBench',
    component: () => import(/* webpackChunkName: "route-sqfx" */ '@/pages/sqfx/workBench'),
    meta: {
      title: '全员营销',
      tag: 'sqfx',
    },
  },
  {
    path: '/updateUserInfo',
    name: 'updateUserInfo',
    component: () => import(/* webpackChunkName: "route-sqfx" */ '@/pages/sqfx/user/updateUserInfo'),
    meta: {
      title: '个人信息修改',
      tag: 'sqfx',
    },
  },
  {
    path: '/productExtendList',
    name: 'ProductExtendList',
    component: () => import(/* webpackChunkName: "route-sqfx" */ '@/pages/sqfx/productExtendList'),
    meta: {
      tag: 'sqfx',
    },
  },
  {
    path: '/promoteProductList',
    name: 'PromoteProductList',
    component: () => import(/* webpackChunkName: "route-sqfx" */ '@/pages/sqfx/promoteProductList'),
    meta: {
      title: '产品列表',
      tag: 'sqfx',
    },
  },
  {
    path: '/moneyRecord',
    name: 'MoneyRecord',
    component: () => import(/* webpackChunkName: "route-sqfx" */ '@/pages/sqfx/moneyRecord'),
    meta: {
      tag: 'sqfx',
    },
  },
  {
    path: '/moneyTake',
    name: 'MoneyTake',
    component: () => import(/* webpackChunkName: "route-sqfx" */ '@/pages/sqfx/moneyTake'),
    meta: {
      tag: 'sqfx',
    },
  },
  {
    path: '/moneyDetail',
    name: 'MoneyDetail',
    component: () => import(/* webpackChunkName: "route-sqfx" */ '@/pages/sqfx/moneyDetail'),
    meta: {
      tag: 'sqfx',
    },
  },
  {
    path: '/extendOrder',
    name: 'ExtendOrder',
    component: () => import(/* webpackChunkName: "route-sqfx" */ '@/pages/sqfx/extendOrder'),
    meta: {
      tag: 'sqfx',
    },
  },
  {
    path: '/inviteOrder',
    name: 'InviteOrder',
    component: () => import(/* webpackChunkName: "route-sqfx" */ '@/pages/sqfx/inviteOrder'),
    meta: {
      tag: 'sqfx',
    },
  },
  {
    path: '/sqfxOrderDetail',
    name: 'sqfxOrderDetail',
    component: () => import(/* webpackChunkName: "route-sqfx" */ '@/pages/sqfx/orderDetail'),
    meta: {
      tag: 'sqfx',
    },
  },
  {
    path: '/myInvite',
    name: 'MyInvite',
    component: () => import(/* webpackChunkName: "route-sqfx" */ '@/pages/sqfx/myInvite'),
    meta: {
      tag: 'sqfx',
    },
  },
  {
    path: '/gathering',
    name: 'gathering',
    component: () => import(/* webpackChunkName: "route-sqfx" */ '@/pages/sqfx/gathering'),
    meta: {
      tag: 'sqfx',
    },
  },
  {
    path: '/settlementAccount',
    name: 'settlementAccount',
    component: () => import(/* webpackChunkName: "route-sqfx" */ '@/pages/sqfx/settlementAccount'),
    meta: {
      title: '结算账户',
      tag: 'sqfx',
    },
  },
  {
    path: '/inviteFriend',
    name: 'InviteFriend',
    component: () => import(/* webpackChunkName: "route-sqfx" */ '@/pages/sqfx/inviteFriend'),
    meta: {
      tag: 'sqfx',
    },
  },
  {
    path: '/activityExtend',
    name: 'ActivityExtend',
    component: () => import(/* webpackChunkName: "route-sqfx" */ '@/pages/sqfx/activityExtend'),
    meta: {
      tag: 'sqfx',
    },
  },
  {
    path: '/invite',
    name: 'Invite',
    component: () => import(/* webpackChunkName: "route-sqfx" */ '@/pages/sqfx/invite'),
    meta: {
      tag: 'sqfx',
    },
  },
  {
    path: '/oldQyBindXj',
    name: 'oldQyBindXj',
    component: () => import(/* webpackChunkName: "route-sqfx" */ '@/pages/sqfx/redirectTransfer'),
    meta: {
      tag: 'sqfx',
    },
  },
  {
    path: '/noticeList',
    name: 'noticeList',
    component: () => import(/* webpackChunkName: "route-sqfx" */ '@/pages/sqfx/announcementsList'),
    meta: {
      title: '公告',
      tag: 'sqfx',
    },
  },
  {
    path: '/noticeDetails',
    name: 'noticeDetails',
    component: () => import(/* webpackChunkName: "route-sqfx" */ '@/pages/sqfx/announcementDetails'),
    meta: {
      tag: 'sqfx',
    },
  },
  {
    path: '/boundDistributor',
    name: 'boundDistributor',
    component: () => import(/* webpackChunkName: "route-sqfx" */ '@/pages/sqfx/boundDistributor'),
    meta: {
      title: '绑定',
      tag: 'sqfx',
    },
  },
  {
    path: '/promoterManageList',
    name: 'promoterManageList',
    component: () => import(/* webpackChunkName: "route-sqfx" */ '@/pages/sqfx/promoterManageList'),
    meta: {
      title: '推广员管理',
      tag: 'sqfx',
    },
  },
  {
    path: '/promoterManageInfo',
    name: 'promoterManageInfo',
    component: () => import(/* webpackChunkName: "route-sqfx" */ '@/pages/sqfx/promoterManageInfo'),
    meta: {
      title: '推广员信息',
      tag: 'sqfx',
    },
  },
  {
    path: '/inviteWhitelist',
    name: 'inviteWhitelist',
    component: () => import(/* webpackChunkName: "route-sqfx" */ '@/pages/sqfx/inviteWhitelist'),
    meta: {
      title: '邀请白名单',
      tag: 'sqfx',
    },
  },
  {
    path: '/withdrawalZyPay',
    name: 'withdrawalZyPay',
    component: () => import(/* webpackChunkName: "route-sqfx" */ '@/pages/sqfx/withdrawalZyPay'),
    meta: {
      title: '提现确认',
      tag: 'sqfx',
    },
  },
  {
    path: '/withdrawalResult',
    name: 'withdrawalResult',
    component: () => import(/* webpackChunkName: "route-sqfx" */ '@/pages/sqfx/withdrawalResult'),
    meta: {
      title: '提现申请',
      tag: 'sqfx',
    },
  },
  {
    path: '/withdrawalDetail',
    name: 'withdrawalDetail',
    component: () => import(/* webpackChunkName: "route-sqfx" */ '@/pages/sqfx/withdrawalDetail'),
    meta: {
      title: '提现详情',
      tag: 'sqfx',
    },
  },
  {
    path: '/withdrawalRecord',
    name: 'withdrawalRecord',
    component: () => import(/* webpackChunkName: "route-sqfx" */ '@/pages/sqfx/withdrawalRecord'),
    meta: {
      title: '提现记录',
      tag: 'sqfx',
    },
  },
  {
    path: '/promoterBind',
    name: 'registerBind',
    component: () => import(/* webpackChunkName: "route-sqfx" */ '@/pages/sqfx/registerBind'),
    meta: {
      title: '推广员注册绑定',
      tag: 'sqfx',
    },
  },
];

export default sqfxRoute;
