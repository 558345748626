import { getAxios, postAxios } from './axios';
/**
 * 获取列表目的地筛选数据
 * @param {string} type 业态类型 eatery-餐馆；park-停车场；pms_hotel-酒店；route-跟团游
 * @param {string} areaCode 编码
 * @param {string} areaType 区域类型 province-省；city-市；area-区
 */
export function getListLocation({ type, areaCode = '', areaType = '' } = {}) {
  const url = '/product/api/product/getLevelAreas';

  const params = {
    businessType: type,
    areaCode,
    areaType: areaType ? `${areaType}Code` : '',
  };

  return getAxios(url, params);
}
/**
 * 存数据--用于小程序内全局数据同步到内嵌h5中
 * @param {object} data
 */
export function setCacheData(data) {
  const url = '/product/api/product/saveData';
  data = JSON.stringify(data);
  return postAxios(url, { data }).then(res => {
    return res.message;
  });
}
/**
 * 取数据--获取setData存进去的数据
 * @param {string} key
 */
export function getCacheData(key) {
  const url = '/product/api/product/getData';
  return getAxios(url, { cacheCode: key }).then(res => {
    return res.message ? JSON.parse(res.message) : null;
  });
}

export default {
  getListLocation,
  setCacheData,
  getCacheData,
};
