import miniProgram from '@/common/miniProgram.js';

function catchMap(data) {
  const { lnglat } = data;
  if (lnglat) {
    catchPathMap(data);
  } else {
    catchStaticMap(data);
  }
}

function catchPathMap(data) {
  const { lnglat, address } = data;
  const miniType = miniProgram.isMP();
  if (!miniType) {
    window.location.href = `//uri.amap.com/navigation?to=${lnglat},${address}&mode=car&callnative=1`;
  } else if (miniType === 'wx') {
    miniProgram.navigateTo(`/pages/map/index?lnglat=${lnglat}&address=${address}`);
  }
}

function catchStaticMap(data) {
  console.log(data);
}

export default catchMap;
