/*
 * @Author: mikey.qiaoyang
 * @Date: 2019-09-19 11:39:47
 * @Last Modified by: mikey.zhaopeng
 * @Last Modified time: 2021-01-13 17:30:21
 */
// 管理app.vue中loading的显示
import Vm from '@/main.js';
import { getContactsList } from '@/api/leaguer';

const Traffic = {
  namespaced: true, // 不要修改,命名空间
  state: {
    lineInfo: {},
    orderDate: {}, // 下单时间
    manList: [], // 所有联系人
    userInfo: {}, // 缓存用户信息
    linkManInfo: {
      name: '',
      certNo: '',
      phone: '',
    },
    buyManList: [
      {
        name: '',
        certNo: '',
        idcardType: 'id',
        key: new Date().getTime(),
      },
    ],
    remainCount: 0,
    minCount: 1,
    maxCount: 9999,
  },
  getters: {
    totalPrice(state) {
      const len = state.buyManList.length;
      const totalPrice = len
        ? (len * (state.lineInfo.priceRetail * 100) / 100).toFixed(2)
        : '0.00';
      return totalPrice;
    },
  },
  mutations: {
    setLineIno(state, payload) {
      state.lineInfo = payload;
    },
    setOrderDate(state, payload) {
      state.orderDate = JSON.parse(JSON.stringify(payload));
    },
    setManList(state, payload) {
      state.manList = payload;
    },
    setUserInfo(state, payload) {
      state.userInfo = payload;
    },
    setLinkMan(state, payload) {
      state.linkManInfo = payload;
    },
    setBuyMan(state, payLoad) {
      state.buyManList = payLoad;
    },
    setRemainCount(state, count) {
      state.remainCount = count;
    },
    setMinCount(state, count) {
      state.minCount = count;
    },
    setMaxCount(state, count) {
      state.maxCount = count;
    },
  },
  actions: {
    getManAxios({ commit }) {
      getContactsList().then(res => {
        if (res.code === 200) {
          const { data } = res;
          commit('setManList', data);
        }
      });
    },
    getUserInfoAxios({ commit }) {
      Vm.getAxios('/leaguer/api/userLeaguer/manage/leaguerInfo').then(res => {
        const { realName: name, idcard: certNo, mobile: phone } = res.data;
        commit('setUserInfo', res.data);
        commit('setLinkMan', {
          name,
          certNo,
          phone,
        });
      });
    },
    emptyData({ commit }) {
      commit('setBuyMan', [
        {
          name: '',
          certNo: '',
          idcardType: 'id',
          key: new Date().getTime(),
        },
      ]);
    },
  },
};

export default Traffic;
