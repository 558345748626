const rangeList = [{
  key: 'day',
  re: /^[0-9]+[D|d]$/,
  unit: 24 * 60 * 60 * 1000,
}, {
  key: 'hour',
  re: /^[0-9]+[H|h]$/,
  unit: 60 * 60 * 1000,
}, {
  key: 'minute',
  re: /^[0-9]+[M|m]$/,
  unit: 60 * 1000,
}, {
  key: 'second',
  re: /^[0-9]+[S|s]$/,
  unit: 1000,
}];
/**
 * 根据时间返回毫秒数 ex---1d,3m,5h ->
 * @param {string | number} value
 */
export function getTimeRange(value) {
  if (typeof value !== 'string' && typeof value !== 'number') {
    throw new Error('function getTimeRange param need string or number');
  }
  for (let i = 0; i < rangeList.length; i++) {
    const item = rangeList[i];
    if (item.re.test(value)) {
      return Number(value.slice(0, value.length - 1)) * item.unit;
    }
  }
  if (typeof value !== 'number') {
    throw new Error('invalid params unit');
  }

  return value;
}

export default { getTimeRange, rangeList };
