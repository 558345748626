import axios from 'axios';
import qs from 'qs';
import miniProgram from '@/common/miniProgram';
import store from '@/store';
import VueCookies from 'vue-cookies';

let _env = null;

function isWxAli() {
  if (_env) {
    return _env;
  }

  const low = window.navigator.userAgent.toLowerCase();
  const isAliPay = /AlipayClient/.test(window.navigator.userAgent);
  const isWx = /MicroMessenger/.test(window.navigator.userAgent);
  // 不是微信或支付宝时
  const isTablet = !(isAliPay || isWx);
  // 银联云闪付
  const isYunPay = ((/(com.unionpay.chsp)/i.test(low)) || (/(com.unionpay.mobilepay)/.test(low))); // 银联云闪付
  // 银联云闪付h5
  const isYunPayH5 = () => isYunPay && VueCookies.get(`appType_${localStorage.getItem('xjsc_vue_2018_12_19_mid')}`) !== 'yunPayMiniApp';
  // 银联云闪付小程序
  const isYunPayMiniApp = () => isYunPay && VueCookies.get(`appType_${localStorage.getItem('xjsc_vue_2018_12_19_mid')}`) === 'yunPayMiniApp';
  // 六安人社app
  const isLuAnRenShe = () => VueCookies.get(`appType_${localStorage.getItem('xjsc_vue_2018_12_19_mid')}`) === 'luAnRenSheApp';
  _env = {
    isWx,
    isAliPay,
    isTablet,
    isYunPay, // 银联云闪付
    isYunPayH5, // 银联云闪付h5
    isYunPayMiniApp, // 银联云闪付小程序
    isABCPay: /abc/.test(low), // 是否是农行
    isMFS: /zjrcu/.test(low), // 是否是丰收互联
    isCroods: /iflytek_mmp.linkmessenger/i.test(low), // 是否是皖事通
    isZhileshan: /com.wisdom.leshan/i.test(low), // 是否是智慧乐山app
    isChangZhouRS: /changzhours/i.test(low), // 常州社保卡
    isYouShanXiApp: /JyznApp/i.test(low), // 是否是游山西app 游山西ua：iOS：JyznApp/YJY_SHANXI_APP_IOS   Android：JyznApp/YJY_SHANXI_APP_ANDROID
    isHuaWei: /HMCLIENT/i.test(low), // 是否是华为鸿蒙 胡龙飞对接
    isSZDApp: /MYSZAPP szd_SZGov/i.test(low), // 是否是苏周到app 下载苏周到app扫码测试
    isiyixing: /iyixing/i.test(low), // 是否是i宜兴app
    isLuAnRenShe, // 是否是六安人社app
  };
  return _env;
}

function isiOS() {
  const u = navigator.userAgent;
  const isAndroid = u.indexOf('Android') > -1 || u.indexOf('android') > -1 || u.indexOf('Linux') > -1; // g
  const isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
  return {
    isAndroid,
    isIOS,
  };
}

// 统一处理订单
function order(formData, modelCodes) {
  const env = isWxAli();
  return new Promise(() => {
    axios.post('/order/api/saveOrder', qs.stringify(formData)).then(() => {
      // const orderNo = result.data.payOrderNo;
      // const orderInfo = result.data.orderDescription;
      // const orderId = result.data.id;
      // let businessType = '';
      if (env.isWx) {
        // 如果是微信端
        axios.get('/merchant/api/validateZeroRateTicket', {
          modelCode: modelCodes.join(','),
        }).then(
          // res => {
          //   if (res.message == 'T') {
          //     businessType = 'YXT';
          //   } else {
          //     businessType = 'WEIXIN';
          //   }
          //   // getPayType(businessType);
          // },
          // reject => {
          //   reject();
          // },
        );
      } else {
        // 非微信端
        // businessType = env.isAliPay ? 'ALI' : 'WAP';
        // this.getPayType(businessType);
      }
    });
  });
}

function wxPay(payParams) {
  function onBridgeReady() {
    // eslint-disable-next-line no-undef
    WeixinJSBridge.invoke(
      'getBrandWCPayRequest', payParams.jsons,
      (res) => {
        // alert(JSON.stringify(res))
        if (res.err_msg === 'get_brand_wcpay_request:ok') {
          // 使用以上方式判断前端返回,微信团队郑重提示：
          // res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          payParams.success();
        } else {
          payParams.fail();
        }
      },
    );
  }

  if (typeof WeixinJSBridge === 'undefined') {
    if (document.addEventListener) {
      document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
    } else if (document.attachEvent) {
      document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
      document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
    }
  } else {
    onBridgeReady();
  }
}

/**
 * 检测图片是否存在
 * @param url
 */
const imageIsExist = function (url) {
  return new Promise((resolve) => {
    let img = new Image();
    img.onload = function () {
      if (this.complete === true) {
        resolve(true);
        img = null;
      }
    };
    img.onerror = function () {
      resolve(false);
      img = null;
    };
    img.src = url;
  });
};

// 数据乱序
function randomSort(arr) {
  for (let i = arr[0].length - 1; i >= 0; i--) {
    const rIndex = Math.floor(Math.random() * (i + 1));
    for (let arrIndex = 0; arrIndex < arr.length; arrIndex++) {
      const item = arr[arrIndex];
      const temp = item[rIndex];
      item[rIndex] = item[i];
      item[i] = temp;
    }
  }
  return arr;
}

function timeAdd0(str) {
  let newStr = str;
  if (str < 10) {
    newStr = `0${str}`;
  }
  return newStr;
}


const mumberMul = (arg1, arg2) => {
  let m = 0;
  const s1 = arg1.toString();
  const s2 = arg2.toString();
  try {
    m += s1.split('.')?.[1]?.length || 0;
  } catch (e) {
    console.log(e);
  }
  try {
    m += s2.split('.')?.[1]?.length || 0;
  } catch (e) { console.log(e); }

  return Number(s1.replace('.', '')) * Number(s2.replace('.', '')) / Math.pow(10, m);
};


const mumberAdd = (arg1, arg2) => {
  let r1;
  let r2;
  // let m;
  // let n;
  try {
    r1 = arg1.toString().split('.')[1].length;
  } catch (e) {
    r1 = 0;
  }
  try {
    r2 = arg2.toString().split('.')[1].length;
  } catch (e) {
    r2 = 0;
  }
  const m = Math.pow(10, Math.max(r1, r2));
  const n = (r1 >= r2) ? r1 : r2;
  return ((arg1 * m + arg2 * m) / m).toFixed(n);
};

const mumberSub = (arg1, arg2) => {
  let re1;
  let re2;
  // let m;
  // let n;
  try {
    re1 = arg1.toString().split('.')[1].length;
  } catch (e) {
    re1 = 0;
  }
  try {
    re2 = arg2.toString().split('.')[1].length;
  } catch (e) {
    re2 = 0;
  }
  const m = Math.pow(10, Math.max(re1, re2));
  const n = (re1 >= re2) ? re1 : re2;
  return ((arg1 * m - arg2 * m) / m).toFixed(n);
};

const mumberDiv = (arg1, arg2) => {
  let t1 = 0;
  let t2 = 0;
  // let r1;
  // let r2;
  try {
    t1 = arg1.toString().split('.')[1].length;
  } catch (e) {
    console.log(e);
  }
  try {
    t2 = arg2.toString().split('.')[1].length;
  } catch (e) {
    console.log(e);
  }
  const r1 = Number(arg1.toString().replace('.', ''));
  const r2 = Number(arg2.toString().replace('.', ''));
  // 获取小数点后的计算值
  const result = ((r1 / r2) * Math.pow(10, t2 - t1)).toString();
  let result2 = result.split('.')[1];
  const digit = 2;
  result2 = result2.substring(0, digit > result2.length ? result2.length : digit);
  return Number(`${result.split('.')[0]}.${result2}`);
};

function addPoint(vm, params = {}, flag) {
  // flag  是否需要传页面停留时间
  if (flag) {
    let stayTime = null;
    const enterTime = sessionStorage.getItem('xjsc_pageentertime_20200303');
    const leaveTime = new Date().valueOf();
    if (enterTime) {
      stayTime = leaveTime - enterTime;
    }
    params.pagePeriod = stayTime;
    const url = sessionStorage.getItem('xjsc_point_url_20200319');
    if (url) {
      params.url = url;
      sessionStorage.removeItem('xjsc_point_url_20200319');
    }
  }
  sessionStorage.setItem('xjsc_point_url_20200319', window.location.href);
  const env = isWxAli();
  let linkSource = 'web';
  if (miniProgram.isMP()) {
    linkSource = 'mini';
  } else if (env.isWx) {
    linkSource = 'weixin';
  } else if (env.isAliPay) {
    linkSource = 'alipay';
  }
  let merchantInfoId = localStorage.getItem('xjsc_vue_2018_12_19_mid');

  // 店铺id异常处理
  if (!merchantInfoId || merchantInfoId === 'undefined') {
    merchantInfoId = '';
  } else if (merchantInfoId instanceof Array) {
    merchantInfoId = merchantInfoId[0];
  }

  const newParams = Object.assign({
    pagePeriod: '',
    linkSource,
    url: window.location.href,
    source: 'client',
    userId: vm.getUserId(),
    shopId: merchantInfoId,
  }, params);

  if (store.state.baseinfo.fromSource) {
    Object.assign(newParams, {
      fromSource: store.state.baseinfo.fromSource,
    });
  }
  vm.jsonAxios('/marketing/api/log/save', newParams, '', true);
}

function getDate(num) {
  const myDate = new Date();
  const lastDate = new Date(myDate.getFullYear(), myDate.getMonth() + num);
  return `${lastDate.getFullYear()}-${timeAdd0(lastDate.getMonth() + 1)}`;
}
// 节流
function throttle(fn, wait) {
  let time = null;
  return function () {
    if (time) return;
    time = setTimeout(function () {
      fn.apply(this, arguments);
      time = null;
    }, wait);
  };
}

/**
 * 随机N位字母数字的字符串
 * @param {number} n 字符串长度
 * @returns {string} n位字符串
 */
function random(n) {
  let res = '';
  while (res.length < n) {
    res += Math.random().toString(36).substr(2).toUpperCase();
  }
  return res.substr(0, n);
}

function compareDate(startDate, endDate) {
  const start = new Date(startDate.replace(/-/g, '/'));
  const end = new Date(endDate.replace(/-/g, '/'));
  if (start > end) {
    return false;
  } else {
    return true;
  }
}

// 通过组件nane，查找子组件
function findComponentsDownward(context, componentName) {
  return context.$children.reduce((components, child) => {
    if (child.$options.name === componentName) components.push(child);
    const foundChilds = findComponentsDownward(child, componentName);
    return components.concat(foundChilds);
  }, []);
}

const checkIdCard = (val) => {
  const ID = val;
  if (!ID) return false;
  if (typeof ID !== 'string' && typeof ID !== 'number') {
    return false;
  }
  const city = { 11: '北京', 12: '天津', 13: '河北', 14: '山西', 15: '内蒙古', 21: '辽宁', 22: '吉林', 23: '黑龙江 ', 31: '上海', 32: '江苏', 33: '浙江', 34: '安徽', 35: '福建', 36: '江西', 37: '山东', 41: '河南', 42: '湖北 ', 43: '湖南', 44: '广东', 45: '广西', 46: '海南', 50: '重庆', 51: '四川', 52: '贵州', 53: '云南', 54: '西藏 ', 61: '陕西', 62: '甘肃', 63: '青海', 64: '宁夏', 65: '新疆', 71: '台湾', 81: '香港', 82: '澳门', 83: '台湾居民', 91: '国外' };
  const birthday =
    `${ID.substr(6, 4)
    }/${
    Number(ID.substr(10, 2))
    }/${
    Number(ID.substr(12, 2))}`;
  const d = new Date(birthday);
  const newBirthday =
    `${d.getFullYear()
    }/${
    Number(d.getMonth() + 1)
    }/${
    Number(d.getDate())}`;
  const currentTime = new Date().getTime();
  const time = d.getTime();
  const arrInt = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
  const arrCh = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2'];
  let sum = 0;
  let i;
  let residue = '';
  if (!/^\d{17}(\d|x)$/i.test(ID)) { return false; }
  if (city[ID.substr(0, 2)] === undefined) { return false; }
  if (time >= currentTime || birthday !== newBirthday) { return false; }
  for (i = 0; i < 17; i++) {
    sum += ID.substr(i, 1) * arrInt[i];
  }
  residue = arrCh[sum % 11];
  if (residue !== ID.substr(17, 1).toUpperCase()) { return false; }
  return true;
};

// 解析url地址，获得一个对象
const urlParse = (url) => {
  if (!url) {
    return {};
  }
  const query = {};
  const urls = url.split('?'); // 分割url
  const path = urls[0];
  if (!urls[1]) {
    return { path, query };
  }
  const arr = urls[1].split('&'); // 分割urls

  for (let i = 0, l = arr.length; i < l; i++) {
    const a = arr[i].split('='); // 遍历 arr 并分割后赋值给a
    query[a[0]] = a[1] || ''; // a给query对象赋值
  }

  return {
    query,
    path,
  };
};

// url后拼接参数
const setUrlQuery = (url, params) => {
  if (!url) {
    return '';
  }
  const { query, path } = urlParse(url);
  const obj = {
    ...query,
    ...params,
  };
  const queryArr = [];
  Object.keys(obj).forEach(key => {
    queryArr.push(`${key}=${obj[key]}`);
  });
  return `${path}?${queryArr.join('&')}`;
};
// 脱敏显示统一格式
const idFilter = function (value) {
  if (value) {
    if (/AlipayClient/.test(window.navigator.userAgent)) {
      // 身份证只显示第一位和最后一位，其他用*号
      return `${value.slice(0, 1) }****************${ value.slice(value.length - 1)}`;
    } else {
      return `${value.slice(0, 6) }**********${ value.slice(value.length - 2)}`;
    }
  } else {
    return '';
  }
};
const nameFilter = function (value) {
  let star = '';
  for (let i = 0; i < value.length - 1; i++) {
    star += '*';
  }
  if (/AlipayClient/.test(window.navigator.userAgent)) {
    // 姓名只显示最后一个字，前面用*号
    return `${star}${ value.slice(value.length - 1)}`;
  } else {
    return `${value.slice(0, value.length - 2) }*${ value.slice(value.length - 1)}`;
  }
};
const phoneFilter = function (value) {
  if (/AlipayClient/.test(window.navigator.userAgent)) {
    // 手机号显示前面3位和最后2位，其他用*号
    return `${value.slice(0, 3) }******${ value.slice(value.length - 2)}`;
  } else {
    return `${value.slice(0, 3) }****${ value.slice(value.length - 4)}`;
  }
};
export {
  isWxAli,
  isiOS,
  wxPay,
  order,
  imageIsExist,
  randomSort,
  addPoint,
  mumberMul,
  mumberAdd,
  mumberSub,
  mumberDiv,
  timeAdd0,
  getDate,
  throttle,
  random,
  compareDate,
  checkIdCard,
  findComponentsDownward,
  urlParse,
  setUrlQuery,
  idFilter,
  nameFilter,
  phoneFilter,
};
