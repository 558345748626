import Vue from 'vue';

const vm = new Vue();
/* --------------------------------  商品详情  --------------------------------- */
/**
 * 获取SKU列表
 * @param {String} merchantMsdeInfoId  商品id
 */
export const getSkuList = (merchantMsdeInfoId) => vm.getAxios('/merchant/api/merchantMsdeInfo/getMerchantMsdeSKu', {
  merchantMsdeInfoId,
});

/**
 * 获取SKU列表 -- 审核
 * @param {String} merchantMsdeInfoId  商品id
 */
export const getReviewSkuList = (merchantMsdeInfoId) => vm.getAxios('/merchant/api/merchantMsdeInfo/mdseSkuSnapshot', {
  merchantMsdeInfoId,
});

/* ---------------------------------  购物车  --------------------------------- */
/**
 * 获取购车列表
 */
export const getCartList = () => vm.getAxios('/cart/api/leaguer/manage/getCart', {
  merchantInfoName: 'test',
});

/**
 * 获取最新的购物车信息
 * @param {String} modelCodes 单个或者多个商品的modelcode,多个逗号分隔
 */
export const getMerchantMdseDetailByCode = (modelCodes) => vm.getAxios('/merchant/api/merchantMsdeInfo/getMerchantMdseDetailByCode', {
  modelCodes,
});

/**
 * 从购物车删除
 * @param {String} mdseDetailIds 购物车商品id,多个逗号分隔
 */
export const removeCartItem = (mdseDetailIds) => vm.getAxios('/cart/api/leaguer/manage/removeCartItem', {
  mdseDetailIds,
});
