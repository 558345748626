let unll;
let ll;
let sl;
let unsl;
let onlySession = true;
const LOCALSTORAGE = 0;
const SESSIONSTORAGE = 1;
let KEY = 'data-keep';
const ls = window.localStorage;
const ss = window.sessionStorage;

const dataKeep = (store) => {
  initAlong(store);
  store.subscribe((mutation, state) => {
    saveLocal(state);
    saveSession(state);
  });
};
export default dataKeep;

const saveLocal = (state) => {
  if (onlySession) return;
  setItem(KEY, filter(ll, unll, state), LOCALSTORAGE);
};

const saveSession = (state) => {
  if (!sl && !unsl && !onlySession) return;
  setItem(KEY, filter(sl, unsl, state), SESSIONSTORAGE);
};

const filter = (s1, s2, state) => {
  let obj = {};
  if (s1) {
    for (const i of s1) {
      obj[i] = state[i];
    }
  } else if (s2) {
    for (const i in state) {
      if (s2.indexOf(i) > -1) continue;
      obj[i] = state[i];
    }
  } else {
    obj = state;
  }
  return obj;
};

const initAlong = (store) => {
  const obj = {};
  if (!onlySession && getItem(KEY, LOCALSTORAGE)) { Object.assign(obj, getItem(KEY, LOCALSTORAGE)); }
  if (getItem(KEY, SESSIONSTORAGE)) { Object.assign(obj, getItem(KEY, SESSIONSTORAGE)); }
  store.replaceState(Object.assign(store.state, obj));
};

const setWatch = (arry, isWatch = true, type) => {
  if (!Array.isArray(arry) || typeof isWatch !== 'boolean') {
    return console.error('params error');
  }
  if (type === LOCALSTORAGE) {
    if (isWatch) {
      ll = arry;
      unll = null;
    } else {
      unll = arry;
      ll = null;
    }
  } else if (isWatch) {
    sl = arry;
    unsl = null;
  } else {
    unsl = arry;
    sl = null;
  }
};

const watch = (arry, isWatch) => {
  setWatch(arry, isWatch, LOCALSTORAGE);
};

const watchSession = (arry, isWatch) => {
  setWatch(arry, isWatch, SESSIONSTORAGE);
};

const setOnlySession = (boolean) => {
  if (typeof boolean !== 'boolean') {
    return console.error('onlySession error');
  }
  onlySession = boolean;
};
const coded = (str) => {
  return window.btoa(window.encodeURIComponent(str));
};
const encoded = (str) => {
  return window.decodeURIComponent(window.atob(str));
};

const getItem = (key, type) => {
  const storage = type === LOCALSTORAGE ? ls : ss;
  try {
    return JSON.parse(encoded(storage.getItem(key)));
  } catch (err) {
    return null;
  }
};

const setItem = (key, val, type) => {
  const storage = type === LOCALSTORAGE ? ls : ss;
  storage.setItem(key, coded(JSON.stringify(val)));
};

const removeItem = (key = KEY) => {
  ss.removeItem(key);
  ls.removeItem(key);
};

const setKey = (key) => {
  if (typeof key === 'string') {
    KEY = key;
  } else {
    console.error('key is not string');
  }
};

dataKeep.setKey = setKey;
dataKeep.watch = watch;
dataKeep.watchSession = watchSession;
dataKeep.onlySession = setOnlySession;
dataKeep.clean = removeItem;
window.cleanDataKeep = removeItem;
