// 全局过滤器


// px转vw
const px2vw = (val) => {
  val = (parseFloat(val) / 37.5).toFixed(6) * 10;
  return `${val }vw`;
};

// 业态类别
const getBusinessType = (key) => {
  const obj = {
    ticket: '门票',
    room: '酒店',
    mdse: '商品',
    repast: '餐饮',
    route: '跟团游',
    pmsHotelTypeProduct: 'pms酒店',
  };
  return obj[key];
};
const ifWindow = (value) => {
  let text = '';
  switch (value) {
    case '0':
      text = '有窗';
      break;
    case '1':
      text = '无窗';
      break;
    case '2':
      text = '落地窗';
      break;
    case '3':
      text = '部分有窗';
      break;
    default:
      break;
  }
  return text;
};
const ifBreakfast = (value) => {
  let text = '';
  switch (value) {
    case '0':
      text = '无早';
      break;
    case '1':
      text = '单早';
      break;
    case '2':
      text = '双早';
      break;
    case '3':
      text = '三早';
      break;
    case '4':
      text = '四早';
      break;
    case '5':
      text = '五早';
      break;
    case '6':
      text = '双早+双温泉';
      break;
    default:
      break;
  }
  return text;
};
// 姓名关键字加*
const replaceStr = (str) => {
  let phnumAfter = str;
  if (str) {
    const { length } = phnumAfter;
    let start = '';
    for (let i = 0; i < length - 4; i++) {
      start += '*';
    }
    phnumAfter = str.substr(0, 2) + start + str.substr(length - 2, 2);
  }
  return phnumAfter;
};

const dateFtt = (d, fmt) => {
  if (typeof d === 'string') {
    d = d.replace(/-/g, '/');
  }
  const date = new Date(d);
  const o = {
    'M+': date.getMonth() + 1, // 月份
    'd+': date.getDate(), // 日
    'h+': date.getHours(), // 小时
    'm+': date.getMinutes(), // 分
    's+': date.getSeconds(), // 秒
    'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
    S: date.getMilliseconds(), // 毫秒
  };
  if (/(y+)/.test(fmt)) { fmt = fmt.replace(RegExp.$1, (`${date.getFullYear() }`).substr(4 - RegExp.$1.length)); }
  for (const k in o) { if (new RegExp(`(${ k })`).test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : ((`00${ o[k]}`).substr((`${ o[k]}`).length))); }
  return fmt;
};
export {
  px2vw,
  getBusinessType,
  replaceStr,
  dateFtt,
  ifWindow,
  ifBreakfast,
};
