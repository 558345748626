import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import getters from './getters';
import dataKeep from './plugin/datakeep';

dataKeep.watchSession(['baseinfo', 'order']);

Vue.use(Vuex);

const context = require.context('./modules', true, /\.js$/);
const modules = context.keys().reduce((total, path) => {
  const name = path.replace(/\.\/|\.js/g, '');
  total[name] = context(path).default;
  return total;
}, {});

const store = new Vuex.Store({
  plugins: [createPersistedState({
    key: 'lotsWap',
    reducer(val) {
      return {
        traffic: val.traffic,
      };
    },
  }), dataKeep],
  state: {
    mid: null,
    isLoading: false,
    count: 0,
    token: '11',
    pageNoScroll: false, // 页面不能滚动
    signatureWrong: false, // 获取微信签名失败
    agreementShow: false,
  },
  getters: {
    count(state) {
      state.count += 100;
      return state.count;
    },
    ...getters,
  },
  mutations: {
    setPageNoScrollMutations(state, flag) {
      state.pageNoScroll = flag;
    },
    setTokenMutations(state, token) {
      state.token = token;
    },
    add(state, n) {
      state.count += n;
    },
    loaddingChange(state, flag) {
      state.isLoading = flag;
    },
    getSignature(state, flag) {
      state.signatureWrong = flag;
    },
    updateMid(state, mid) {
      state.mid = mid;
    },
  },
  actions: {
    setPageNoScroll({ commit }, flag) {
      commit('setPageNoScrollMutations', flag);
    },
    loaddingChangeAction({ commit }, flag) {
      commit('loaddingChange', flag);
    },
    addAction({ commit }) {
      commit('add', 10);
    },
    setTokenAction({ commit }, token) {
      commit('setTokenMutations', token);
    },
  },
  modules,
});

export default store;
