<template>
  <div class="nav-module">
    <RightSideNav
      v-if="sideNavOptions"
      :options="sideNavOptions"
    ></RightSideNav>
    <BottomNav
      v-if="bottomNavOptions"
      :page-data="bottomNavOptions"
    ></BottomNav>
    <ImgBottomNav
      v-if="imgBottomNavOptions"
      :options="imgBottomNavOptions"
    ></ImgBottomNav>
    <CircleNav
      v-if="circleNavOptions"
      :options="circleNavOptions"
    ></CircleNav>
  </div>
</template>

<script>
// import { getPageNav, getCustomPageNav } from '@/api/merchant';
import BottomNav from '@/components/custompage/footer.vue';
import ImgBottomNav from './bottomNav';
import RightSideNav from './rightSideNav';
import CircleNav from './circleNav';
import { mapState, mapGetters } from 'vuex';

export default {
  components: {
    BottomNav,
    RightSideNav,
    CircleNav,
    ImgBottomNav,
  },
  data() {
    return {
      // navDataList: [],
    };
  },
  computed: {
    ...mapState('baseinfo', ['destinationPageId']),
    ...mapGetters('navModule', ['navDataList', 'bottomNavOptions', 'imgBottomNavOptions', 'sideNavOptions', 'circleNavOptions']),
    routeName() {
      return this.$route.name;
    },
    customPageId() {
      if (this.routeName === 'Custompage') {
        return this.$route.query.id;
      }
      return null;
    },
  },
  watch: {
    routeName(val) {
      if (!val) return;
      if (val === 'Custompage') {
        this.$store.dispatch('navModule/getCustomPageNavDataList', this.customPageId);
      } else {
        this.$store.dispatch('navModule/getNavDataList', val);
      }
    },
    customPageId(val) {
      if (!val) return;
      this.$store.dispatch('navModule/getCustomPageNavDataList', Number(val));
    },
    destinationPageId(val) {
      if (!val) return;
      this.$store.dispatch('navModule/getCustomPageNavDataList', Number(val));
    },
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
.demo {
  position: fixed;
  left: 50px;
  bottom: 100px;
  width: 300px;
  height: 400px;
  border: 1px solid;
  .demo1 {
    position: absolute;
    top: 10%;
    left: 10%;
    width: 50%;
    height: 50%;
    border: 1px solid red;
  }
}
</style>
