/**
 * saas 年卡状态
 */

import { saasCheck, getXJUserInfo } from '@/pages/personal/saas/api';
import router from '@/router';

export default {
  namespaced: true,
  state: {
    phone: '', // 当前登陆手机号   拿取登陆信息
    token: '', // 判断是否登陆
  },
  getters: {
    GET_PHONE: state => {
      if (!state.phone) state.token = ''; // 重制 token
      return state.phone;
    },
    GET_TOKEN: state => {
      return state.token;
    },
  },
  mutations: {
    SET_PHONE: (state, phone) => {
      state.phone = phone;
    },
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
  },
  actions: {
    // 校验登陆方式
    async checkLogin({ getters, commit, dispatch }) {
      // 拿取用户信息
      try {
        const infoRes = (await getXJUserInfo()).data;
        commit('SET_PHONE', infoRes.mobile);
      } catch (error) {
        router.push('/');
      }
      // 直接登陆    注册登陆
      const phone = getters.GET_PHONE;
      if (phone) {
        await dispatch('getLoginRes', {
          phone,
        }); //  已有手机号情况不需要传入信息
      } else {
        // 环境差异问题 兼容处理
        const mid = localStorage.getItem('xjsc_vue_2018_12_19_mid');
        router.push(`/login/saas?m_id=${mid}`);
      }
    },
    // 拿取登陆信息
    async getLoginRes({ commit }, loginInfo = {}) {
      const res = await saasCheck({
        phone: loginInfo.phone,
        code: loginInfo.code,
      });
      commit('SET_TOKEN', res.data.authToken);
    },
  },
};
