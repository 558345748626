<template>
  <ImgHotModule
    class="bottom-nav"
    :img="options.img"
    :hot-list="options.hotList"
    :img-style="imgStyle"
    @hot-click="onHotClick"
    @wrapper-click="onWrapperClick"
  ></ImgHotModule>
</template>

<script>
import ImgHotModule from '@/components/imgHotModule';

export default {
  components: {
    ImgHotModule,
  },
  props: {
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    };
  },
  computed: {
    imgStyle() {
      return {
        width: '100%',
        height: `${this.options.imgHeight}px`,
      };
    },
  },
  methods: {
    onHotClick(index) {
      const data = this.options.hotList[index];
      this.gotoTarget(data);
    },
    onWrapperClick() {
      this.gotoTarget(this.options);
    },
  },
};
</script>

<style scoped lang="scss">
.bottom-nav {
  position: fixed;
  bottom: 0;
  z-index: 2000;
}
</style>
