// 抽奖相关路由
const routes = [
  // 抽奖页面
  {
    path: '/luckDraw/index',
    name: 'luckDrawIndex',
    // meta: {
    //   title: '幸运大抽奖',
    // },
    component: () => import(/* webpackChunkName: "route-luck-draw" */ '@/pages/luckDraw/index'),
  },
  {
    path: '/luckDraw/record',
    name: 'luckDrawRecord',
    meta: {
      title: '我的中奖记录',
    },
    component: () => import(/* webpackChunkName: "route-luck-draw" */ '@/pages/luckDraw/record'),
  },
];

export default routes;
