import { setStorage, getStorage } from '@/utils/storage';

const locationTypeList = ['province', 'city', 'area'];
const state = () => ({
  // 目的地相关数据
  destinationLink: '',
  destinationPageId: null,
  destinationLocation: '',
  province: '',
  city: '',
  area: '',
  provinceCode: '',
  cityCode: '',
  areaCode: '',
  // 是否已经定位过
  isInitLocation: false,
  // 是否需要切换目的地 0-未初始化，1-需要，2-不需要，3-未询问，4-本次不再询问
  needChangeLocation: 0,
  merchantId: '',
  isOtherMP: false,
  // 访问来源 guide-中台导游导览
  fromSource: '',
  addressLink: '', // 目的地组件链接地址
  addressId: '', // 当前目的地组件-》目的地库id
});

const getters = {
  addressIdFilter: (baseState) => {
    return baseState.addressId;
  },
  destinationFilter: (baseState) => {
    const result = { type: '', code: '' };
    const merchantId = localStorage.getItem('xjsc_vue_2018_12_19_mid');
    const location = baseState.destinationLocation || getStorage(`${merchantId}_destinationLocation`) || '';
    const list = location.split(',');
    for (let index = list.length - 1; index >= 0; index--) {
      const value = list[index];
      if (value) {
        result.type = locationTypeList[index];
        result.code = value;
        break;
      }
    }
    return result;
  },
  destinationListFilter: (baseState) => {
    const result = { type: '', code: '' };
    const list = baseState.destinationLocation.split(',');
    for (let index = list.length - 1; index >= 0; index--) {
      const value = list[index];
      if (value && index !== 2) {
        result.type = locationTypeList[index];
        result.code = value;
        break;
      }
    }
    return result;
  },
};

const actions = {
  addDestination({ commit }, { link, id, location }) {
    commit('updateDestinationLink', link);
    commit('updateDestinationPageId', id);
    commit('updateDestinationLocation', location);
    // 点击标签切换之后不再询问切换地点，以后有其他需求就加个参数判断
    commit('updateNeedChangeLocation', 4);
  },
  changeLocation({ commit }, { province = '', city = '', area = '', provinceCode = '', cityCode = '', areaCode = '' }) {
    commit('updateProvince', province);
    commit('updateCity', city);
    commit('updateArea', area);
    commit('updateProvinceCode', provinceCode);
    commit('updateCityCode', cityCode);
    commit('updateAreaCode', areaCode);
    commit('updateIsInitLocation', true);
  },
  addCustomPageId({ commit }, addressLink = {}) {
    sessionStorage.setItem('destination-data', JSON.stringify(addressLink));
    commit('updateCustomPageId', addressLink);
  },
  addAddressId({ commit }, addressId) {
    commit('updateAddressId', addressId);
  },
};

const mutations = {
  updateAddressId(baseState, addressId) {
    baseState.addressId = addressId;
  },
  updateCustomPageId(baseState, addressLink) {
    baseState.addressLink = addressLink;
  },
  updateDestinationLink(baseState, link) {
    baseState.destinationLink = link;
  },
  updateDestinationPageId(baseState, id) {
    baseState.destinationPageId = id;
  },
  updateDestinationLocation(baseState, location = '') {
    baseState.destinationLocation = location;
  },
  updateProvince(baseState, province) {
    baseState.province = province;
  },
  updateCity(baseState, city) {
    baseState.city = city;
  },
  updateArea(baseState, area) {
    baseState.area = area;
  },
  updateProvinceCode(baseState, provinceCode) {
    baseState.provinceCode = provinceCode;
  },
  updateCityCode(baseState, cityCode) {
    baseState.cityCode = cityCode;
  },
  updateAreaCode(baseState, areaCode) {
    baseState.areaCode = areaCode;
  },
  updateIsInitLocation(baseState, isInitLocation) {
    baseState.isInitLocation = isInitLocation;
  },
  updateNeedChangeLocation(baseState, needChangeLocation) {
    if (needChangeLocation !== 4) {
      setStorage('changeLocationStatus', needChangeLocation);
    }
    baseState.needChangeLocation = needChangeLocation;
  },
  updateIsOtherMP(baseState, isOtherMP) {
    baseState.isOtherMP = isOtherMP;
  },
  updateFromSource(baseState, fromSource) {
    baseState.fromSource = fromSource;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
