const route = [
  {
    path: '/MFSLogin', // 丰收互联授权跳转页面
    name: 'MFSLogin',
    meta: {
      title: '丰收互联授权',
    },
    component: () => import(/* webpackChunkName: "MFSLogin" */ '@/pages/login/MFSLogin'),
  },
  {
    path: '/croodsAuth', // 皖事通授权跳转页面
    name: 'croodsAuth',
    meta: {
      title: '皖事通授权',
    },
    component: () => import(/* webpackChunkName: "croodsAuth" */ '@/pages/login/croodsAuth'),
  },
  {
    path: '/zhileshanAuth', // 智乐山授权跳转页面
    name: 'zhileshanAuth',
    meta: {
      title: '智乐山授权',
    },
    component: () => import(/* webpackChunkName: "zhileshanAuth" */ '@/pages/login/zhileshanAuth'),
  },
  {
    path: '/changZhouRSAuth', // 常州社保卡授权跳转页面
    name: 'changZhouRSAuth',
    meta: {
      title: '常州社保卡授权',
    },
    component: () => import(/* webpackChunkName: "changZhouRSAuth" */ '@/pages/login/changZhouRSAuth'),
  },
  {
    path: '/youShanXiAuth', // 游山西app授权跳转页面
    name: 'youShanXiAuth',
    meta: {
      title: '游山西授权',
    },
    component: () => import(/* webpackChunkName: "youShanXiAuth" */ '@/pages/login/youShanXiAuth'),
  },
  {
    path: '/yunPayMiniAuth', // 银联云闪付小程序授权
    name: 'yunPayMiniAuth',
    meta: {
      title: '银联云闪付小程序授权',
    },
    component: () => import(/* webpackChunkName: "yunPayMiniAuth" */ '@/pages/login/yunPayMiniAuth/index'),
  },
  {
    path: '/szdAppAuth', // 苏周到app授权跳转页面
    name: 'szdAppAuth',
    meta: {
      title: '苏周到授权',
    },
    component: () => import(/* webpackChunkName: "szdAppAuth" */ '@/pages/login/szdAppAuth/index'),
  },
  {
    path: '/huaweiAuth', // 游山西app授权跳转页面
    name: 'huaweiAuth',
    meta: {
      title: '华为鸿蒙授权',
    },
    component: () => import(/* webpackChunkName: "huaweiAuth" */ '@/pages/login/huaweiAuth'),
  },
  {
    path: '/iyixingAuth', // i宜兴app授权跳转页面
    name: 'iyixingAuth',
    meta: {
      title: 'i宜兴授权',
    },
    component: () => import(/* webpackChunkName: "iyixingAuth" */ '@/pages/login/iyixingAuth'),
  },
  {
    path: '/luAnRenSheAuth', // i宜兴app授权跳转页面
    name: 'luAnRenSheAuth',
    meta: {
      title: '六安人社授权',
    },
    component: () => import(/* webpackChunkName: "luAnRenSheAuth" */ '@/pages/login/luAnRenSheAuth'),
  },
];

export default route;
