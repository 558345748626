// 埋点信息相关
const state = () => ({
  traceId: '',
  sessionId: '',
  browserId: '',
});

const mutations = {
  updateTrDeId(baseState, traceId) {
    baseState.traceId = traceId;
  },
  updateTrSeId(baseState, sessionId) {
    baseState.sessionId = sessionId;
  },
  updateTrWbId(baseState, browserId) {
    baseState.browserId = browserId;
  },
};
const actions = {
  changePointData({ commit }, { traceId = '', sessionId = '', browserId = '', traceid = '', sessionid = '', browserid = '' } = {}) {
    commit('updateTrDeId', traceId || traceid);
    commit('updateTrSeId', sessionId || sessionid);
    commit('updateTrWbId', browserId || browserid);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
