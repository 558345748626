// 礼品卡相关页面路由
const routes = [
  {
    path: '/giftCard/list',
    name: 'giftCardList',
    meta: {
      title: '福利卡列表',
      customTheme: false,
    },
    component: () => import(/* webpackChunkName: "route-gift-card" */ '@/pages/giftCard/index'),
  },
  {
    path: '/giftCard/detail',
    name: 'giftCardDetail',
    meta: {
      title: '福利卡详情',
      customTheme: false,
    },
    component: () => import(/* webpackChunkName: "route-gift-card" */ '@/pages/giftCard/detail'),
  },
  {
    path: '/giftCard/notice',
    name: 'giftCardNotice',
    meta: {
      title: '使用须知',
      customTheme: false,
    },
    component: () => import(/* webpackChunkName: "route-gift-card" */ '@/pages/giftCard/notice'),
  },
  {
    path: '/giftCard/record',
    name: 'giftCardRecord',
    meta: {
      title: '使用记录',
      customTheme: false,
    },
    component: () => import(/* webpackChunkName: "route-gift-card" */ '@/pages/giftCard/record'),
  },
  {
    path: '/giftCard/payResult',
    name: 'giftCardPayResultt',
    meta: {
      title: '支付结果',
      customTheme: false,
    },
    component: () => import(/* webpackChunkName: "route-gift-card" */ '@/pages/giftCard/payResult'),
  },
];

export default routes;
