const cloudCater = {
  namespaced: true,
  state: {
    merchantInfo: {},
    repastDetailInfo: {},
    orderDetailInfo: {},
  },
  getters: {
    merchantInfo(state) {
      if (!state.merchantInfo || (state.merchantInfo && !Object.keys(state.merchantInfo).length)) {
        state.merchantInfo = JSON.parse(localStorage.getItem('cloudCater_merchant_info'));
      }
      return state.merchantInfo;
    },
    repastDetailInfo(state) {
      if (!state.repastDetailInfo || (state.repastDetailInfo &&
        !Object.keys(state.repastDetailInfo).length)) {
        state.repastDetailInfo = JSON.parse(localStorage.getItem('cloudCater_repast_info'));
      }
      return state.repastDetailInfo;
    },
    orderDetailInfo(state) {
      if (!state.orderDetailInfo || (state.orderDetailInfo &&
        !Object.keys(state.orderDetailInfo).length)) {
        state.orderDetailInfo = JSON.parse(localStorage.getItem('cloudCater_order_info'));
      }
      return state.orderDetailInfo;
    },
  },
  mutations: {
    merchantInfo: (state, info) => {
      state.merchantInfo = info;
    },
    orderDetailInfo: (state, info) => {
      state.orderDetailInfo = info;
    },
    removeMerchantInfo: (state) => {
      state.merchantInfo = null;
      localStorage.removeItem('cloudCater_merchant_info');
    },
    repastDetailInfo: (state, info) => {
      state.repastDetailInfo = info;
    },
    removeRepastDetailInfo: (state) => {
      state.repastDetailInfo = null;
      localStorage.removeItem('cloudCater_repast_info');
    },
  },
  actions: {
    setMerchantInfo({ commit }, info) {
      localStorage.setItem('cloudCater_merchant_info', JSON.stringify(info));
      commit('merchantInfo', info);
    },
    setRepastDetailInfo({ commit }, info) {
      localStorage.setItem('cloudCater_repast_info', JSON.stringify(info));
      commit('repastDetailInfo', info);
    },
    setOrderDetailInfo({ commit }, info) {
      localStorage.setItem('cloudCater_order_info', JSON.stringify(info));
      commit('orderDetailInfo', info);
    },
  },
};

export default cloudCater;
