<template>
  <div class="amap-page-container">
    <div
      id="js-map"
      class="amap-wrap"
    ></div>
  </div>
</template>
<script>
import AMapLoader from '@amap/amap-jsapi-loader';

export default {
  name: 'AmapPage',
  mounted() {
    AMapLoader.load({
      key: 'b547d05d07535668173a50b6d644ffce', // 申请好的Web端开发者Key，首次调用 load 时必填
      version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
      plugins: ['AMap.Autocomplete', 'AMap.BezierCurveEditor', 'AMap.ToolBar', 'AMap.PlaceSearch'], // 插件列表
    }).then((AMap) => {
      document.title = this.$route.query.sitename;
      const center = [+this.$route.query.lng, +this.$route.query.lat];

      this._marker = new AMap.Marker({
        position: center,
        label: {
          content: `站点：${ this.$route.query.sitename}`,
          offset: new AMap.Pixel(20, -20),
        },
      });

      this._marker.on('click', this.onMarkClick);

      this._mapInstance = new AMap.Map('js-map', {
        count: 1,
        zoom: 14,
        zoomEnable: true,
        dragEnable: true,
        scrollWheel: true,
        touchZoom: true,
        center,
      });

      this._mapInstance.add(this._marker);
    });
  },
  beforeDestroy() {
    this._marker.off('click', this.onMarkClick);
    this._mapInstance.destroy();
    this._marker = null;
    this._mapInstance = null;
  },
  methods: {
    onMarkClick() {
      // 唤起APP
      this._marker.markOnAMAP({
        name: this.$route.query.sitename,
        position: this._marker.getPosition(),
      });
    },
  },
};
</script>
<style>
.amap-page-container {
  height: 100vh;
}
.amap-wrap {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.amap-marker-label {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
</style>
