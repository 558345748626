import Vue from 'vue';

const vm = new Vue();

const BASE_URL = '/newapiyearcard/front';
const BASE_URL_CORP = '/newapiyearcard/corp';
export const BASE_URL_ADMIN = 'newapiyearcard/admin';

/* ---------------------------------  图片  --------------------------------- */
/**
 * 获取图片前缀
 */
export const getXJBaseImg = () => vm.getAxios(`${BASE_URL_ADMIN}/api/public/setting/file`);

/* ---------------------------------  登陆  --------------------------------- */

/**
 * 获取小鲸用户信息
 */
export const getXJUserInfo = () => vm.getAxios('/leaguer/api/userLeaguer/manage/leaguerInfo');

/**
 * 年卡会员注册,如果手机号为空则强制跳转注册手机号码
 * @param {Number} phone 手机号
 * @param {Number} code 验证码
 */
export const saasCheck = ({ phone = '', code = '' } = {}) => vm.postAxios('/leaguer/api/userLeaguer/newyearcard/loginOrRegister', {
  mobile: phone,
  checkCode: code,
});

/**
 * 发送注册saas会员验证码
 * @param {Number} phone 手机号
 */
export const saasLoginSendCode = phone => vm.postAxios('/leaguer/api/userLeaguer/sendCheckCode', {
  mobile: phone,
  sendType: 'bindingTel', // 绑定手机号
});

/* ---------------------------------  验证码  --------------------------------- */

/**
 * 发送绑定相关验证码
 */
export const sendBindCode = ({ phone, key }) => vm.postAxios(`${BASE_URL}/api/common/sendSMSValidCode`, {
  key,
  receiver: phone,
});

/**
 * 发送绑定身份证验证码
 */
export const sendCertNoCode = certNo => vm.postAxios(`${BASE_URL}/wx/api/leaguerInfo/sendCertNoValidCode`, {
  certNo,
});

/**
 * 发送绑定身份证验证码（他人）
 */
export const sendCertNoCodeOther = certNo => vm.postAxios(`${BASE_URL}/wx/api/leaguerInfo/sendBindingCardBagValidCode`, {
  certNo,
});

/* ---------------------------------  购买年卡  --------------------------------- */

/**
 * 获取年卡购买列表
 */
export const getCardBuyList = () => vm.getAxios(`${BASE_URL}/wx/api/cardProInfo/getAllCardProInfo`);

/**
 * 获取条款内容
 */
export const getProtocol = codes => vm.getAxios(`${BASE_URL_CORP}/api/static/info/content`, {
  codes,
});

/**
 * 获取年卡详情
 * @param {Number} id 年卡 id
 */
export const getCardDetail = id => vm.getAxios(`${BASE_URL}/wx/api/cardProInfo/${id}`);

/**
 * 获取年卡兑换码详情
 * @param {Number} id 年卡 id
 */
export const getCardKeyDetail = id => vm.getAxios(`${BASE_URL}/wx/api/CDKeyInfo/${id}`);

/**
 * 获取购买模式  认证优先还是支付优先
 * OrderFirst || ApproveFirst
 */
export const getBuyModel = () => vm.getAxios(`${BASE_URL}/api/common/flowModel`);

/**
 * 获取订单号
 */
export const getOrderId = data => vm.postAxios('/order/api/saveOrder', data);

/**
 * 获取订单确认的详情
 * @param {Number} id 获取订单 id
 */
export const getComfirmOrderDetail = id => vm.getAxios('/order/api/getPayOrder', {
  payOrderNo: id,
});

/**
 * 获取订单详情  (小鲸订单)
 * @param {Number} id 获取订单 id
 */
export const getOrderDetail = orderInfoId => vm.getAxios('/leaguer/api/order/manage/orderQuery', {
  orderInfoId,
});

/**
 * 获取订单详情  (年卡订单)
 * @param {Number} id 获取订单 id
 */
export const getOrderDetailBySaas = id => vm.getAxios(`${BASE_URL}/wx/orderInfo/view/${id}`);

/**
 * 获取订单支付方式
 * @param {String} businessType 当前平台类型
 */
export const getOrderPayType = businessType => vm.getAxios('/merchant/api/merchantPayType/getMerchantPayType', {
  businessType,
});

/**
 * 获取公众号上的用户openid
 */
export const getOrderPayWxOpenId = () => vm.getAxios('/leaguer/api/userLeaguer/manage/leaguerInfo');

/**
 * 支付
 */
export const orderPay = data => vm.getAxios('/order/orderPay/toPay', data);

/**
 * 退款详情
 * @param {Number} orderNo 订单 id
 */
export const refundOrderDetail = orderNo => vm.getAxios(`${BASE_URL}/wx/api/orderRefund/queryDetail`, {
  orderNo,
});

/**
 * 退款
 * @param {Number} orderId 订单 id
 * @param {String} reason 退款原因
 * @param {String} reasonType 退款类型
 */
export const refundOrder = ({
  orderId,
  reasonType,
  reason,
}) => vm.postAxios('/leaguer/api/order/manage/yearcard/orderRefund', {
  orderId,
  reason,
  reasonType,
});

/**
 * 撤销退款
 * @param { Number } orderId
 */
export const refundOrderCancel = orderId => vm.getAxios('/leaguer/api/order/manage/yearcard/cancelRetreat', {
  orderRefundNo: orderId,
});

/* ---------------------------------  年卡中心  --------------------------------- */

/**
 * 获取用户信息
 */
export const getUserInfo = () => vm.getAxios(`${BASE_URL}/wx/api/leaguerInfo/onlineLeaguerInfo`);

/**
 * 获取当前本人电子卡信息
 * @param {Number} cardNo 电子卡 id
 */
export const getCurrentCardInfo = (cardNo = '') => vm.getAxios(`${BASE_URL}/wx/api/leaguerInfo/generatorQR`, {
  cardNo,
});

/**
 * 获取他人电子卡信息
 * @param {Number} cardNo 电子卡 id
 */
export const getCurrentOtherCardInfo = (cardNo = '') => vm.getAxios(`${BASE_URL}/wx/api/leaguerInfo/generatorOtherCardBagQR`, {
  cardNo,
});

/**
 * 提交建议信息
 * @param {Object} data 提交信息
 */
export const pushUserAdvice = data => vm.jsonAxios(`${BASE_URL}/wx/api/cms/saveWebComplain`, data);

/**
 * 获取常见问题
 */
export const getQuestionList = () => vm.getAxios(`${BASE_URL}/wx/api/cms/webFaqList`, {
  current: 1,
  size: 20,
});

/**
 * 获取我的卡包列表
 */
export const getMyCardList = () => vm.getAxios(`${BASE_URL}/wx/api/leaguerInfo/getCardBagList`);

/**
 * 获取我的兑换码  分页
 */
export const getConvertKeyList = data => vm.getAxios(`${BASE_URL}/wx/exchange/page`, data);

/**
 * 获取他人卡包列表
 */
export const getOtherCardList = () => vm.getAxios(`${BASE_URL}/wx/api/leaguerInfo/getBindingCardBagList`);

/**
 * 获取卡包二维码
 * @param {Number} cardNo 电子卡 id
 * @param {Number} personBh
 */
export const getCardQrCode = ({ cardNo, personBh }) => vm.getAxios(`${BASE_URL}/wx/api/leaguerInfo/refreshQR`, {
  cardNo,
  personBh,
}, null, true);

/**
 * 获取卡包续费记录
 * @param {Number} cardNo 电子卡 id
 */
export const getCardRenewRecord = cardNo => vm.getAxios(`${BASE_URL}/wx/api/cardBag/cardValidityRecord`, {
  cardNo,
});

/**
 * 获取卡包续费时间
 * @param {Number} cardNo 电子卡 id
 */
export const getCardRenewTime = cardNo => vm.getAxios(`${BASE_URL}/wx/api/cardBag/nextRenewValidityDate`, {
  cardNo,
});

/**
 * 提交卡包续费订单
 * @param {Number} cardNo 电子卡 id
 */
export const getCardRenewOrderId = cardNo => vm.postAxios(`${BASE_URL}/wx/orderInfo/card/submitRenew`, { cardNo });

/**
 * 获取当前账号绑定id
 */
export const getCardBindInfoId = () => vm.getAxios(`${BASE_URL}/wx/api/cardAccount/bindingInfo`);

/**
 * 获取当前账号绑定卡信息
 */
export const getCardBindInfoDetail = id => vm.getAxios(`${BASE_URL}/wx/api/cardAccount/info`, {
  id,
});

/**
 * 获取当前账号是否可被绑定
 * @param {Number} phone 号码
 * @param {Number} code 验证码
 */
export const checkCardCanBind = ({ phone, code }) => vm.getAxios(`${BASE_URL}/wx/api/leaguerInfo/checkCardAccountInfo`, {
  tel: phone,
  validCode: code,
});

/**
 * 获取他人账号是否可被绑定  并获得他人卡包列表
 * @param {Number} certNo 他人身份证
 * @param {Number} code 验证码
 */
export const checkOtherCardCanBind = ({ certNo, code }) => vm.getAxios(`${BASE_URL}/wx/api/leaguerInfo/checkBindingCardBagValidCode`, {
  certNo,
  validCode: code,
});

/**
 * 绑定
 * @param {Number} certNo 身份证号
 */
export const bindCard = certNo => vm.postAxios(`${BASE_URL}/wx/api/leaguerInfo/bindingCardAccount`, { certNo });

/**
 * 绑定他人卡
 * @param {Array} cardIdArr 他人卡列表
 */
export const bindOtherCard = cardIdArr => vm.jsonAxios(`${BASE_URL}/wx/api/leaguerInfo/bindingCardBag`, cardIdArr);

/**
 * 修改绑定手机号
 * @param {Number} acctId id
 * @param {Number} newTelNo 新手机号
 * @param {Number} code 验证码
 */
export const editBindCardPhone = ({ acctId, newTelNo, code }) => vm.postAxios(`${BASE_URL}/wx/api/leaguerInfo/changeAccoutTelNo`, { acctId, newTelNo, validCode: code });

/**
 * 当前账号解绑
 */
export const cardUnBind = ({ phone, code }) => vm.postAxios(`${BASE_URL}/wx/api/leaguerInfo/unbindingCardAccount`, {
  mobile: phone,
  validCode: code,
});

/* ---------------------------------  认证  --------------------------------- */

/**
 * 获取认证列表
 * @param {Object} data 分页
 */
export const getCardUserCheckList = data => vm.getAxios(`${BASE_URL}/wx/api/approve/approveList`, data);

/**
 * 撤销认证
 * @param {Number} orderId 订单 id
 */
export const cancelCardUserCheck = orderId => vm.postAxios(`${BASE_URL}/wx/api/approve/cancel/${orderId}`);

/**
 * 获取认证详情
 * @param {Number} orderId 订单 id
 */
export const getCardUserCheckDetail = orderId => vm.getAxios(`${BASE_URL}/wx/api/approve/getList/${orderId}`);

/**
 * 校验头像面容
 * @param {String} url 图片地址
 */
export const cardCheckFace = url => vm.postAxios(`${BASE_URL}/wx/api/approve/checkFace`, { url });

/**
 * 提交
 * @param {Object} data 数据包
 */
export const pushUserCheckInfo = data => vm.jsonAxios(`${BASE_URL}/wx/api/approve/save`, data);
