/**
 * 优惠券
 */
const couponsRoute = [{
  path: '/coupons/list',
  name: 'couponsList',
  component: () => import(/* webpackChunkName: "route-coupons" */ '@/pages/coupons/list'),
  meta: {
    title: '优惠券列表',
  },
},
{
  path: '/coupons/detail',
  name: 'couponsDetail',
  component: () => import(/* webpackChunkName: "route-coupons" */ '@/pages/coupons/detail'),
  meta: {
    title: '优惠券领取详情',
  },
},
{
  path: '/coupons/couponsDetail/:id',
  name: 'couponsCouponsDetail',
  component: () => import(/* webpackChunkName: "route-coupons" */ '@/pages/coupons/couponsDetail'),
  meta: {
    title: '优惠券详情',
  },
},
{
  path: '/coupons/success',
  name: 'couponsSuccess',
  component: () => import(/* webpackChunkName: "route-coupons" */ '@/pages/coupons/success'),
  meta: {
    title: '优惠券领取成功',
  },
},
{
  path: '/coupons/member',
  name: 'couponsMember',
  component: () => import(/* webpackChunkName: "route-coupons" */ '@/pages/coupons/member'),
  meta: {
    title: '我的优惠券',
  },
},
];

export default couponsRoute;
