export default [
  // 登陆
  {
    path: '/login/saas',
    name: 'SaasLogin',
    component: () => import(/* webpackChunkName: "route-saas" */ '@/pages/personal/saas/login/index'),
  },
  // 身份验证
  {
    path: '/saas/userCheck',
    name: 'SaasUserCheck',
    component: () => import(/* webpackChunkName: "route-saas" */ '@/pages/personal/saas/userCheck/index'),
  },
  {
    path: '/saas/userCheck/detail',
    name: 'SaasUserCheckDetail',
    component: () => import(/* webpackChunkName: "route-saas" */ '@/pages/personal/saas/userCheck/detail'),
  },
  {
    path: '/saas/userCheck/push',
    name: 'SaasUserCheckPush',
    component: () => import(/* webpackChunkName: "route-saas" */ '@/pages/personal/saas/userCheck/push'),
  },

  // 订单
  {
    path: '/saas/order/list',
    name: 'SaasOrderList',
    component: () => import(/* webpackChunkName: "route-saas" */ '@/pages/personal/saas/order/list'),
  },
  {
    path: '/saas/order/detail',
    name: 'SaasOrderDetail',
    component: () => import(/* webpackChunkName: "route-saas" */ '@/pages/personal/saas/order/detail'),
  },
  {
    path: '/saas/order/refund',
    name: 'SaasOrderRefund',
    component: () => import(/* webpackChunkName: "route-saas" */ '@/pages/personal/saas/order/refund'),
  },

  // 购买
  {
    path: '/saas/yearcard/buy/list',
    name: 'SaasYearCardBuyList',
    component: () => import(/* webpackChunkName: "route-saas" */ '@/pages/personal/saas/yearcard/buy/list'),
  },
  {
    path: '/saas/yearcard/buy/cardDetail',
    name: 'SaasYearCardBuyCardDetail',
    component: () => import(/* webpackChunkName: "route-saas" */ '@/pages/personal/saas/yearcard/buy/cardDetail'),
  },
  {
    path: '/saas/yearcard/buy/comfirm',
    name: 'SaasYearCardBuyComfirm',
    component: () => import(/* webpackChunkName: "route-saas" */ '@/pages/personal/saas/yearcard/buy/comfirm'),
  },
  {
    path: '/saas/yearcard/buy/success',
    name: 'SaasYearCardBuySuccess',
    component: () => import(/* webpackChunkName: "route-saas" */ '@/pages/personal/saas/yearcard/buy/success'),
  },

  // 年卡中心
  {
    path: '/saas/yearcard/center',
    name: 'SaasYearcardCenter',
    component: () => import(/* webpackChunkName: "route-saas" */ '@/pages/personal/saas/yearcard/center/index'),
  },
  {
    path: '/saas/yearcard/center/card',
    name: 'SaasYearcardCenterCard',
    component: () => import(/* webpackChunkName: "route-saas" */ '@/pages/personal/saas/yearcard/center/card'),
  },
  {
    path: '/saas/yearcard/center/cardDetail',
    name: 'SaasYearcardCenterCardDetail',
    component: () => import(/* webpackChunkName: "route-saas" */ '@/pages/personal/saas/yearcard/center/cardDetail'),
  },
  {
    path: '/saas/yearcard/center/advice',
    name: 'SaasYearcardCenterAdvice',
    component: () => import(/* webpackChunkName: "route-saas" */ '@/pages/personal/saas/yearcard/center/advice'),
  },
  {
    path: '/saas/yearcard/center/bind',
    name: 'SaasYearcardCenterBind',
    component: () => import(/* webpackChunkName: "route-saas" */ '@/pages/personal/saas/yearcard/center/bind'),
  },
  {
    path: '/saas/yearcard/center/convertKey',
    name: 'SaasYearcardCenterConvertKey',
    component: () => import(/* webpackChunkName: "route-saas" */ '@/pages/personal/saas/yearcard/center/convertKey'),
  },
  {
    path: '/saas/yearcard/center/toConvertKey',
    name: 'SaasYearcardCentertoConvertKey',
    component: () => import(/* webpackChunkName: "route-saas" */ '@/pages/personal/saas/yearcard/center/toConvertKey'),
  },
  {
    path: '/saas/yearcard/center/question',
    name: 'SaasYearcardCenterQuestion',
    component: () => import(/* webpackChunkName: "route-saas" */ '@/pages/personal/saas/yearcard/center/question'),
  }
]