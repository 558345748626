<template>
  <div
    ref="wrapper"
    class="hot-module"
    :style="wrapperStyle"
    @click="onWrapperClick"
  >
    <img
      ref="hotImg"
      :style="imgStyle"
      :src="img"
      @load="onImgLoad"
    >
    <template v-if="isShowHot">
      <div
        v-for="(item, index) in hotList"
        :key="index"
        class="hot-item"
        :style="[getHotStyle(item)]"
        @click.stop="onHotItemClick(index)"
      ></div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    img: {
      type: String,
      default: '',
    },
    hotList: {
      type: Array,
      default: () => [],
    },
    valueMap: {
      type: Object,
      default: () => ({
        top: 'topPercent',
        left: 'leftPercent',
        width: 'widthPercent',
        height: 'heightPercent',
      }),
    },
    imgStyle: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isShowHot: false,
      wrapperWidth: 0,
      wrapperHeight: 0,
    };
  },
  computed: {
    wrapperStyle() {
      if (!this.isShowHot) {
        return {};
      }
      return this.imgStyle || {
        width: `${this.wrapperWidth}px`,
        height: `${this.wrapperHeight}px`,
      };
    },
  },
  methods: {
    getHotStyle(data) {
      return {
        top: `${data[this.valueMap.top] * 100}%`,
        left: `${data[this.valueMap.left] * 100}%`,
        width: `${data[this.valueMap.width] * 100}%`,
        height: `${data[this.valueMap.height] * 100}%`,
      };
    },
    onImgLoad() {
      const dom = this.$refs.hotImg;
      this.wrapperWidth = dom.width;
      this.wrapperHeight = dom.height;
      this.isShowHot = true;
    },
    onHotItemClick(index) {
      this.$emit('hot-click', index);
    },
    onWrapperClick() {
      this.$emit('wrapper-click');
    },
  },
};
</script>

<style scoped lang="scss">
.hot-module {
  position: relative;
  .hot-item {
    position: absolute;
  }
}
</style>
