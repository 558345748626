import store from '@/store';
import { clearToken } from '@/utils';

const { wx, tt, my } = window;

let isMiniProgram;
let miniType; // wx--微信；tt--头条
let isOtherMiniProgram = false;
// 微信小程序环境判断
function getWxEvn() {
  return new Promise((resolve) => {
    wx.miniProgram.getEnv(res => {
      resolve(res);
    });
  });
}
// 是否为微信小程序
async function isWxMini() {
  let result;
  const ua = navigator.userAgent;
  if (
    (ua.match(/micromessenger/i) &&
    ua.match(/miniprogram/i)) ||
    window.__wxjs_environment === 'miniprogram'
  ) {
    result = true;
  } else if (ua.match(/micromessenger/i)) {
    const env = await getWxEvn();
    result = Boolean(env.miniprogram);
  } else {
    result = false;
  }
  return result;
}
// 是否为头条小程序
function isTtMini() {
  return navigator.userAgent
    .toLowerCase()
    .includes('toutiaomicroapp');
}
// 支付宝小程序环境判断
function getAliEnv() {
  return new Promise((resolve) => {
    my.getEnv((res) => {
      resolve(Boolean(res.miniprogram)); // true
    });
  });
}
// 支付宝小程序环境判断
async function isAliMini() {
  if (!navigator.userAgent.includes('AlipayClient')) {
    return false;
  }
  const result = await getAliEnv();
  return result;
}
async function getMiniProgramStatus() {
  if (isTtMini()) {
    isMiniProgram = true;
    miniType = 'tt';
  } else if (await isWxMini()) {
    isMiniProgram = true;
    miniType = 'wx';
  } else if (await isAliMini()) {
    isMiniProgram = true;
    miniType = 'ali';
  }
}
// 初始化小程序狀態，如果不是小程序的話則清除小程序token
async function initMiniProgram() {
  await getMiniProgramStatus();
  const tokenType = localStorage.getItem('token_type');
  if (tokenType === 'mini' && isMiniProgram === false) {
    clearToken();
  }
}

function getMiniSdk() {
  if (miniType === 'tt') {
    return tt;
  } else if (miniType === 'wx') {
    return wx;
  } else if (miniType === 'ali') {
    return { miniProgram: my };
  } else {
    return null;
  }
}
function changeOtherMPStauts(status) {
  store.commit('baseinfo/updateIsOtherMP', status);
  isOtherMiniProgram = status;
}


function postMessage(value, type = '') {
  getMiniSdk().miniProgram.postMessage({
    data: {
      type,
      history: window.location.href,
      value,
    },
  });
}
function navigateTo(url) {
  getMiniSdk().miniProgram.navigateTo({ url });
}
function navigateBack() {
  getMiniSdk().miniProgram.navigateBack();
}
function redirectTo(url, query = {}) {
  if (Object.keys(query).length) {
    url += '?';
    for (const key in query) {
      url += `${key}=${query[key]}&`;
    }
    url = url.slice(0, url.length - 1);
  }
  getMiniSdk().miniProgram.redirectTo({ url });
}
function isMP() {
  if (isMiniProgram === undefined) {
    getMiniProgramStatus();
  }
  const isInMp = !isOtherMP() && isMiniProgram;
  if (!isInMp) return false;
  return miniType || true;
}
// 是否为其他小程序（只支持0元购）
function isOtherMP() {
  return store.state.baseinfo.isOtherMP || isOtherMiniProgram;
}

const mpPostMessage = (value, type = '') => {
  if (isMP() && isMP() === 'wx') {
    postMessage(value, type);
  }
};
// 对应的小程序路径
const pageMap = {
  Home: '/pages/index/index',
  Custompage: (to) => `/pages/customPage/index?id=${to.query.id}`,
  Personal: '/pages/member/index',
  cart: '/pages/shoppingCart/index',
};
// 判断是否需要跳转小程序页面,如果需要的话，就跳转到对应的页面
function toMiniProgramPage(to) {
  // 判断是否为小程序环境
  if (!isMP()) return false;
  // 判断是否需要特殊跳转
  if (!pageMap[to.name]) return false;

  let target = pageMap[to.name];
  if (typeof target === 'function') {
    target = target(to);
  }
  redirectTo(target);

  return true;
}
// 是否移动端判断
function isMobileNavigator() {
  const flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
  return flag;
}
const miniProgram = {
  isMP,
  postMessage,
  navigateTo,
  navigateBack,
  redirectTo,
  initMiniProgram,
  getMiniProgramStatus,
  changeOtherMPStauts,
  isOtherMP,
  mpPostMessage,
  toMiniProgramPage,
  isMobileNavigator,
};

export default miniProgram;
