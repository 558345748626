// 礼品卡 - 所有产品列表
const routes = [
  {
    path: '/products/list',
    name: 'productsList',
    meta: {
      title: '产品列表',
      customTheme: false,
    },
    component: () => import(/* webpackChunkName: "route-products" */ '@/pages/products/list'),
  },
];

export default routes;
