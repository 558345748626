/**
 * 剧院换座路由
 */
const changeSeatRoute = [
  {
    path: '/changeSeats/index',
    name: 'changeSeatIndex',
    component: () => import(/* webpackChunkName: "route-order" */ '@/pages/changeSeats/index'),
  },
  {
    path: '/changeSeats/list',
    name: 'changeSeatList',
    component: () => import(/* webpackChunkName: "route-order" */ '@/pages/changeSeats/list'),
  },
  {
    path: '/changeSeats/select',
    name: 'changeSeatSelect',
    component: () => import(/* webpackChunkName: "route-order" */ '@/pages/changeSeats/select'),
  },
  {
    path: '/changeSeats/result',
    name: 'changeSeatResult',
    component: () => import(/* webpackChunkName: "route-order" */ '@/pages/changeSeats/result'),
  },
];

export default changeSeatRoute;
