import VueCookies from 'vue-cookies';
import miniProgram from '@/common/miniProgram';
import { Command, MacroCommand } from '@/utils/commonClass';
import store from '@/store';
import { getCacheData } from '@/api/product';
import { initData } from '@/utils/transinformation';

// 自定义推广渠道处理
function initGeneralize(querys) {
  if (querys.merchantChannelCode) {
    VueCookies.set('merchantChannelCode', querys.merchantChannelCode, '1d', '/', window.location.hostname);
  }
}
// 其他小程序跳转h5特殊处理,湖北需求嵌入h5使用0元支付功能
function otherMiniProgram(querys) {
  if (querys.from === 'othermp') {
    miniProgram.changeOtherMPStauts(true);
  }
}
// 其他平台跳转小鲸带的特定参数
function initFromSource(querys) {
  if (querys.fromSource) {
    store.commit('baseinfo/updateFromSource', querys.fromSource);
  }
}
// 小程序传递过来的数据处理
function initMiniData(querys) {
  if (querys.cacheCode) {
    return getCacheData(querys.cacheCode).then(res => {
      initData(res, store);
    });
  }
  return null;
}
// 处理参数上的埋点信息
function initPointInfo(querys) {
  if (querys.traceId && querys.sessionId && querys.browserId) {
    store.dispatch('point/changePointData', querys);
  }
}
// 全局页面参数处理--每个页面都会调用，不要加太多东西
const routeQueryCommand = new MacroCommand();
routeQueryCommand.add(new Command(initGeneralize));
routeQueryCommand.add(new Command(otherMiniProgram));
routeQueryCommand.add(new Command(initFromSource));
routeQueryCommand.add(new Command(initPointInfo));
routeQueryCommand.add(new Command(initMiniData, true));

export default routeQueryCommand;
