import { getPageNav, getCustomPageNav } from '@/api/merchant';

const getNavData = (navDataList, position) => {
  const data = navDataList.find(v => v.position === position);
  if (!data) {
    return null;
  }
  const opts = data.content ? JSON.parse(data.content) : null;
  return opts;
};

const state = {
  navDataList: [],
};

const getters = {
  navDataList: (state) => state.navDataList,
  bottomNavOptions: (state) => {
    const data = getNavData(state.navDataList, 'bottom');
    return data && data.type !== 'image' ? data : null;
  },
  imgBottomNavOptions: (state) => {
    const data = getNavData(state.navDataList, 'bottom');
    return data && data.type === 'image' ? data : null;
  },
  sideNavOptions: (state) => getNavData(state.navDataList, 'rightSide'),
  circleNavOptions: (state) => getNavData(state.navDataList, 'circle'),
};

const mutations = {
  SET_NAVDATALIST(state, payload) {
    state.navDataList = payload || [];
  },

};

const actions = {
  async getNavDataList({ commit }, routeName) {
    const res = await getPageNav(routeName);
    commit('SET_NAVDATALIST', res);
  },
  async getCustomPageNavDataList({ commit }, id) {
    if (!id) return;
    const res = await getCustomPageNav(id);
    commit('SET_NAVDATALIST', res);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
