const cloudCaterRoute = [
  {
    path: '/cloudCaterList',
    name: 'cloudCaterList',
    component: () => import('@/pages/service/list/cloudCaterList'),
    meta: {
      title: '店铺列表',
    },
  },
  {
    path: '/cloudCaterDetail/:id',
    name: 'cloudCaterDetail',
    component: () => import('@/pages/service/detail/cloudCaterDetail'),
    meta: {
      title: '店铺详情',
    },
  },
  {
    path: '/cloudCaterVoucherDetail/:id',
    name: 'cloudCaterVoucherDetail',
    component: () => import('@/pages/service/detail/cloudCater/voucher.vue'),
    meta: {
      title: '兑换券详情',
    },
  },
  {
    path: '/cloudCaterOrder/:id',
    name: 'cloudCaterOrder',
    component: () => import('@/pages/service/order/cloudCaterOrder'),
    meta: {
      title: '确认订单',
    },
  },
  {
    path: '/cloudCaterPayResult',
    name: 'cloudCaterPayResult',
    component: () => import('@/pages/service/payResult/cloudCater'),
    meta: {
      title: '支付结果',
    },
  },
  {
    path: '/cloudCaterSmsCouponCode',
    name: 'cloudCaterSmsCouponCode',
    component: () => import('@/pages/service/smsCouponCode/cloudCater'),
    meta: {
      title: '短信券码',
    },
  },
  {
    path: '/cloudCaterOrderDetail/:id',
    name: 'cloudCaterOrderDetail',
    component: () => import('@/pages/service/orderDetail/cloudCaterOrderDetail'),
    meta: {
      title: '订单详情',
    },
  },
  {
    path: '/cloudCaterRefundApply',
    name: 'cloudCaterRefundApply',
    component: () => import('@/pages/service/refund/cloudCater/refundApply'),
    meta: {
      title: '退款申请',
    },
  },
  {
    path: '/cloudCaterSubmitResult',
    name: 'cloudCaterSubmitResult',
    component: () => import('@/pages/service/refund/cloudCater/submitResult'),
    meta: {
      title: '提交结果',
    },
  },
  {
    path: '/cloudCaterRefundDetail/:id',
    name: 'cloudCaterRefundDetail',
    component: () => import('@/pages/service/refund/cloudCater/refundDetail'),
    meta: {
      title: '退款单详情',
    },
  },
];

export default cloudCaterRoute;
