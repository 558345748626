/* eslint-disable */
import Vue from "vue";
import VueCookies from 'vue-cookies';
import App from "./App";
import router from "./router";
import { resizeImg, getToken } from '@/utils';
import store from "./store";
import "@/styles/icon.scss"
import "@/assets/font/iconfont.css";
import "@/assets/weatherFont/iconfont.js";
import apiAxios, { jsonAxios } from "./api/axios.js";
import * as filters from "./filters";
import api from "./api/api";
import { getCorpCode } from "@/ajax/guide"
import { linkBaseUrl, pubUrl, yearCardBaseUrl } from "@/common/config.js"

import {
    isWxAli,
    imageIsExist
} from '@/common/common.js';
import {
    LoadingPlugin,
    ToastPlugin,
    ConfirmPlugin,
    AlertPlugin
} from 'vux';
import datePicker from '@/components/datePicker'
import preview from 'vue-photo-preview'
import 'vue-photo-preview/dist/skin.css'
import miniProgram from '@/common/miniProgram.js';
import '@sdi/fish/lib/style/base.less'
import catchMap from '@/utils/catch-map.js'

// import * as Sentry from '@sentry/browser';
// import { Vue as VueIntegration } from '@sentry/integrations';
// import { Integrations } from '@sentry/tracing';

// const { VUE_APP_VERSION, VUE_APP_MODE } = process.env;

// if (VUE_APP_MODE !== 'dev') {
//     Sentry.init({
//         dsn: 'https://a44d0790d6b847bfb0a3e2f15e1032f4@sentry.sendinfo.com.cn/12',
//         release: VUE_APP_VERSION,
//         integrations: [
//             new VueIntegration({
//             Vue,
//             tracing: true,
//             }),
//             new Integrations.BrowserTracing(),
//         ],
//         tracesSampleRate: 1,
//         environment: VUE_APP_MODE
//     });
// }

miniProgram.initMiniProgram()
// 临时fix this.api
Vue.prototype.api = api;
//定义全局过滤器
Object.keys(filters).forEach(key => {
    Vue.filter(key, filters[key]);
});

//全局使用,js调用
Vue.use(datePicker)
Vue.use(preview, {
    arrowEl: false, //左右切换
    fullscreenEl: false, //控制右上角全屏按钮
    zoomEl: false, //控制是否显示放大缩小按钮
})
Vue.use(VueCookies);
Vue.use(LoadingPlugin);
Vue.use(ToastPlugin);
Vue.use(ConfirmPlugin);
Vue.use(AlertPlugin);

//全局初始化axios请求
Vue.use(apiAxios);
Vue.config.productionTip = false;

//混合，全局使用api
Vue.mixin({
    beforeRouteEnter (to, from, next) {       
        next(vm => {
            let env = isWxAli()
            let linkSource = env.isWx ? 'weixin' : (env.isAliPay ? 'alipay' : 'web')
            let pageType = to.meta.pageType || '';
            let merchantInfoId = vm.$route.query.m_id;
            sessionStorage.setItem("xjsc_pageentertime_20200303", (new Date).valueOf())
            if (merchantInfoId && (merchantInfoId instanceof Array || merchantInfoId === 'undefined')) {
                merchantInfoId = '';
            }
            if (merchantInfoId && to.matched.length && !(to.meta && to.meta.pagePoint)) {
                // 缓存本地进入时间
                sessionStorage.setItem('xjsc_point_url_20200319', window.location.href)
                jsonAxios('/marketing/api/log/save', {
                    handleType: 'enter', //方便前端查看页面是进入还是离开
                    url: window.location.href,
                    pagePeriod: '',
                    productName: "",
                    linkSource: linkSource,
                    pageType: pageType,
                    productType: to.meta.productType ? to.meta.productType : '',
                    source: "client",
                    shopId: merchantInfoId,
                    fromSource: store.state.baseinfo.fromSource,
                    // 支付结果添加支付订单信息
                    extend1: typeof pageType !== 'undefined' && pageType === 'payResult' ? to.query.payOrderNo : '',
                    // 禁止加载弹框，后台不需要
                    unLoading: true,
                }, '', true)
            }
        })
    },
    beforeRouteLeave(to, from, next) {
        if (to.query.m_id && from.query.m_id && from.matched.length && !(from.meta && from.meta.pagePoint)) {
            let stayTime = null
            let enterTime = sessionStorage.getItem("xjsc_pageentertime_20200303")
            let leaveTime = (new Date()).valueOf()
            let productType = from.meta.productType || ''
            let pageType = from.meta.pageType || ''
            if (enterTime) {
                stayTime = leaveTime - enterTime
            }
            let env = isWxAli()
            let linkSource = env.isWx ? 'weixin' : (env.isAliPay ? 'alipay' : 'web')
            let url = sessionStorage.getItem("xjsc_point_url_20200319")
            if (url) {
                sessionStorage.removeItem('xjsc_point_url_20200319')
            }
            jsonAxios('/marketing/api/log/save', {
                handleType: 'leave', //方便前端查看页面是进入还是离开
                url: url,
                productName: "",
                linkSource: linkSource,
                pageType: pageType,
                productType: productType,
                source: "client",
                pagePeriod: stayTime,
                shopId: from.query.m_id,
                fromSource: store.state.baseinfo.fromSource,
                // 支付结果添加支付订单信息
                extend1: typeof pageType !== 'undefined' && pageType === 'payResult' ? from.query.payOrderNo : '',
            }, '', true)
        }
        next()
    },
    methods: {
        resizeImg,
        getUserId() {
            return this.$cookies.get(`leaguerInfoId_${localStorage.getItem("xjsc_vue_2018_12_19_mid")}`) || localStorage.getItem("xjsc_login_userId_2019_3_8");
        },
        //解决IOS12以上微信内置浏览器下键盘收起底部空白的问题，导致底部点击失效问题
        iosBlur() {
            let ua = window.navigator.userAgent;
            //$alert('浏览器版本: ' + app + '\n' + '用户代理: ' + ua);
            if (!!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
                //ios系统
                let currentPosition, timer;
                let speed = 1;
                timer = setInterval(function () {
                    currentPosition =
                        document.documentElement.scrollTop || document.body.scrollTop;
                    currentPosition -= speed;
                    window.scrollTo(0, currentPosition); //页面向上滚动
                    currentPosition += speed;
                    window.scrollTo(0, currentPosition); //页面向下滚动
                    clearInterval(timer);
                    // alert("失去焦点")
                    console.log("失去焦点");
                }, 100);
            }
        },
        checkToken(nextPath) {
            //用于验证当前token过期或者不存在的问题
            if (nextPath) {
                //用于window.locatin.href 跳转
                localStorage.setItem("xjsc_nextpath_2019_4_20", nextPath);
            }
            //在该方法then后面主动清理xjsc_nextpath_2019_4_20，具体使用可以查看门票详情页面
            return this.getAxios("/up/api/loginStatus");
        },
        go(path) {
            if (path) {
                this.$router.push(path);
            }
        },
        goLinkUrl(text) {
            let m_id = localStorage.getItem("xjsc_vue_2018_12_19_mid");
            let url = "";
            if (text === "home") {
                url = "/" + "?m_id=" + m_id;
            } else if (text === "personal") {
                url = "/personal" + "?m_id=" + m_id;
            } else if (text === "order") {
                url = "/orderList" + "?m_id=" + m_id;
            } else if (text === "") {
                url = "/" + "?m_id=" + m_id;
            } else if (text === "") {
                url = "/" + "?m_id=" + m_id;
            } else if (text === "publicservice" || text === "tourGuide") {
                this.toTourGuide()
                return
                this.getAxios(api.main.marketing.selectServiceUse, {
                    serviceType: "guide"
                })
                    .then(response => {
                        if (response.status === "200" && response.data.guide) {
                            this.getAxios(api.main.merchant.gotoPublic, {
                                merchantId: m_id
                            })
                                .then(res => {
                                    if (res.status === 200) {
                                        // window.location.href = "http://pubswap.sendinfo.com.cn/publicMap/index/?acc=" + res.message
                                        window.location.href =
                                            "//pubswap.zhiyoubao.com/publicMap/index/?acc=" +
                                            res.message;
                                        // window.location.href = pubUrl + '/publicMap/index/?acc=' + res.message;
                                    } else {
                                        console.log("没找到手机号码");
                                    }
                                })
                                .catch(e => {
                                    console.log(e);
                                });
                        } else {
                            this.$vux.alert.show({
                                title: "",
                                content: "你还没有购买此服务或者你购买的此服务已过期"
                            });
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    });
                return;
            }
            this.$router.push(url);
        },
        goUrl(item) {
            let m_id = localStorage.getItem("xjsc_vue_2018_12_19_mid");
            let url = "";
            if (item.businessType === "ticket") {
                // 门票
                url =
                    "/detail/ticket/" +
                    item.merchantProdcutId +
                    "/" +
                    item.productCode +
                    "?m_id=" +
                    m_id;
            } else if (item.businessType === "room") {
                //酒店
                url =
                    "/detail/hotel/" +
                    item.merchantProdcutId +
                    "/" +
                    item.productCode +
                    "?m_id=" +
                    m_id;
            } else if (item.businessType === "mdse") {
                url =
                    "/detail/shop/" +
                    item.merchantProdcutId +
                    "/" +
                    item.productCode +
                    "?m_id=" +
                    m_id;
            } else if (item.businessType === "repast") {
                url =
                    "/detail/repast/" +
                    item.merchantProdcutId +
                    "/" +
                    item.productCode +
                    "?m_id=" +
                    m_id;
            } else if (item.businessType === "route") {
                url =
                    "/detail/route/" +
                    item.merchantProdcutId +
                    "/" +
                    item.productCode +
                    "?m_id=" +
                    m_id;
            } else if (item.businessType === "pmsHotelTypeProduct") {
                this.$router.push(
                    "/detail/pmsHotel?id=" +
                    item.merchantProdcutId +
                    "&m_id=" +
                    m_id
                );
            }
            window.location.href = linkBaseUrl + url;
        },
        //新自定义页面跳转
        gotoTarget (item) {
            let baseUrl = linkBaseUrl;
            let m_id = localStorage.getItem("xjsc_vue_2018_12_19_mid");
            let url = "",
                vueUrl = '';
            if (item.linkUrl === 'destination') {
                const { addressLink } = this.$store.state.baseinfo;
                if (addressLink) {
                    this.gotoTarget(addressLink)
                }
            } else if (item.linkUrl === "personal") {
                vueUrl = "/personal?m_id=" + m_id;
            } else if (item.linkUrl === "qynk") {
                //全域年卡
                const qynkUrl = `${yearCardBaseUrl}/?m_id=${m_id}`;
                window.location.href = qynkUrl;
                return false
            } else if (item.linkUrl === "home") {
                vueUrl = "/?m_id=" + m_id;
                // 如果当前是首页着不跳转
                // window.location.reload()

                // return false
                // router.push(url)
            } else if (item.linkUrl === "traffic") {
                vueUrl = "/traffic_home?m_id=" + m_id;
            } else if (item.linkUrl === 'shopcart') {
                vueUrl = '/cart?m_id=' + m_id;
            } else if (item.linkUrl === 'order') {
                url = '/list/order?m_id=' + m_id;
            } else if (item.linkUrl === 'groupbooking') {
                url = '/group/orderList?m_id=' + m_id;
            } else if (item.linkUrl === 'coupon') {
                url = '/coupons/member?m_id=' + m_id;
            } else if (item.linkUrl === 'receivecoupons') {
                url = '/coupons/list?m_id=' + m_id;
            } else if (item.linkUrl === 'accountcenter') {
                url = '/member/user?m_id=' + m_id;
            } else if (item.linkUrl === 'ticket') {
                vueUrl = '/list/ticket?m_id=' + m_id;
            } else if (item.linkUrl === 'guide') {
                vueUrl = '/list/guide?m_id=' + m_id;
            } else if (item.linkUrl === 'rent_car') {
                vueUrl = '/list/rentalCars?m_id=' + m_id;
            } else if (item.linkUrl === 'tickets') {
                vueUrl = '/list/tickets?m_id=' + m_id;
            } else if (item.linkUrl === "cloud_repast") {
                // vueUrl = "/list/rmsRepast?m_id=" + m_id;
                vueUrl = "/cloudCaterList?m_id=" + m_id;
            } else if (item.linkUrl === 'hotel') {
                url = '/list/hotel?m_id=' + m_id;
            } else if (item.linkUrl === 'integralStore') {
                vueUrl = "/integralStore/list?m_id=" + m_id;
            } else if (item.linkUrl === "pmsHotel") {
                vueUrl = "/list/pmsHotel?m_id=" + m_id;
            } else if (item.linkUrl == "shop") {
                if (item.selectLabels && item.selectLabels instanceof Array && item.selectLabels.length > 0) {
                    let filterId = item.selectLabels.map(label => label.id);
                    url = "/vue/list/shop?m_id=" + m_id + '&category=' + filterId.join(',');
                } else {
                    url = "/vue/list/shop?m_id=" + m_id;
                }
            } else if (item.linkUrl === "repast") {
                vueUrl = "/list/repast?m_id=" + m_id;
            } else if (item.linkUrl === "route") {
                if (item.columnId && item.columnId.length === 2) {
                    vueUrl = `/list/route?m_id=${m_id}&categoryId=${item.columnId[1]}`;
                } else {
                    vueUrl = `/list/route?m_id=${m_id}`;
                }
            } else if (item.linkUrl === "album") {
                //相册
                vueUrl = `/material/album?id=${item.albumId}&m_id=${m_id}`;
            } else if (item.linkUrl === "strategy") {
                let arr = [];
                if (item.strategyArr) {
                    arr = item.strategyArr.map(item => item.key);
                }
                if (arr.length) {
                    url =
                        "/vue/list/strategy?strategyTypeIds=" +
                        arr.join(",") +
                        "&m_id=" +
                        m_id;
                } else {
                    url = "/vue/list/strategy?m_id=" + m_id;
                }
            } else if (item.linkUrl === "custompage") {
                // url = '/custompage?id=' + item.customPageId + '&m_id=' + m_id;
                this.$router.push("/custompage?id=" + item.customPageId);
            } else if (item.linkUrl === "customtel") {
                window.location.href = "tel:" + item.customTel;
            } else if (item.linkUrl === "customlink") {
                // url = item.customLinkurl;
                let reg = /(http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&amp;:/~\+#]*[\w\-\@?^=%&amp;/~\+#])?/;
                if (reg.test(item.customLinkurl)) {
                    window.location.href = item.customLinkurl;
                } else {
                    let strUrl = "//" + item.customLinkurl;
                    window.location.href = strUrl;
                }
                return;
            } else if (item.linkUrl === "differentLink") {
                // url = item.customLinkurl;
                if (miniProgram.isMP()) {
                    miniProgram.redirectTo(item.miniLink);
                } else {
                    let reg = /(http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&amp;:/~\+#]*[\w\-\@?^=%&amp;/~\+#])?/;
                    if (reg.test(item.wapLink)) {
                        window.location.href = item.wapLink;
                    } else {
                        let strUrl = "//" + item.wapLink;
                        window.location.href = strUrl;
                    }
                }

                return;
            } else if (item.linkUrl === "publicservice" || item.linkUrl === "tourGuide") {
                this.toTourGuide()
                return
            } else if (item.linkUrl === "parkcard") {
                this.getAxios(api.main.marketing.selectServiceUse, {
                    serviceType: "gynk"
                })
                    .then(res => {
                        if (res.status === 200 && res.data.gynk) {
                            window.location.href = baseUrl + "/list/yearcard?m_id=" + m_id;
                        } else {
                            this.$vux.alert.show({
                                title: "",
                                content: "你还没有购买此服务或者你购买的此服务已过期"
                            });
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    });
                return;
            } else if (item.linkUrl === "newYearCard") {
                this.getAxios(api.main.marketing.selectServiceUse, {
                    serviceType: "newYearCard"
                })
                    .then(res => {
                        if (res.status === 200 && res.data.newYearCard) {
                            window.location.href = baseUrl + "/vue/saas/yearcard/buy/list?m_id=" + m_id;
                        } else {
                            this.$vux.alert.show({
                                title: "",
                                content: "你还没有购买此服务或者你购买的此服务已过期"
                            });
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    });
                return;
            } else if (item.linkUrl === "theater") {
                this.getAxios(api.main.marketing.selectServiceUse, {
                    serviceType: "theater"
                })
                    .then(res => {
                        if (res.status === 200 && res.data.theater) {
                            window.location.href = baseUrl + "/vue/list/theater?m_id=" + m_id;
                        } else {
                            this.$vux.alert.show({
                                title: "",
                                content: "你还没有购买此服务或者你购买的此服务已过期"
                            });
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    });
                return;
            } else if (item.linkUrl === "ai_p") {
                this.$vux.alert.show({
                    title: "",
                    content: "暂未开放"
                });
                return;
            } else if (item.linkUrl === "information") {
                // 中台-cms 自定义链接
                // 获取企业码
                this.getAxios("/merchant/api/merchantInfo/getCorpCode", {
                    merchantInfoId: m_id
                })
                    .then(res => {
                        if (res.status === 200) {
                            let corpcode = res.message;
                            const locationCode = store.getters['baseinfo/destinationFilter'].code;
                            const locationListCode = store.getters['baseinfo/destinationListFilter'].code;
                            if (item.columnId && item.columnId.length) {
                                const result =
                                    pubUrl + "/wap/#/news?colId=" +
                                    item.columnId[item.columnId.length - 1] +
                                    "&corpCode=" +
                                    corpcode +
                                    "&location=" + locationCode +
                                    "&list=" + locationListCode;

                                router.toOtherPage(result)
                            }

                        } else {
                            this.$vux.alert.show({
                                title: "",
                                content: res.message
                            });
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    });
                return;
            }
            if (url) {
                window.location.href = baseUrl + url;
            } else if (vueUrl) {
                router.push(vueUrl)
            }
        },
        getSingleAxios() {
            return this.getAxios(`/merchant/api/merchantWholeMarket/getOpenSingle`).then(res => {
                return res;
            })
        },
        /**
         * 
         * @param {String | Number} val 身份证号码
         */
        mainCheckIdCard(val) {
            let ID = val;
            if (!ID) return false;
            if (typeof ID !== "string" && typeof ID !== "number") {
                return false;
            }
            let city = { 11: "北京", 12: "天津", 13: "河北", 14: "山西", 15: "内蒙古", 21: "辽宁", 22: "吉林", 23: "黑龙江 ", 31: "上海", 32: "江苏", 33: "浙江", 34: "安徽", 35: "福建", 36: "江西", 37: "山东", 41: "河南", 42: "湖北 ", 43: "湖南", 44: "广东", 45: "广西", 46: "海南", 50: "重庆", 51: "四川", 52: "贵州", 53: "云南", 54: "西藏 ", 61: "陕西", 62: "甘肃", 63: "青海", 64: "宁夏", 65: "新疆", 71: "台湾", 81: "香港", 82: "澳门", 83: "台湾居民", 91: "国外" };
            let birthday =
                ID.substr(6, 4) +
                "/" +
                Number(ID.substr(10, 2)) +
                "/" +
                Number(ID.substr(12, 2));
            let d = new Date(birthday);
            let newBirthday =
                d.getFullYear() +
                "/" +
                Number(d.getMonth() + 1) +
                "/" +
                Number(d.getDate());
            let currentTime = new Date().getTime();
            let time = d.getTime();
            let arrInt = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
            let arrCh = ["1", "0", "X", "9", "8", "7", "6", "5", "4", "3", "2"];
            let sum = 0,
                i,
                residue;
            if (!/^\d{17}(\d|x)$/i.test(ID))
                return false;
            if (city[ID.substr(0, 2)] === undefined)
                return false;
            if (time >= currentTime || birthday !== newBirthday)
                return false;
            for (i = 0; i < 17; i++) {
                sum += ID.substr(i, 1) * arrInt[i];
            }
            residue = arrCh[sum % 11];
            if (residue !== ID.substr(17, 1).toUpperCase())
                return false;
            return true;
        },
        /**
         * 
         * 导览跳转 20200730
         * 
         */
        toTourGuide() {
            const m_id = localStorage.getItem("xjsc_vue_2018_12_19_mid");
            const token = getToken();
            getCorpCode({ merchantInfoId: m_id }).then(res => {
                if (res.status === 200) {
                    let url = `${pubUrl}/wap/#/tourGuide/list?m_id=${m_id}&corpCode=${res.message}`;
                    if (token) {
                        url += `&token=${token}`
                    }
                    router.toOtherPage(url)
                } else {
                    console.log("获取code出错了")
                }
            })

        },
        // 唤起小程序地图
        catchMap
    },
});

//用于加载图片失败情况的指令
Vue.directive("error-img", async function (el, binding) {
    if (el.src) return;
    //初始化设置加载默认失败时图片
    el.src = require("./assets/images/none_img.jpg");
    let imgURL = binding.value; //获取图片地址
    if (imgURL) {
        let exist = await imageIsExist(imgURL);
        if (exist) {
            el.setAttribute("src", imgURL);
        }
    }
});

/* eslint-disable no-new */
export default new Vue({
    store,
    router,
    render: h => h(App)
}).$mount('#app')