import Vue from 'vue';
import Router from 'vue-router';
// import {
//   isWxAli,
// } from '@/common/common';
import VueCookies from 'vue-cookies';
import axios from 'axios';
import querystring from 'query-string';
import preSaleRoute from './preSaleRoute';
import preSaleTheaterRoute from './preSaleTheaterRoute';
import couponsRoute from './couponsRoute';
import trafficRoutes from './trafficRoutes.js';
import cartRoute from './cartRoute';
import saasRouter from './saas';
import memberRoute from './memberRoute';
import orderRoute from './order';
import changeSeatRoute from './changeSeat';
import sqfxRoute from './sqfxRoute';
import materialRoute from './materialRoute';
import cloudCaterRoute from './cloudCaterRoute';
// 抽奖路由
import luckDrawRoute from './luckDrawRoute';
import giftCardRouter from './giftCardRouter';
import productsRoute from './productsRoute';
import shopsRoute from './shopsRoute';
import store from '@/store';
import { getXJBaseImg } from '@/pages/personal/saas/api';
import miniProgram from '@/common/miniProgram';
import routeQueryCommand from './handleRouteQuery';
import virtualProductsRouter from './virtualProductsRouter';
import ctgRoute from './ctgRoute';// 会员信息修改
// app授权路由
import appAuthRouter from './appAuthRouter';
// app授权配置
import { getAppAuthConfig, getParamsFromAppPath } from '@/common/appAuth/auth';

function addMID(query, mId) {
  const newQuery = {
    ...query,
  };
  if (!newQuery.m_id) {
    newQuery.m_id = mId;
  }
  return newQuery;
}

class LotsRouter extends Router {
  getNewLocation(location) {
    const currentQuery = this.currentRoute.query;
    const M_ID = currentQuery.m_id;
    let newLocation;
    if (typeof location === 'string') {
      const q = querystring.parseUrl(location);
      q.query = addMID(q.query, M_ID);
      newLocation = querystring.stringifyUrl(q);
    } else {
      newLocation = {
        ...location,
        query: addMID(location.query, M_ID),
      };
    }
    return newLocation;
  }

  push(location, onComplete, onAbort) {
    super.push(this.getNewLocation(location), onComplete, onAbort);
  }

  replace(location, onComplete, onAbort) {
    super.replace(this.getNewLocation(location), onComplete, onAbort);
  }

  toOtherPage(href) {
    let result = href;
    if (store.state.point.traceId &&
      store.state.point.sessionId &&
      store.state.point.browserId
    ) {
      const params = `traceId=${encodeURIComponent(store.state.point.traceId)}&sessionId=${encodeURIComponent(store.state.point.sessionId)}&browserId=${encodeURIComponent(store.state.point.browserId)}`;
      /* eslint-disable */
      result = `${result}${result.includes('?') ? '&' : '?'}${params}`;
      /* eslint-enable */
    }
    window.location.href = result;
  }
}

Vue.use(LotsRouter);
const router = new LotsRouter({
  strict: process.env.NODE_ENV !== 'production',
  mode: 'history',
  base: 'vue',
  routes: [{
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/home/home'),
  },
  {
    path: '/pay/unionJsPay',
    name: 'unionJsPay',
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/pay/unionJsPay'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/login/login'),
  },
  // app授权页面路由
  ...appAuthRouter,
  {
    path: '/bind/mobile',
    name: 'BindMobile',
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/login/bindMobile'),
  },
  {
    path: '/register',
    name: 'Register',
    meta: {
      keepAlive: true,
    },
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/login/register'),
  },
  {
    path: '/forgetPw',
    name: 'ForgetPw',
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/login/forgetPw'),
  },
  {
    path: '/reset/password',
    name: 'ResetPassword',
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/login/resetPassword'),
  },
  {
    path: '/reg/deal',
    name: 'RegDeal',
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/login/regDeal'),
  },
  {
    path: '/customFormSuccess',
    name: 'CustomFormSuccess',
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/customForm/customFormSuccess'),
  },
  {
    path: '/noLoginCustomFormSuccess',
    name: 'noLoginCustomFormSuccess',
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/customForm/noLoginCustomFormSuccess'),
  },
  {
    path: '/customFormDetail',
    name: 'CustomFormDetail',
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/customForm/customFormDetail'),
  },
  {
    path: '/customForm',
    name: 'CustomForm',
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/customForm'),
  },
  {
    path: '/custompage',
    name: 'Custompage',
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/custompage'),
  },
  {
    path: '/list/rmsRepast',
    name: 'ListRmsRepast',
    meta: {
      keepAlive: true,
      pageType: 'list',
      productType: 'rmsRepast',
    },
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/service/list/pmsRepastList'),
  },
  {
    path: '/detail/rmsRepast',
    name: 'DetailRmsRepast',
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/service/detail/pmsRepastDetail'),
  },
  {
    path: '/order/rmsRepast',
    name: 'OrderRmsRepast',
    meta: {
      productType: 'rmsRepast',
    },
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/service/order/pmsRepastOrder'),
  },
  {
    path: '/personal/balance',
    name: 'PersonalBalance',
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/personal/balance'),
  },
  {
    path: '/personal/balanceDetail',
    name: 'PersonalBalanceDetail',
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/personal/balance/balanceDetail'),
  },
  {
    path: '/cost/detail',
    name: 'CostDetail',
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/personal/balance/costDetail'),
  },
  {
    path: '/recharge/pay',
    name: 'RechargePay',
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/personal/balance/rechargePay'),
  },
  {
    path: '/member/recharge',
    name: 'MemberRecharge',
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/personal/balance/recharge'),
  },
  {
    path: '/list/ticket',
    name: 'ListTicket',
    meta: {
      keepAlive: true,
      pageType: 'list',
      productType: 'ticket',
    },
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/ticket/list'),
  },
  {
    path: '/list/hotel',
    name: 'ListHotel',
    meta: {
      keepAlive: true,
    },
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/service/list/hotelList'),
  },
  {
    path: '/list/pmsHotel',
    name: 'ListPmsHotel',
    meta: {
      keepAlive: true,
      pageType: 'list',
      productType: 'hotel',
    },
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/hotel/list'),
  },
  {
    path: '/list/hsHotel',
    name: 'ListhsHotel',
    meta: {
      // keepAlive: true,
      pageType: 'list',
      productType: 'hotel',
    },
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/service/list/hsHotel'),
  },
  {
    path: '/list/shop',
    name: 'ListMdse',
    meta: {
      keepAlive: true,
      pageType: 'list',
      productType: 'goods',
    },
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/goods/list'),
  },
  {
    path: '/list/repast',
    name: 'ListRepast',
    meta: {
      // keepAlive: true,
      pageType: 'list',
      productType: 'repast',
    },
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/service/list/repastList'),
  },
  {
    path: '/list/route',
    name: 'ListRoute',
    meta: {
      // keepAlive: true,
      pageType: 'list',
      productType: 'route',
    },
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/service/list/routeList'),
  },
  {
    path: '/list/strategy',
    name: 'ListStrategy',
    meta: {
      keepAlive: true,
      pageType: 'list',
      productType: 'strategy',
    },
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/service/list/strategyList'),
  },
  {
    path: '/list/theater',
    name: 'ListTheater',
    meta: {
      keepAlive: true,
      pageType: 'list',
      productType: 'theater',
    },
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/service/list/theaterList'),
  },
  {
    path: '/list/tickets',
    name: 'ListTickets',
    meta: {
      keepAlive: true,
      pageType: 'list',
      productType: 'tickets',
    },
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/service/list/ticketsList'),
  },
  {
    path: '/list/guide',
    name: 'ListGuide',
    meta: {
      keepAlive: true,
      pageType: 'list',
      productType: 'guide',
    },
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/service/list/guideList'),
  },
  {
    path: '/list/rentalCars',
    name: 'ListRentalCars',
    meta: {
      keepAlive: true,
      pageType: 'list',
      productType: 'rent_car',
    },
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/service/list/rentalCarsList'),
  },
  {
    path: '/list/comment/:mold/:id',
    name: 'ListComment',
    meta: {
      // keepAlive: true
    },
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/service/list/commentList'),
  }, {
    path: '/detail/ticketType',
    name: 'DetailTicketType',
    meta: {
      pagePoint: true,
    },
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/service/detail/ticketTypeDetail'),
  },
  {
    path: '/detail/buyMoreDiscount',
    name: 'DetailBuyMoreDiscount',
    meta: {
      // pagePoint: true, // 这里设置不可以 设置true 跳转报错 不显示页面
    },
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/service/detail/buyMoreDiscount'),
  },
  {
    path: '/detail/virtualTicket', // 虚拟票券
    name: 'DetailVirtualTicket',
    meta: {
      // pagePoint: true, // 这里设置不可以 设置true 跳转报错 不显示页面
    },
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/service/detail/virtualTicket'),
  },
  {
    path: '/detail/virtualTicketCheck', // 虚拟票券核销页
    name: 'DetailVirtualTicketCheck',
    meta: {
      // pagePoint: true, // 这里设置不可以 设置true 跳转报错 不显示页面
    },
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/service/detail/virtualTicketCheck'),
  },
  {
    path: '/detail/virtualTicketCheck/confirmCheck', // 虚拟票券确认核销页
    name: 'DetailVirtualTicketCheckConfirmCheck',
    meta: {
      // pagePoint: true, // 这里设置不可以 设置true 跳转报错 不显示页面
    },
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/service/detail/virtualTicketCheck/confirmCheck'),
  },
  {
    path: '/detail/ticket',
    name: 'DetailTicket',
    meta: {
      pagePoint: true,
    },
    component: () => {
      const pUrl = querystring.parseUrl(window.location.href);
      const mId = pUrl.query && pUrl.query.m_id ? pUrl.query.m_id : '';
      if (mId === '1841') {
        return import(/* webpackChunkName: "route-index" */ '@/pages/service/detail/oldTicketDetail');
      } else {
        return import(/* webpackChunkName: "route-index" */ '@/pages/service/detail/ticketDetail');
      }
    },
  }, {
    path: '/detail/tickets',
    name: 'DetailTickets',
    meta: {
      pagePoint: true,
    },
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/service/detail/ticketsDetail'),
  },
  {
    path: '/detail/guide',
    name: 'DetailGuide',
    meta: {
      pagePoint: true,
    },
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/service/detail/guideDetail'),
  }, {
    path: '/detail/rentalCars',
    name: 'DetailRentalCars',
    meta: {
      pagePoint: true,
      productType: 'rent_car',
    },
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/service/detail/rentalCarsDetail'),
  }, {
    path: '/detail/carPackage',
    name: 'DetailCarPackage',
    meta: {
      pagePoint: true,
      productType: 'rent_car',
    },
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/service/detail/selectPackageDetail'),
  }, {
    path: '/detail/theater',
    name: 'DetailTheater',
    meta: {
      pagePoint: true,
    },
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/service/detail/theaterDetail'),
  }, {
    path: '/detail/router',
    name: 'DetailRouter',
    meta: {
      pagePoint: true,
    },
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/service/detail/routerDetail'),
  }, {
    path: '/detail/repast',
    name: 'DetailTepast',
    meta: {
      pagePoint: true,
    },
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/service/detail/repastDetail'),
  }, {
    path: '/detail/eatery',
    name: 'DetailEatery',
    meta: {
      pagePoint: true,
    },
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/service/detail/eateryDetail'),
  }, {
    path: '/detail/mdse',
    name: 'DetailMdse',
    meta: {
      pagePoint: true,
    },
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/service/detail/mdseDetail'),
  },
  {
    path: '/detail/hotel',
    name: 'DetailHotel',
    meta: {
      pagePoint: true,
    },
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/service/detail/hotelDetail'),
  },
  {
    path: '/detail/pmsHotel',
    name: 'DetailPmsHotel',
    meta: {
      pagePoint: true,
    },
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/service/detail/pmsHotelDetail'),
  },
  {
    path: '/detail/pmsHotel/room-view',
    name: 'DetailPmsHotelRoomView',
    meta: {
      pagePoint: true,
    },
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/service/detail/room-view'),
  },
  {
    path: '/detail/strategy',
    name: 'DetailStrategy',
    meta: {
      productType: 'strategy',
    },
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/service/detail/strategyDetail'),
  },
  {
    path: '/detail/router/calendar',
    name: 'routerCalendar',
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/service/detail/router/calendar'),
  },
  {
    path: '/detail/shop',
    name: 'DetailShop',
    meta: {
      productType: 'shop',
    },
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/service/detail/shopDetail'),
  },
  {
    path: '/reviewDetail/shop', // 商品审核预览页面
    name: 'ReviewDetailShop',
    meta: {
      productType: 'shop',
    },
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/service/detail/shopReviewDetail'),
  },
  {
    path: '/order/ticket',
    name: 'OrderTicket',
    meta: {
      pagePoint: true,
    },
    component: () => {
      const pUrl = querystring.parseUrl(window.location.href);
      const mId = pUrl.query && pUrl.query.m_id ? pUrl.query.m_id : '';
      if (mId === '1841') {
        return import(/* webpackChunkName: "route-index" */ '@/pages/service/order/ticketOrderCbs');
      } else {
        return import(/* webpackChunkName: "route-index" */ '@/pages/service/order/ticketOrder');
      }
    },
  },
  {
    path: '/order/ticketNew',
    name: 'NewOrderTicket',
    meta: {
      pagePoint: true,
    },
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/service/order/newTicketOrder'),
  },
  {
    path: '/order/tickets',
    name: 'OrderTickets',
    meta: {
      pagePoint: true,
    },
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/service/order/ticketsOrder'),
  }, {
    path: '/order/guide',
    name: 'OrderGuide',
    meta: {
      pagePoint: true,
    },
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/service/order/guideOrder'),
  }, {
    path: '/order/rentalCars',
    name: 'OrderRentalCars',
    meta: {
      pagePoint: true,
      productType: 'rent_car',
    },
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/service/order/rentalCarsOrder'),
  }, {
    path: '/order/router',
    name: 'OrderRouter',
    meta: {
      pagePoint: true,
    },
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/service/order/routerOrder'),
  }, {
    path: '/order/repast',
    name: 'OrderTepast',
    meta: {
      pagePoint: true,
    },
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/service/order/repastOrder'),
  }, {
    path: '/order/mdse',
    name: 'OrderMdse',
    meta: {
      pagePoint: true,
    },
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/service/order/mdseOrder'),
  },
  {
    path: '/order/hotel',
    name: 'OrderHotel',
    meta: {
      pagePoint: true,
    },
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/service/order/hotelOrder'),
  },
  {
    path: '/order/pmsHotel',
    name: 'OrderPmsHotel',
    meta: {
      pagePoint: true,
    },
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/service/order/pmsHotelOrder'),
  }, {
    path: '/order/shop',
    name: 'OrderShop',
    meta: {
      pagePoint: true,
    },
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/service/order/shopOrder'),
  },
  {
    path: '/order/shop/noPurchaseList',
    name: 'OrderShopNoPurchaseList',
    meta: {
      pagePoint: true,
    },
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/service/order/shop/noPurchaseList'),
  },
  {
    path: '/pay/ticket',
    name: 'PayTicket',
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/service/pay/ticketPay'),
  }, {
    path: '/pay/theater',
    name: 'PayTheater',
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/service/pay/theaterPay'),
  }, {
    path: '/pay/router',
    name: 'PayRouter',
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/service/pay/routerPay'),
  }, {
    path: '/pay/repast',
    name: 'PayTepast',
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/service/pay/repastPay'),
  }, {
    path: '/pay/mdse',
    name: 'PayMdse',
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/service/pay/mdsePay'),
  },
  {
    path: '/pay/hotel',
    name: 'PayHotel',
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/service/pay/hotelPay'),
  },
  {
    path: '/detail/ticketNotice',
    name: 'DetailTicketNotice',
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/service/detail/ticket/notice'),
  },
  {
    path: '/detail/routerNotice',
    name: 'DetailRouterNotice',
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/service/detail/router/notice'),
  },
  {
    path: '/detail/theaterShow',
    name: 'DetailTheaterShow',
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/service/detail/theater/show'),
  },
  {
    path: '/detail/theaterSelect',
    name: 'DetailTheaterSelect',
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/service/detail/theater/select'),
  },
  {
    path: '/personal/receiveCoupon',
    name: 'PersonalReceiveCoupon',
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/personal/coupon/receiveCoupon'),
  },
  {
    path: '/personal/myCoupon',
    name: 'PersonalMyCoupon',
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/personal/coupon/myCoupon'),
  }, {
    path: '/personal/couponGit',
    name: 'PersonalcouponGit',
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/personal/coupon/couponGit'),
  },
  {
    path: '/yearcard',
    name: 'yearcard',
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/personal/yearcard/yearcard'),
  },
  {
    path: '/yearcardDetail',
    name: 'yearcardDetail',
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/personal/yearcard/yearcardDetail'),
  },
  {
    path: '/yearcardPay',
    name: 'yearcardPay',
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/personal/yearcard/yearcardPay'),
  },
  {
    path: '/yearcardRecord',
    name: 'yearcardRecord',
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/personal/yearcard/yearcardRecord'),
  },
  {
    path: '/parkCard/login',
    name: 'parkCardLogin',
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/parkCard/login'),
  },
  {
    path: '/parkCard/home',
    name: 'parkCardHome',
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/parkCard/home'),
  },
  {
    path: '/parkCard/list',
    name: 'parkCardList',
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/parkCard/list'),
  },
  {
    path: '/parkCard/detail',
    name: 'parkCardDetail',
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/parkCard/detail'),
  },
  {
    path: '/parkCard/saveOrder',
    name: 'parkCardSaveOrder',
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/parkCard/saveOrder'),
  },
  {
    path: '/parkCard/topay',
    name: 'parkCardTopay',
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/parkCard/topay'),
  },
  {
    path: '/parkCard/member',
    name: 'parkCardMember',
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/parkCard/member'),
  },
  {
    path: '/parkCard/order/list',
    name: 'parkCardOrderList',
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/parkCard/order/list'),
  },
  {
    path: '/parkCard/invoice/list',
    name: 'parkCardInvoiceList',
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/parkCard/invoice/list'),
  },
  {
    path: '/parkCard/invoice/detail',
    name: 'parkCardInvoiceDetail',
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/parkCard/invoice/detail'),
  },
  {
    path: '/parkCard/invoice/create',
    name: 'parkCardInvoiceCreate',
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/parkCard/invoice/create'),
  },
  {
    path: '/parkCard/order/detail',
    name: 'parkCardOrderDetail',
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/parkCard/order/detail'),
  },
  {
    path: '/parkCard/showQr',
    name: 'parkCardShowQr',
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/parkCard/qr/showQr'),
  },
  {
    path: '/parkCard/showQr/warning',
    name: 'parkCardShowQrWarning',
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/parkCard/qr/message'),
  },
  {
    path: '/parkCard/bindQr',
    name: 'parkCardBindQr',
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/parkCard/qr/bindQr'),
  },
  {
    path: '/recordDetail',
    name: 'recordDetail',
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/personal/yearcard/recordDetail'),
  },
  {
    path: '/invoiceList',
    name: 'invoiceList',
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/personal/invoice/invoiceList'),
  },
  {
    path: '/invoicePay',
    name: 'invoicePay',
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/personal/invoice/invoicePay'),
  },
  {
    path: '/invoiceDetail',
    name: 'invoiceDetail',
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/personal/invoice/invoiceDetail'),
  },
  {
    path: '/address/list',
    name: 'AddressList',
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/service/order/address/addressList'),
  },
  {
    path: '/address/mgt',
    name: 'AddressMgt',
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/service/order/address/addressMgt'),
  },
  {
    path: '/selfPlace',
    name: 'SelfPlace',
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/service/order/selfPlace/selfPlace'),
  },
  {
    path: '/shopping/cart',
    name: 'ShoppingCart',
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/shoppingCart/shoppingCart'),
  },
  {
    path: '/pay/result',
    name: 'PayResult',
    meta: {
      pageType: 'payResult',
    },
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/payResult'),
  },
  {
    path: '/addFace/search',
    name: 'addFaceSearch',
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/others/addFace/search'),
  },
  {
    path: '/addFace/result/list',
    name: 'addFaceSearchResult',
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/others/addFace/orderList'),
  },
  {
    path: '/addFace/result/allperson',
    name: 'addFaceSearchAllperson',
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/others/addFace/allperson'),
  },
  {
    path: '/addFace/result/person',
    name: 'addFaceSearchPerson',
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/others/addFace/singlePerson'),
  },
  {
    path: '/faceSupplement/search',
    name: 'faceSupplementSearch',
    component: r => require.ensure([], () => r(require('@/pages/others/faceSupplement/search')), 'faceSupplementSearch'),
  },
  {
    path: '/faceSupplement/result/list',
    name: 'faceSupplementResult',
    component: r => require.ensure([], () => r(require('@/pages/others/faceSupplement/orderList')), 'faceSupplementResult'),
  },
  {
    path: '/faceSupplement/result/allperson',
    name: 'faceSupplementAllperson',
    component: r => require.ensure([], () => r(require('@/pages/others/faceSupplement/allperson')), 'faceSupplementAllperson'),
  },
  {
    path: '/faceSupplement/result/person',
    name: 'faceSupplementSinglePerson',
    component: r => require.ensure([], () => r(require('@/pages/others/faceSupplement/singlePerson')), 'faceSupplementSinglePerson'),
  },
  {
    path: '/ticketQrCode/:id',
    name: 'ticketQrCode',
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/ticketQrCode'),
    meta: {
      title: '票型二维码',
    },
  },
  {
    path: '/order/nametheater',
    name: 'OrderNameTheater',
    meta: {
      pagePoint: true,
    },
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/service/order/theaterNameOrder'),
  },
  // 虚拟票券订单页
  {
    path: '/order/virtualTicketOrder',
    name: 'OrderVirtualTicket',
    meta: {
      pagePoint: true,
    },
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/service/order/virtualTicketOrder'),
  },
  {
    path: '/integralStore/list',
    name: 'integralStoreList',
    meta: {
      keepAlive: true,
      pageType: 'list',
      productType: '',
    },
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/others/integralStore/list'),
  },
  {
    path: '/integralStore/detail',
    name: 'integralStoreDetail',
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/others/integralStore/detail'),
  },
  {
    path: '/bonus/detail',
    name: 'bonusDetail',
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/personal/bonus'),
  },
  {
    path: '/bonus/rules',
    name: 'bonusRules',
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/personal/bonus/rules'),
  },
  {
    path: '/miniProgramOrder',
    name: 'miniProgramOrder',
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/miniProgramOrder'),
  },
  {
    path: '/miniProgramQyyx',
    name: 'miniProgramQyyx',
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/miniProgramQyyx'),
  },
  // 夏
  // 排队取号
  {
    path: '/queueNums',
    name: 'queueNums',
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/queueNums/index.vue'),
  },
  {
    path: '/queueNums/myNums',
    name: 'queueNumsMyNums',
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/queueNums/myNums.vue'),
  },
  {
    path: '/appraise/index',
    name: 'appraise',
    meta: {
      title: '评价列表',
    },
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/appraise/index.vue'),
  },
  {
    path: '/404',
    name: '404',
    component: () => import(/* webpackChunkName: "route-index" */ '@/pages/404'),
  },
  ...trafficRoutes,
  ...saasRouter,
  ...cartRoute,
  ...preSaleRoute,
  ...preSaleTheaterRoute,
  ...couponsRoute,
  ...memberRoute,
  ...orderRoute,
  ...changeSeatRoute,
  ...sqfxRoute,
  ...materialRoute,
  ...cloudCaterRoute,
  ...luckDrawRoute, // 抽奖路由
  ...virtualProductsRouter,
  ...ctgRoute,
  ...giftCardRouter,
  ...shopsRoute,
  ...productsRoute,
  ],
  scrollBehavior(to, from, savedPosition) {
    // console.log(savedPosition)
    if (savedPosition) {
      return savedPosition;
    } else {
      if (from.meta.keepAlive) {
        from.meta.savedPosition = document.body.scrollTop;
      }
      return {
        x: 0,
        y: to.meta.savedPosition || 0,
      };
    }
  },
});

router.beforeEach(async (to, from, next) => {
  const wqridx = to.query.wqridx || to.params.wqridx || from.query.wqridx || from.params.wqridx;
  if (wqridx) {
    // to.query.wqridx = wqridx;
    // to.params.wqridx = wqridx;
    // from.query.wqridx = wqridx;
    // from.params.wqridx = wqridx;
    sessionStorage.setItem('tmh-saveorder-wqridx', wqridx);
  }

  // 小程序跳转对应的页面
  if (miniProgram.toMiniProgramPage(to)) return;

  if (to.meta && to.meta.title) {
    document.title = to.meta.title;
  }
  // store.dispatch('updateLoadingStatus', {isLoading: true})
  if (to.matched && to.matched.length) {
    if (to.fullPath === '/404' || to.path === '/cloudCaterPayResult') {
      next();
    } else if (to.query.m_id) {
      // m_id存在多个的情况，例如http://localhost:8080/#/?m_id=78&m_id=78
      let { m_id } = to.query;
      if (m_id instanceof Array) {
        m_id = m_id[0];
      }
      localStorage.setItem('xjsc_vue_2018_12_19_mid', m_id);
      // 同步node端M_id,spreadCode,marketDisAccount
      let obj = {
        merchantInfoId: m_id,
        // 禁止加载弹框，后台不需要
        unLoading: true,
      };
      const spread_code = to.query.spread_code || to.query.promoteCode;
      if (spread_code) {
        // 存在cookie中
        VueCookies.set(`spread_code_${m_id}`, spread_code, '1d', null, window.location.hostname);
        VueCookies.set('promote_code', spread_code, '1d', null, window.location.hostname);
        const code = spread_code.split('-')[1];
        if (spread_code && code) {
          obj = Object.assign(obj, {
            spreadCode: spread_code,
            marketDisAccount: code,
          });
        }
      }
      // 处理query上的特殊参数
      await routeQueryCommand.execute(to.query);

      // 判断环境
      // const env = isWxAli();

      // 第三方授权，路径参数拦截,存储
      getParamsFromAppPath({ to });
      // 微信或者阿里的时候
      const appAuthItem = getAppAuthConfig();
      if (appAuthItem?.interceptToken || miniProgram.isMP()) {
        // 微信、支付宝、云闪付h5、农行、小程序
        if (to.query.token) {
          // 清除旧的token和leaguerInfoId
          const keys = VueCookies.keys();
          keys && keys.forEach(item => {
            if (item.includes('token') || item.includes('leaguerInfoId')) {
              VueCookies.remove(item, null, window.location.hostname);
            }
          });
          try {
            const data = await axios.get(`${window.location.protocol}//${window.location.host}/merchant/api/node/changeInfo`, {
              params: {
                token: 'clear',
                logs: JSON.stringify(keys),
                merchantInfoId: m_id,
              },
            });
            console.log('data', data);
          } catch (err) {
            console.log(err);
          }
          const token = to.query.token instanceof Array
            ? to.query.token[to.query.token.length - 1]
            : to.query.token;

          localStorage.setItem(
            'xjsc_login_access_token_2019_3_7',
            token,
          );
          // 兼容小程序
          // VueCookies.set('token', token, '8h', null, window.location.hostname);
          // 区分小程序与h5账号
          if (miniProgram.isMP()) {
            VueCookies.set(`mini_token_${to.query.m_id}`, token, '8h', null, window.location.hostname);
            localStorage.setItem('token_type', 'mini');
          } else {
            VueCookies.set(`token_${to.query.m_id}`, token, '8h', null, window.location.hostname);
          }

          obj = Object.assign(obj, {
            token,
          });
        }
        if (to.query.leaguerInfoId) {
          const leaguerId = to.query.leaguerInfoId instanceof Array
            ? to.query.leaguerInfoId[to.query.leaguerInfoId.length - 1]
            : to.query.leaguerInfoId;
          localStorage.setItem(
            'xjsc_login_userId_2019_3_8',
            leaguerId,
          );
          VueCookies.set(`leaguerInfoId_${to.query.m_id}`, leaguerId, '8h', null, window.location.hostname);
          VueCookies.set('leaguerInfoId', leaguerId, '8h', null, window.location.hostname);

          obj = Object.assign(obj, {
            leaguerId,
          });
        }
      }

      try {
        await axios.get(`${window.location.protocol}//${window.location.host}/merchant/api/node/changeInfo`, {
          params: obj,
        });
      } catch (err) {
        console.log(err);
      }

      if (/^\/saas/.test(to.path)) {
        // 获取图片前缀
        let publicBaseXJImgUrl = sessionStorage.getItem('public-base-XJ-img-url');
        if (!publicBaseXJImgUrl) {
          try {
            const res = (await getXJBaseImg()).data;
            publicBaseXJImgUrl = res.publicBucketUrl;
            sessionStorage.setItem('public-base-XJ-img-url', publicBaseXJImgUrl.substr(0, publicBaseXJImgUrl.length - 1));
          } catch (error) {
            console.error('saas 年卡 获取图片前缀出错', error);
          }
        }

        if (!to.path.startsWith('/saas/yearcard/buy/list')) {
          // saas 年卡页校验登陆
          try {
            const saas_local = JSON.parse(localStorage.getItem('saas_info')) || {};
            const saas_token = saas_local.token || store.getters['saas/GET_TOKEN']; //  防止刷新过快拿不到token
            const saas_phone = saas_local.phone || store.getters['saas/GET_PHONE'];
            if (!saas_token || !saas_phone) await store.dispatch('saas/checkLogin');
          } catch (error) {
            console.error('saas 年卡 校验登陆出错', error);
          }
        }
      }

      if (process.env.NODE_ENV === 'production') {
        // 处理微信H5分享只能分享首页的问题
        // 原因ios系统对于spa单页面应用的路径变化无法监听
        // 生产环境判断是不是ios环境iso环境路由跳转的时候用location.assign处理
        const agent = navigator.userAgent;
        // 是否是iso系统
        const isiOS = !!agent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
        // 是否是微信浏览器
        const isWx = /MicroMessenger/.test(window.navigator.userAgent);
        console.log(isiOS);
        console.log(isWx);
        // ios微信浏览器端
        if (isWx && isiOS && ((`/vue${to.path}`) !== global.location.pathname)) {
          console.log(1);
          if (to.query.token || to.query.leaguerInfoId) {
            delete to.query.token;
            delete to.query.leaguerInfoId;
            console.log(3);
            console.log(querystring.stringify(to.query));
            location.assign(`/vue${to.path}?${querystring.stringify(to.query)}`);
          } else {
            location.assign(`/vue${to.fullPath}`);
          }
        } else if (miniProgram.isMP()) {
          next();
        } else if (isWx && isiOS) {
          console.log(4);
          if (to.query.token || to.query.leaguerInfoId) {
            delete to.query.token;
            delete to.query.leaguerInfoId;
            console.log(5);
            location.assign(`/vue${to.path}?${querystring.stringify(to.query)}`);
          } else {
            next();
            // location.assign('/vue' + to.fullPath)
          }
        } else {
          console.log(22);
          if (to.query.token || to.query.leaguerInfoId) {
            // 如果有token值，删除参数token和leaguerInfoId后，replace当前路由
            delete to.query.token;
            delete to.query.leaguerInfoId;
            next({
              ...to,
              replace: true,
            });
          } else {
            next();
          }
        }
      } else if (to.query.token || to.query.leaguerInfoId) {
        // 如果有token值，删除参数token和leaguerInfoId后，replace当前路由
        delete to.query.token;
        delete to.query.leaguerInfoId;
        next({
          ...to,
          replace: true,
        });
      } else {
        next();
      }
      // next()
    } else if (from.query.m_id) {
      // m_id存在多个的情况，例如http://localhost:8080/#/?m_id=78&m_id=78
      let { m_id } = from.query;
      if (m_id instanceof Array) {
        m_id = m_id[0];
      }
      to.query.m_id = m_id;
      next({
        ...to,
      });
    } else {
      next('/404');
    }
  } else {
    next('/404');
  }
});

// 解决Loading chunk (\d)+ failed问题
router.onError((error) => {
  const pattern = /Loading chunk (\d)+ failed/g;
  const isChunkLoadFailed = error.message.match(pattern);
  console.log(error.message);
  if (isChunkLoadFailed) {
    // 用路由的replace方法，并没有相当于F5刷新页面，失败的js文件并没有从新请求，会导致一直尝试replace页面导致死循环，而用 location.reload 方法，相当于触发F5刷新页面，虽然用户体验上来说会有刷新加载察觉，但不会导致页面卡死及死循环，从而曲线救国解决该问题
    // location.reload();
    const targetPath = router.history.pending.fullPath;
    console.log(targetPath);
    router.replace(targetPath);
  }
});

export default router;
