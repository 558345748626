/**
 * 个人中心路由
 */
const memberRoute = [{
  path: '/personal',
  name: 'Personal',
  component: () => import(/* webpackChunkName: "router-member" */ '@/pages/personal/personal'),
  alias: '/member',
  meta: {
    title: '个人中心',
  },
},
{
  path: '/personal/contacts',
  name: 'PersonalContacts',
  component: () => import(/* webpackChunkName: "router-member" */ '@/pages/personal/contacts/contacts'),
},
{
  path: '/personal/contactsAdd',
  name: 'PersonalContactsAdd',
  component: () => import(/* webpackChunkName: "router-member" */ '@/pages/personal/contacts/contactsAdd'),
},
{
  path: '/personal/addressManage',
  name: 'PersonalAddressManage',
  component: () => import(/* webpackChunkName: "router-member" */ '@/pages/personal/addressManage/addressManage'),
},
{
  path: '/personal/userInfo',
  name: 'PersonalUserInfo',
  component: () => import(/* webpackChunkName: "router-member" */ '@/pages/personal/user/userInfo'),
},
{
  path: '/personal/editInfo',
  name: 'PersonalEditInfo',
  component: () => import(/* webpackChunkName: "router-member" */ '@/pages/personal/user/editInfo'),
},
{
  path: '/changePassword',
  name: 'changePassword',
  component: () => import(/* webpackChunkName: "router-member" */ '@/pages/personal/user/changePassword'),
},
{
  path: '/collectList',
  name: 'CollectList',
  component: () => import(/* webpackChunkName: "route-member" */ '@/pages/personal/collect'),
},
{
  path: '/personal/giftCardDetail',
  name: 'GiftCardDetail',
  component: () => import(/* webpackChunkName: "route-member" */ '@/pages/personal/giftCard/giftCardDetail'),
},
{
  path: '/personal/cardNoticeUse',
  name: 'CardNoticeUse',
  component: () => import(/* webpackChunkName: "route-member" */ '@/pages/personal/giftCard/cardNoticeForUse'),
},
{
  path: '/member/memberCenter/agreement',
  name: 'MemberAgreement',
  component: () => import(/* webpackChunkName: "route-member" */ '@/pages/member/memberCenter/agreement'),
  meta: {
    title: '会员服务协议',
  },
},
{
  path: '/member/memberDetail/rules',
  name: 'MemberRules',
  component: () => import(/* webpackChunkName: "route-member" */ '@/pages/member/memberDetail/rules'),
},
{
  path: '/member/privilege',
  name: 'Privilege',
  component: () => import(/* webpackChunkName: "route-member" */ '@/pages/member/privilege'),
},
{
  path: '/member/payResult',
  name: 'PayResult',
  component: () => import(/* webpackChunkName: "route-member" */ '@/pages/member/payResult'),
},
{
  path: '/member/privilege/record',
  name: 'memberRecord',
  component: () => import(/* webpackChunkName: "route-member" */ '@/pages/member/privilege/record'),
},
{
  path: '/member/memberInfo',
  name: 'MemberInfo',
  component: () => import(/* webpackChunkName: "route-member" */ '@/pages/member/memberInfo'),
},
{
  path: '/member/memberCenter',
  name: 'MemberCenter',
  component: () => import(/* webpackChunkName: "route-member" */ '@/pages/member/memberCenter'),
  meta: {
    isBack: false,
  },
},
{
  path: '/member/memberRegister',
  name: 'memberRegister',
  component: () => import(/* webpackChunkName: "route-member" */ '@/pages/member/memberRegister'),
  meta: {
    title: '个人会员注册页',
  },
},
{
  path: '/member/memberRegister',
  name: 'memberRegister',
  component: () => import(/* webpackChunkName: "route-member" */ '@/pages/member/memberRegister'),
  meta: {
    title: '个人会员注册页',
  },
},
];

export default memberRoute;
