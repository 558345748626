// 素材相关路由
const materialRoute = [
  {
    path: '/material/album',
    name: 'materialAlbum',
    component: () => import('@/pages/material/album'),
  },
];

export default materialRoute;
