// 全员营销接口
import api from './api';
import { getAxios, jsonAxios, postAxios, jsonAxiosDel } from './axios';
import { sqfxBaseUrl } from '@/common/config';

// 独立部署根据用户id获取全员用户信息
const getQyyxToken = (params = {}) => {
  return jsonAxios('/web-platform/checkThirdInterfaceService/queryUserInfoByThirdParam?source=qyyx', params, sqfxBaseUrl,true);
};

// 获取当前部署环境信息
const getDeployInfo = () => {
  return getAxios('/merchant/api/merchantWholeMarket/aloneDeploySwitch');
};

/**
 * 绑定分销商
 */
const bindResellerAuth = (params = {}) => {
  return postAxios('/leaguer/api/promoter/bindResellerAuth', params);
};

/**
 * 获取推广员注册配置
 */
const queryPromoterRegisterConfig = (params = {}) => {
  return jsonAxios('/sendinfo-promotion/cPersonalService/promoterRegisterConfig', params, sqfxBaseUrl);
};

const getMarketWholeMarketShareConfig = (params = {}) => {
  return postAxios('/merchant/api/merchantWholeMarket/getMarketWholeMarketShareConfig', params);
};

// const queryPromoteQrCodeType = (params = {}) => {
//   return jsonAxios('/sendinfo-promotion/cPersonalService/queryPromoteQrCodeType', params, sqfxBaseUrl);
// };
/**
 * 查询推广员产品推广列表
 * @param {*Number} promoterType 推广员类型：0-直客，1-分销商
 * @param {*Number} accType  2-分销商，3-推广员
 */
const queryProductByCache = ({ promoterType = 0, accType = 3 }, params = {}) => {
  const {
    queryDistributionPromoterProductByCache,
    queryProduct,
    queryDistributorOptionalProduct,
  } = api.sqfx;
  // eslint-disable-next-line no-nested-ternary
  const url = accType === 2 ? queryDistributorOptionalProduct : (promoterType
    ? queryDistributionPromoterProductByCache
    : queryProduct);
  return jsonAxiosDel(url, params, sqfxBaseUrl);
};


// const checkUserPromoter = (params = {}) => {
//   const { checkUserPromoter } = api.sqfx;
//   return postAxios(checkUserPromoter, params, baseUrl);
// };

const sqfx = Object.keys(api.sqfx).reduce((total, key) => {
  const path = api.sqfx[key];
  if (path.includes('sendinfo-promotion')) {
    total[key] = (params = {}) => {
      return jsonAxiosDel(path, params, sqfxBaseUrl);
    };
  } else {
    total[key] = (params = {}) => {
      return postAxios(path, params);
    };
  }
  return total;
}, {});

export default {
  ...sqfx,
  getQyyxToken,
  getDeployInfo,
  getMarketWholeMarketShareConfig,
  queryProductByCache,
  bindResellerAuth,
  queryPromoterRegisterConfig,
  // queryPromoteQrCodeType,
};
