// RSA加密
import JSEncrypt from 'jsencrypt';
import sha256 from 'hash.js/lib/hash/sha/256';
import sortObject from 'sort-object';

class wxEncrypt {
  constructor() {
    this.pubsKey = `
      -----BEGIN PUBLIC KEY-----
      MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDI5kmdW9rmxQlTraZ6Wx+C7kYR
      DjL332N53dsqFsqqT+PX6mosaM5hW4MS4vUbQNtUblhmQgzmUD0KBJFH9YZMmSPL
      dCC+G4r7d2PPFBrCy+9dFUMUNaT4PenyaVQJyAp3bhUJ7n93bRJT9jtxsdDwJ/Zl
      12A/kO93fr7+waaepwIDAQAB
      -----END PUBLIC KEY-----`;
    this.privateKey = `
      -----BEGIN PRIVATE KEY-----
      MIICdwIBADANBgkqhkiG9w0BAQEFAASCAmEwggJdAgEAAoGBAIvTT4CNu+WPelfl9h8MqvA4Bl0ir++U15pcC2MLK2NdRojD1dPsao515rBL0Oa8H5p/re6iDuF575ev7I/I04kGjrINuwpXnORhv62Opb160OXN6wfVV+cYhyY9yHkseKueRqkfzLJf7BDUuqqMjG39Ffb43d0TYvSIosq0lSpTAgMBAAECgYADV98bxMfMWlzjgyUaYvQnYD+OtIieYuGw2CiRiq/T5WQNWxlewtVdaKZGatmo2rCqwgjHnupaK881wkgWvAeKwyMI+k0C1+qAqvxEMlPkP9ed4SUd3kpWFmLt7KB5fcDVXFl9Ak9mV2nO2Kctho5swAnSvr0n5Ig3+p3+mdZVCQJBAMpByAdTOkLiXxFhYemVFJIl96XSM4Ne6GJYAoJt/AscyuzmT9SpDiIrpROz6mQMpr3YWGBDeAcFxC1iABPf3FUCQQCw+rh4HM16nVh4kWaA8i3FkKxbjxtRxp2NVsmf15xL8f7i5ugcgxs0akhkEK/dW3XQOBmvU+EYwwxHU4sawZQHAkEAofn+fMdNgGUUJ7IqnMtUlxqq8vSdZpcqWiALmnMEh82+QezMgA4HkJwNbvMGQqvTGXTaifVeeZlHm+TBFSmRpQJAaVg0KbrrTIsQOGREIYkGWc0t6yoZ1ulkqtb4GR8cN9CKKcHMc/xOWzrQcE/GfvqL/C8IiuZg3H5tdp9x87E6TQJBAK+cY731g+eKWXd5gNDBBJPPMwgchubKvXb4ITrZqwhb0mUPRn4Gmjm35D/KErClsbBSaNcxz/Tn1JmurPX6Bao=
      -----END PRIVATE KEY-----`;
  }

  /**
   * 生产验签
   * @param {Object} params 请求参数
   * @param {Boolean} isJson 是否为json类型请求
   */
  setSign(params, isJson) {
    const salt = this.random(15);
    const paramsStr = this.signInitData(params, isJson);
    const sign = this.signData(paramsStr, salt);
    const sk = this.setRSA(salt);
    return {
      sk,
      sign,
    };
  }

  /**
   * RSAj加密
   * @param {String} text 需要加密的文本
   * @returns {String} 加密后的文本
   */
  setRSA(text) {
    // 新建JSEncrypt对象
    const encryptor = new JSEncrypt();
    // 设置公钥
    encryptor.setPublicKey(this.pubsKey);
    // 加密数据
    const resultText = encryptor.encrypt(text);
    // 转码
    return encodeURIComponent(resultText);
  }

  /**
   * RSAj解密
   * @param {String} text 需要解密的文本
   * @returns {String} 解密后的文本
   */
  getRSA(text) {
    // 新建JSEncrypt对象
    const decryptor = new JSEncrypt();
    // 设置公钥
    decryptor.setPrivateKey(this.privateKey);
    // 解码
    const ducText = decodeURIComponent(text);
    // debugger;
    // 解密数据
    const resultText = decryptor.decrypt(ducText);
    // 返回
    return resultText;
  }

  /**
   * 预处理需要生成验签的数据
   * @param {Object} params 需要处理的数据
   * @param {Boolean} isJson 加密数据的类型
   */
  signInitData(params, isJson) {
    let signString;
    if (!isJson) {
      const keys = Object.keys(params).sort();
      const queryArray = [];
      keys.forEach(key => {
        if (typeof params[key] === 'boolean' || params[key] || params[key] === 0) {
          queryArray.push(`${key }=${ params[key]}`);
        }
      });
      signString = queryArray.join('&');
    } else {
      const newParams = sortObject(params);
      signString = JSON.stringify(newParams);
    }
    return signString;
  }

  /**
   * 按照约定规则生成验签
   * @param {String} data 需要加密的数据
   * @param {String} salt 盐值
   */
  signData(data, salt) {
    const once = sha256().update(data).digest('hex');
    const seconed = sha256().update(once + salt).digest('hex');
    return seconed;
  }

  /**
   * 随机N位字母数字的字符串
   * @param {number} n 字符串长度
   * @returns {string} n位字符串
   */
  random(n) {
    let res = '';
    // eslint-disable-next-line no-empty
    for (; res.length < n; res += Math.random().toString(36).substr(2).toUpperCase()) {}
    return res.substr(0, n);
  }
}

// eslint-disable-next-line new-cap
export default new wxEncrypt();
