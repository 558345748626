/*
 * @Author: mikey.qiaoyang
 * @Date: 2019-09-19 11:39:15
 * @Last Modified by: mikey.zhaopeng
 * @Last Modified time: 2021-02-22 15:18:23
 */

// 添加地图的页面需用import防止报错 TypeError: v.w.uh is not a constructor
import home from '@/pages/traffic/home.vue';
import map from '@/pages/traffic/map.vue';

export default [{
  path: '/traffic_home',
  name: 'TrafficHome',
  component: home,
}, {
  path: '/traffic_route',
  name: 'TrafficRoute',
  component: () => import(/* webpackChunkName: "route-traffic" */ '@/pages/traffic/route-list.vue'),
}, {
  path: '/changeRoute',
  name: 'ChangeRoute',
  component: () => import(/* webpackChunkName: "route-traffic" */ '@/pages/traffic/changeRoute.vue'),
}, {
  path: '/traffic_detail',
  name: 'TrafficDetail',
  component: () => import(/* webpackChunkName: "route-traffic" */ '@/pages/traffic/route-detail.vue'),
}, {
  path: '/traffic_schedule',
  name: 'TrafficSchedule',
  component: () => import(/* webpackChunkName: "route-traffic" */ '@/pages/traffic/schedule.vue'),
}, {
  path: '/traffic_order',
  name: 'TrafficOrder',
  component: () => import(/* webpackChunkName: "route-traffic" */ '@/pages/traffic/order.vue'),
}, {
  path: '/traffic_success',
  name: 'TrafficSuccess',
  component: () => import(/* webpackChunkName: "route-traffic" */ '@/pages/traffic/pay-result.vue'),
}, {
  path: '/traffic_qrcode',
  name: 'TrafficQrcode',
  component: () => import(/* webpackChunkName: "route-traffic" */ '@/pages/traffic/qrcode.vue'),
},
{
  path: '/traffic_map',
  name: 'TrafficMap',
  // component: resolve => require(['@/views/visitor/map.vue'], resolve)
  component: map,
},
];
