<template>
  <div class="">
    <popup v-model="isShow"
            height="100%"
           @on-hide="close">
      <div class="site-wrap">
        <div class="site_search">
          <span class="site_back" @click='close'><</span>
          <i @click="search" class="xx-icon icon-ico-search search-icon"></i>
          <input v-model="searchword" class="search-input" placeholder="搜索" type="text" @keyup.enter="search">
        </div>
        <div class="site_list_warp" ref="site">
          <div class="site_list_group" :ref="'site'+key" v-for="(val, key) in siteObj" :key="key">
            <div class="site_class">{{key}}</div>
            <div class="site_item" @click="siteItem(item)" v-for="(item, index) in val" :key="index">{{item.siteAliasName}}</div>
          </div>
        </div>
        <div class="class_list">
          <div class="class_item" @click="goClass(item)" v-for="(item, index) in classList" :key="index">{{item}}</div>
        </div>
      </div>
    </popup>
  </div>
</template>
<script>
import { Popup } from "vux";

export default {
  data () {
    return {
      searchword: '',
      classList: [],
      siteObj: {
        // "C":
        // [{"deleted":"F","routeCode":"L01200629153102241803","siteCode":"S200629153102262986","siteAliasName":"翠苑家居","siteSerial":2,"firstLetter":"C"}],
      },
    };
  },
  watch: {
    isShow(val) {
      if(val){
        this.searchword = '';
        this.search();
      }
    },
  },
  mounted() {
    let arr = [];
    for(var i = 65; i < 91; i++){
        arr.push(String.fromCharCode(i));
    }
    this.classList = arr;
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    params: {
      type: Object,
      default: () => ({}),
    },
    url: {
      type: String,
      default: '',
    },
  },
  components: { Popup },
  methods: {
    siteItem (item) {
      this.$emit('siteItem', item);
    },
    goClass (v) {
      if(this.siteObj[v]){
        this.$refs.site.scrollTo(0,this.$refs['site'+v][0].offsetTop);
      }
    },
    search () {
      this.getAxios(this.url, {
        ...this.params,
        siteName: this.searchword,
      }).then(res => {
        this.siteObj = res.data;
      });
    },
    close () {
      this.$emit('closeSearch');
    }
  }
};
</script>
<style lang='scss' scoped>
.site-wrap {
  background-color: #fff;
  height: 100%;
  position: relative;
}
.class_list {
  line-height: 20px;
  font-size: 12px;
  color: #999999;
  position: absolute;
  right: 10px;
  top: 70px;
}
.site_search {
  line-height: 50px;
  font-size: 16px;
  position: relative;
  border-bottom: 1px solid #ccc;
  .site_back {
    padding: 0px 10px;
  }
}
.site_list_warp {
  position: absolute;
  padding-left: 15px;
  top: 50px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 500px;
  overflow: auto;
  line-height: 32px;
  font-size: 14px;
  color: #333;
  .site_class {
    font-size: 18px;
    line-height: 18px;
    padding: 20px 0px 3px;
    color: #999;
  }
}
.site_item {
  font-size: 14px;
  line-height: 38px;
  border-bottom: 1px solid #EBEBEB;
}
.search-icon {
  position: absolute;
  font-size: 18px;
  left: 35.5px;
  color: #c4c4c4;
}
.search-input {
  padding: 0 5px;
  outline: none;
  border: none;
  width: calc(100% - 45px);
  height: 34px;
  text-align: center;
  border-radius: 2px;
  border-left: 1px solid #eeeee7;
}
::-webkit-input-placeholder {
  text-align: center;
}
:-moz-placeholder {
  /* Firefox 18- */
  text-align: center;
  color: #999;
  font-size: 13px;
}
::-moz-placeholder {
  text-align: center;
  /* Firefox 19+ */
  color: #999;
  font-size: 13px;
}
:-ms-input-placeholder {
  text-align: center;
  color: #999;
  font-size: 13px;
}
</style>