import { postAxios, getAxios } from './axios';
import store from '@/store';

function getAddressInfo(params) {
  const url = '/merchant/api/destinationConfigApi/getByAreaCodes';
  return postAxios(url, params).then(res => {
    return res.data;
  });
}

function getLocation(id) {
  const url = '/merchant/api/destinationConfigApi/getById';
  return postAxios(url, { id }).then(res => {
    return res.data;
  });
}
/**
 * 获取列表页展示地点筛选的状态
 * @param {string} type
 * hotelSelectPageType-酒店业态；
 * parkSelectPageType-景区业态；
 * routeSelectPageType-跟团游；
 * eaterySelectPageType-餐饮
 */
function getLocationFilterStatus(type) {
  const url = '/merchant/api/merchantRenovationToc/selectRenovationPage';
  return getAxios(url, {
    pageType: type,
  });
}
/**
 * 根据id获取套票信息
 * @param {*} id 套票id
 * @param {*} date 日期
 */
function getTicketsInfoById(id, date) {
  const url = '/merchant/api/merchantFamilyInfo/familyDetailInfoList';
  console.log(store.state.mid);
  return postAxios(url, {
    merchantFamilyTicketId: id,
    dateStr: date,
    merchantInfoId: store.state.mid,
  }).then(res => {
    if (res.status === 200) {
      // 下单参数使用park所以这里ticket转为park
      return res.data.map(item => {
        if (item.modelType === 'ticket') {
          return Object.assign(item, { modelType: 'park' });
        } else {
          return item;
        }
      });
    }
    return [];
  });
}
/**
 * 根据id获取套票限购数量
 * @param {*} id 套票id
 */
function getTicketsLimitById(id) {
  const url = '/merchant/api/merchantFamilyInfo/familyTicketInfoDetail';
  return postAxios(url, {
    merchantFamilyTicketId: id,
    merchantInfoId: store.state.mid,
  }).then(res => {
    if (res.status === 200) {
      return res.data;
    }
  });
}
/**
 * 获取店铺是否支持识别身份证
 */
function getIdCardServerStatus() {
  const url = '/marketing/api/marketBaiduService/idCardServiceEnable';
  return postAxios(url, {
    merchantId: store.state.mid,
  }).then(res => {
    return res.data.enable;
  });
}
/**
 * 获取店铺灰度状态
 */
function getStoreStatus(type, defaultValue = 'F') {
  const url = '/merchant/api/merchant/config/get';
  return getAxios(url, {
    configType: type,
    merchantInfoId: store.state.mid,
    defaultValue,
  }).then(res => {
    return res.data;
  });
}
/**
 * 获取景区信息
 */
function getParkInfo(merchantParkInfoId, wayType = 1) {
  const url = '/merchant/api/merchantParkInfo/merchantParkInfo';
  return getAxios(url, {
    merchantParkInfoId,
    wayType,
  }).then(res => {
    return res.data;
  });
}
/**
 * 获取套票信息
 */
function getTicketsInfo(merchantFamilyInfoId) {
  const url = '/merchant/api/merchantFamilyInfo/familyDetail';
  return postAxios(url, {
    merchantFamilyInfoId,
    merchantInfoId: store.state.mid,
  }).then(res => {
    return res.data;
  });
}
/**
 *景区标签(山西文旅)
 * @param {}
 */
function getListlabel() {
  const url = '/merchant/api/merchantParkInfo/getParkLabel';
  return getAxios(url, { merchantInfoId: store.state.mid });
}
/**
 * 查询自定义页面导航栏信息
 * @param {*} id 自定义页id
 */
function getCustomPageNav(id) {
  const url = '/merchant/api/merchantRenovationToc/getCustomNaviPage';
  return getAxios(url, {
    pageId: id,
  }).then(res => {
    return res.data || [];
  });
}
/**
 * 查询页面导航栏信息
 * @param {*} routeName 页面路由名
 */
function getPageNav(routeName) {
  const url = '/merchant/api/merchantRenovationToc/getSysNaviPage';
  return getAxios(url, {
    routeName,
  }).then(res => {
    return res.data || [];
  });
}
export {
  getTicketsInfo,
  getParkInfo,
  getStoreStatus,
  getIdCardServerStatus,
  getTicketsLimitById,
  getTicketsInfoById,
  getAddressInfo,
  getLocation,
  getLocationFilterStatus,
  getListlabel,
  getCustomPageNav,
  getPageNav,
};
