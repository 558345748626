/**
 * 中旅修改会员
 */
export default [
  {
    path: '/ctgMember',
    name: 'ctgMember',
    component: () => import(/* webpackChunkName: "route-ctg" */ '@/pages/ctgMember/index'),
    meta: {
      title: '会员信息',
    },
  }, {
    path: '/ctgMember/modify',
    name: 'ctgModify',
    component: () => import(/* webpackChunkName: "route-ctg" */ '@/pages/ctgMember/modify'),
    meta: {
      title: '更改信息',
    },
  },
];
