const theater = {
  namespaced: true,
  state: {
    cache: sessionStorage.getItem('theaterCache') ? JSON.parse(sessionStorage.getItem('theaterCache')) : {},
    lockStcokData: sessionStorage.getItem('lockStcokData') ? JSON.parse(sessionStorage.getItem('lockStcokData')) : {},
  },
  getters: {
    getCache(state) {
      return state.cache;
    },
    getLockStcokData(state) {
      return state.lockStcokData;
    },
  },
  mutations: {
    setCache(state, items) {
      const sta = state;
      sessionStorage.setItem('theaterCache', JSON.stringify(items));
      sta.cache = items;
    },
    setLockStcokData(state, items) {
      sessionStorage.setItem('lockStcokData', JSON.stringify(items));
      state.lockStcokData = items;
    },
  },
};

export default theater;
