import Vue from 'vue';

const vm = new Vue();

/* ---------------------------------  用户  --------------------------------- */

/**
 * 获取小鲸用户信息
 */
export const getUserInfo = () => vm.getAxios('/leaguer/api/userLeaguer/manage/leaguerInfo');


/* ---------------------------------  会员  --------------------------------- */
/**
 * 获取会员价
 * @param {String} codePrice code与价格拼接字符,多个逗号分隔  eg:spec4645444634:0.01,spec564654646445:0.02
 */
export const getDiscount = codePrice => vm.postAxios('/leaguer/api/leaguer/pubs/product/discountForMdse', {
  codePrice,
});


export const getMemberInfo = () => vm.getAxios('up/api/wap/home');

/**
 *
 * @param {*} params
    nickName string 名称
    headImg string 头像
    gender int 性别 1:男,2:女
    idCard string 身份证
    email
 * @returns
 */
export const updateMemberInfo = params => vm.postAxios('up/api/wap/updateMember', params);
