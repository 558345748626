export default {
  host: 'http://testwww.lotsmall.cn',
  aiPapi: {
    getList: '/marketing/api/pat/selectProductList',
    imgUp: '/admin/api/ui/uplode', // 上传图片
    imgDistinguish: '/marketing/api/pat/parkDistinguish', // 图片识别
    imgDetail: '/marketing/api/pat/getProductDetil', // 图片相关信息
    getRecommendList: '/marketing/api/patRecommend/getRecommendList', // 获取推荐景点信息
    getRenovationById: '/merchant/api/merchantRenovationToc/getRenovationById', // 自定义页面数据
    previewPage: '/merchant/api/merchantRenovationToc/previewPage', // 预览不保存的自定义页面数据
    getUsePage: '/merchant/api/merchantRenovationToc/getUsePage', // 自定义页面数据
    getUsePageNewLayer: '/merchant/api/ad/query', // 自定义页面新弹窗组件
    getSystemPage: '/merchant/api/customPage/system/get',
    oldTemplatePage: '/merchant/api/merchantInfo/getMerchantTemplateCustom', // 固定模板||老版自定义页面
    selectServiceUse: '/marketing/api/market/selectServiceUse', // 服务是否过期
    aibeeFace: '/admin/api/aibeeFace/getQuality', // 艾比人脸识别
    uplodeBase64: '/admin/api/ui/uplodeBase64', // 上传base64图片
  },
  main: {
    getAdress: {
      all: '/api/area/selectRrovince',
    },
    findPicInfo: '/merchant/api/merchantAdvManage/findPicInfo', // 首页banner图
    merchant: {
      updateMerchantInfo: '/merchant/api/merchantInfo/doUpdateMerchantInfo',
      listMerchantPushInfo: '/merchant/api/merchantInfo/listMerchantPushInfo', // 精品推荐
      getMerchantInfoByCode: '/merchant/api/merchantInfo/getMerchantInfoByCode',
      getMerchantInfoByUserInfoId: '/merchant/api/merchantInfo/getMerchantInfoByUserInfoId',
      getCorpCode: '/merchant/api/merchantWholeMarket/getMerchantWholeMarket',
      getMerchantTemplateCode: '/merchant/api/merchantInfo/getMerchantTemplateCode',
      getMerchantInfo: '/merchant/api/merchantInfo/getMerchantInfo', // 店铺信息
      findPayPageAd: '/merchant/api/merchantAdvManage/findPayPageAd',
      getSearchType: '/merchant/api/merchantInfo/getSearchType', // 获取店铺可搜索类型
      getSearchResult: '/merchant/api/merchantInfo/getSearchResult', // 获取店铺搜索结果
      gotoPublic: '/merchant/api/merchantInfo/getUserNameByMerchantId', // 跳转公共服务
      showWholeMarket: '/merchant/api/merchantInfo/showWholeMarket',
      checkIdCard: '/merchant/api/checkIdCard', // 校验身份证是否有绿码
      showQyBindTab: '/merchant/api/merchantInfo/showQyBindTab',
      getGraytatus: '/merchant/api/merchantInfo/getGraytatus', // 个人中心订单灰度链接接口
      getElectronicInvoiceRouteInfo: '/merchant/api/merchantInfo/getElectronicInvoiceRouteInfo', // 电子发票跳转中台接口
    },
    detailimgs: '/product/api/product/getPicturesByModelCode',
    ratecode: {
      ruleBuy: '/product/api/product/ruleBuy',
      stockprices: '/product/api/product/getDailyStock',
    },
    comment: {
      upData: '/merchant/api/merchantProdComments/doUpdateMerchantProdComments',
      list: '/merchant/api/merchantProdComments/comment/list',
      oldList: '/merchant/api/merchantProdComments/getComment',
      add: '/merchant/api/merchantProdComments/saveMerchantProdComments',
      iscomment: '/merchant/api/merchantProdComments/userIfEvaluate',
      update: '/merchant/api/merchantProdComments/updateComment',
    },
    marketing: {
      couponsList: '/marketing/api/marketingCoupon/listCouponTempletVoPage',
      getCoupo: '/marketing/api/marketingCoupon/getCouponDetailVo',
      myCoupons: '/marketing/api/marketingCoupon/listCouponDetailVoPage', // 用户查询已领优惠券
      useCoupons: '/marketing/api/marketingCoupon/listUseCouponDetailVo', // 用户可用优惠券
      findBuySendRule: '/marketing/api/marketBuySend/findBuySendRule',
      findBuySendRuleAndReceive: '/marketing/api/marketBuySend/findBuySendRuleAndReceive',
      selectServiceUse: '/marketing/api/market/selectServiceUse', // 判断服务是否购买或过期
      getByMerchantId: '/marketing/api/wxAttractFansToc/getByMerchantId', // 微信吸粉
      userFollowCheck: '/marketing/api/wxAttractFansToc/userFollowCheck',
      getWxPublicUrl: '/marketing/api/wxAttractFansToc/getWxPublicUrl',
    },
    pay: {
      getPayInfo: '/pay/api/getPayInfo',
      goPay: '/order/orderPay/toPay',
      doRefund: '/pay/api/leaguer/manage/doRefund',
      result: '/pay/api/leaguer/manage/getPayOrder',
      notify: '/pay/api/toAliPay/notify_url',
    },
    wechat: {
      Authorization: 'https://open.weixin.qq.com/connect/oauth2/authorize',
      accessToken: 'https://api.weixin.qq.com/sns/oauth2/access_token',
      token: 'https://api.weixin.qq.com/cgi-bin/token',
      getTicket: 'https://api.weixin.qq.com/cgi-bin/ticket/getticket',
      wxUserInfo: 'https://api.weixin.qq.com/sns/userinfo',
      toWxpay: '/pay/api/toWxpay/webPay',
      tozybwxpay: '/pay/api/toWxpay/zybWebPay',
      wxPayResult: '/pay/api/toWxpay/payResult',
      getWxToken: '/merchant/api/merchantInfo/getWxToken',
      getWxCardOrderList: '/order/api/wxCardApi/getWxCardOrderListByPayOrderNo',
    },
    cart: {
      add: '/cart/api/leaguer/manage/addCartItem',
      list: '/cart/api/leaguer/manage/getCart',
      delete: '/cart/api/leaguer/manage/removeCartItem',
      updata: '/cart/api/leaguer/manage/updateCart',
    },
    address: {
      list: '/leaguer/api/userLeaguerAddress/manage/leaguerAddressPage', // 收货地址列表
      detail: '/leaguer/api/userLeaguerAddress/manage/leaguerAddressInfo', // 地址详情
      delete: '/leaguer/api/userLeaguerAddress/manage/delLeaguerAddress', // 删除
      save: '/leaguer/api/userLeaguerAddress/manage/saveLeaguerAddress', // 地址保存
      default: '/leaguer/api/userLeaguerAddress/manage/setDefaultAddress', // 设置默认地址
      selfPlace: '/leaguer/api/order/manage/selfPlace', // 获取自提点地址
    },
    express: '/leaguer/api/order/manage/express',
    order: {
      getRecommendPark: '/leaguer/api/leaguerRecommend/getRecommentProduct',
    },
  },
  detailimgs: '/product/api/product/getPicturesByModelCode',
  productGroup: '/product/api/productGroup/productGroupInfoList',
  ratecode: {
    ruleBuy: '/product/api/product/ruleBuy',
    stockprices: '/product/api/product/getDailyStock',
  },
  comment: {
    upData: '/merchant/api/merchantProdComments/doUpdateMerchantProdComments',
    list: '/merchant/api/merchantProdComments/getComment',
    add: '/merchant/api/merchantProdComments/saveMerchantProdComments',
    iscomment: '/merchant/api/merchantProdComments/userIfEvaluate',
  },
  marketing: {
    couponsList: '/marketing/api/marketingCoupon/listCouponTempletVoPage',
    getCoupon: '/marketing/api/marketingCoupon/getCouponDetailVo',
    myCoupons: '/marketing/api/marketingCoupon/listCouponDetailVoPage', // 用户查询已领优惠券
    useCoupons: '/marketing/api/marketingCoupon/listUseCouponDetailVo', // 用户可用优惠券
    findBuySendRule: '/marketing/api/marketBuySend/findBuySendRule',
    selectServiceUse: '/marketing/api/market/selectServiceUse', // 判断服务是否购买或过期
    userFollowCheck: '/marketing/api/wxAttractFansToc/userFollowCheck',
    getWxPublicUrl: '/marketing/api/wxAttractFansToc/getWxPublicUrl',
  },
  pay: {
    getPayInfo: '/pay/api/getPayInfo',
    goPay: '/order/orderPay/toPay',
    doRefund: '/pay/api/leaguer/manage/doRefund',
    result: '/pay/api/leaguer/manage/getPayOrder',
    notify: '/pay/api/toAliPay/notify_url',
  },
  wechat: {
    Authorization: 'https://open.weixin.qq.com/connect/oauth2/authorize',
    accessToken: 'https://api.weixin.qq.com/sns/oauth2/access_token',
    token: 'https://api.weixin.qq.com/cgi-bin/token',
    getTicket: 'https://api.weixin.qq.com/cgi-bin/ticket/getticket',
    wxUserInfo: 'https://api.weixin.qq.com/sns/userinfo',
    toWxpay: '/pay/api/toWxpay/webPay',
    tozybwxpay: '/pay/api/toWxpay/zybWebPay',
    wxPayResult: '/pay/api/toWxpay/payResult',
  },
  cart: {
    add: '/cart/api/leaguer/manage/addCartItem',
    list: '/cart/api/leaguer',
    delete: '/cart/api/leaguer/manage/removeCartItem',
    updata: '/cart/api/leaguer/manage/updateCart',
  },
  address: {
    list: '/leaguer/api/userLeaguerAddress/manage/leaguerAddressPage', // 收货地址列表
    detail: '/leaguer/api/userLeaguerAddress/manage/leaguerAddressInfo', // 地址详情
    delete: '/leaguer/api/userLeaguerAddress/manage/delLeaguerAddress', // 删除
    save: '/leaguer/api/userLeaguerAddress/manage/saveLeaguerAddress', // 地址保存
    default: '/leaguer/api/userLeaguerAddress/manage/setDefaultAddress', // 设置默认地址
  },
  express: '/leaguer/api/order/manage/express',
  // "order": {
  //   "getRecommendPark": "/leaguer/api/leaguerRecommend/getRecommentProduct"
  // },
  order: {
    saveOrder: '/order/api/saveOrder', // 下单
    detail: '/order/api/getPayOrder', // 获取订单支付信息
  },
  ticket: {
    list: {
      pagelist: '/merchant/api/merchantParkInfo/merchantParkInfoGrid',
    },
    detail: {
      main: '/merchant/api/merchantParkInfo/merchantParkInfo',
      productItems: '/merchant/api/merchantParkTicketGridNew',
      getSaleStatusByModelCode: '/merchant/api/getSaleStatusByModelCode',
      titleSetting: '/merchant/api/merchantParkInfo/getMerchantParkInfoTitleSetting',
    },
    order: {
      main: '/merchant/api/leaguer/manage/merchantParkTicket',
    },
  },
  tickets: {
    list: {
      pagelist: '/merchant/api/merchantFamilyInfo/list',
    },
    detail: {
      main: '/merchant/api/merchantFamilyInfo/familyDetail',
      productItems: '/merchant/api/merchantParkTicketGridNew',
      getSaleStatusByModelCode: '/merchant/api/getSaleStatusByModelCode',
    },
    order: {
      main: '/merchant/api/leaguer/manage/merchantParkTicket',
    },
  },
  guide: {
    list: {
      pagelist: '/merchant/api/merchantChargeGuideInfo/list',
    },
    detail: {
      main: '/merchant/api/merchantChargeGuideInfo/guideDetail',
    },
  },
  hotel: {
    list: {
      pagelist: '/merchant/api/merchantHotelInfo/merchantHotelInfoGrid',
    },
    detail: {
      main: '/merchant/api/merchantFamilyInfo/familyDetail',
      productItems: '/merchant/api/merchantParkTicketGridNew',
      getSaleStatusByModelCode: '/merchant/api/getSaleStatusByModelCode',
    },
    order: {
      main: '/merchant/api/leaguer/manage/merchantParkTicket',
    },
    transform: {
      idByCode: '/merchant/api/pmsMerchantHotelType/getProductIdByCode',
    },
  },
  route: {
    list: {
      pagelist: '/merchant/api/merchantRouteInfo/merchantRouteInfoGrid',
      getSerchByMerchantId: '/merchant/api/merchantRouteInfo/getSerchByMerchantId',
    },
    detail: {
      main: '/merchant/api/merchantRouteInfo/getMerchantRouteInfoById',
      productItems: '/merchant/api/merchantHotelRoom/merchantHotelRoomGrid',
      calendarPrice: '/merchant/api/merchantRouteInfo/listCalendarPriceMap',
      checkRuleBuy: '/order/api/checkRuleBuy',
    },
    order: {
      main: '/merchant/api/merchantHotelRoom/merchantHotelRoom',
    },
  },
  other: {
    note: {
      main: '/order/qr/getOrderQRCode',
    },
  },
  theater: {
    list: {
      pagelist: '/merchant/api/theater/merchantTheaterShowGrid',
    },
    detail: {
      main: '/merchant/api/theater/merchantTheaterShow',
      productItems: '/merchant/api/merchantHotelRoom/merchantHotelRoomGrid',
    },
    order: {
      main: '/merchant/api/merchantHotelRoom/merchantHotelRoom',
    },
  },
  shop: {
    list: {
      pagelist: '/merchant/api/merchantMsdeInfo/listMerchantMsdeInfo', // 商品列表
    },
    detail: {
      main: '/merchant/api/merchantMsdeInfo/getMerchantMsdeInfo', // 商品详情
      revieMain: '/merchant/api/merchantMsdeInfo/mdseSnapshot', // 商品详情快照 -- 审核
      sku: '/merchant/api/merchantMsdeInfo/getMerchantMsdeSKu', // sku
      reviewSku: '/merchant/api/merchantMsdeInfo/mdseSkuSnapshot', // sku -- 审核
      checkSelloutByCode: '/merchant/api/merchantMsdeInfo/checkSelloutByCode', // 校验sku是否有库存
    },
    order: {
      main: '/merchant/api/merchantMsdeInfo/getMerchantMsdeSKu',
      getMerchantMdseDetailByCode: '/merchant/api/merchantMsdeInfo/getMerchantMdseDetailByCode',
      listPoint: '/product/api/shop/listPoint',
      address: '/merchant/api/merchantMsdeInfo/getArea',
      getPostage: '/merchant/api/merchantMsdeInfo/getPostagePrice', // 获取邮费
      getStock: '/product/api/shop/getStock',
      selfPlace: '/leaguer/api/order/manage/selfPlace', // 自提点
      bonus: '/leaguer/api/leaguer/pubs/bonus/deduction', // 获取积分
      saveOrder: '/order/api/saveOrder',
      selfPlaceByIds: '/leaguer/api/order/manage/selfPlaceByIds', // 根据多个商品查询自提地址
    },
  },
  repast: {
    list: {
      pagelist: '/merchant/api/merchantRepastEatery/merchantRepastEateryGrid',
    },
    detail: {
      main: '/merchant/api/merchantRepastEatery/getRepastEateryVoById',
      mealCoupon: '/merchant/api/merchantRepastEatery/getRepastCouponVoById',
    },
  },
  strategy: {
    list: {
      pagelist: '/merchant/api/merchantStrategy/merchantStrategyGrid',
    },
    detail: {
      main: '/merchant/api/merchantStrategy/getMerchantStrategyById',
    },
  },
  coupons: {
    list: {
      main: '/leaguer/api/coupon/couponPage',
      member: '/leaguer/api/coupon/manage/myCoupon',
      usableCoupons: '/leaguer/api/coupon/manage/listAbleCoupon',
    },
    detail: {
      main: '/leaguer/api/coupon/manage/queryCouponInfo',
    },
    getCoupons: {
      main: '/leaguer/api/coupon/manage/receiveCoupon',
    },
  },
  yearcard: {
    list: {
      pagelist: '/marketing/api/yearCardToc/selectList',
    },
    detail: {
      main: '/marketing/api/yearCardTob/selectById',
    },
    order: {
      main: '/yearcard/api/saveOrder',
      refund: '/yearcard/orderApi/orderRefund',
      newMain: '/yearcard/orderApi/saveOrder',
      cancel: '/yearcard/orderApi/orderCancel',
      renewals: '/yearcard/orderApi/renewals',
      detail: '/yearcard/orderApi/getYearCardDetail',
    },
    qr: {
      bind: '/yearcard/orderApi/openQrCode',
      unbind: '/yearcard/orderApi/closeQrCode',
      beforGetQr: '/yearcard/orderApi/beforUseQrCode',
      beforBindQr: '/yearcard/orderApi/beforOpenQrCode',
      getQr: '/yearcard/orderApi/useQrCode',
    },
  },
  member: {
    login: {
      otherLogin: '/leaguer/api/userLeaguer/thirdLogin',
    },
    delOrder: '/leaguer/api/order/manage/delOrder', // 删除订单
    register: '/leaguer/api/userLeaguer/leaguerRegister',
    logout: '/leaguer/api/userLeaguer/manage/leaguerLogout',
    info: '/leaguer/api/userLeaguer/manage/leaguerInfo',
    modify: '/leaguer/api/userLeaguer/manage/leaguerUpdate',
    leaguerFixPwd: '/up/api/account/password',
    totalPerson: '/leaguer/api/other/manage/saveVisitRecord',
    unbindPhone: '/leaguer/api/userLeaguer/unbindPhone',
    getLeaguerInfo: '/leaguer/api/pubs/leaguer/web/leaguer/getLeaguerInfo',
    enterPromote: {
      main: 'http://192.168.200.40:8080/wap/enterPromote.htm',
      loginWapAuthor: 'http://192.168.200.40:8080/wap/loginWapAuthor.htm',
    },
    order: {
      pagelist: '/leaguer/api/order/manage/orderPage', // 全部订单除去待付款订单
      waitPayList: '/order/api/getPayOrderList', // 待付款订单
      detail: '/leaguer/api/order/manage/orderQuery', // 订单详情
      refund: '/leaguer/api/order/manage/retreat', //
      cancel: '/order/api/cancelOrder', // 取消订单
      payorder: '/order/api/getPayOrderList',
      getPayOrder: '/order/api/getPayOrder',
      refundDetail: '/leaguer/api/order/manage/retreatDetail', // 退单详情
      warnsendgoods: '/leaguer/api/order/manage/warnSendGoods', // 提醒发货
      cancelRetreat: '/leaguer/api/order/manage/cancelRetreat', // 取消退单申请
      retreatSendGoods: '/leaguer/api/order/manage/retreatSendGoods',
      confirmGoods: '/leaguer/api/order/manage/confirmGoods',
      getMerchantMdseDetailByCode: '/merchant/api/merchantMsdeInfo/getMerchantMdseDetailByCode',
      getQrCodeUrl: '/admin/api/ui/url2QrCode', // 接口二维码
      orderQueryByOrderId: '/leaguer/api/order/manage/orderQueryByOrderId',
      discount: '/leaguer/api/leaguer/pubs/product/discount', // 获取会员价
      noEvaluateNum: '/leaguer/api/order/manage/noCommentOrderCount', // 订单未评价数量
      noCommentOrderPage: '/leaguer/api/order/manage/noCommentOrderPage', // 订单未评价数量
      getMyCommentList: 'merchant/api/merchantProdComments/getMyCommentList', // 订单未评价数量
      getOrderCount: '/leaguer/api/order/manage/orderCount', // 订单数量
    },
    yearcard: {
      selectOrderList: '/leaguer/api/yearCardOrder/selectOrderList',
      selectOrderDetil: '/leaguer/api/yearCardOrder/selectOrderDetil',
      normalQuestions: '/marketing/api/yearCardToc/get/questionUrl',
    },
    face: {
      queryCertOrder: '/leaguer/api/order/queryCertOrder',
      queryOrderDetail: '/leaguer/api/order/queryOrderDetail',
      addOrderFace: '/leaguer/api/order/addOrderFace',
      getFaceRecordSet: '/marketing/api/faceRecordSet/getFaceRecordSet',
    },
    pubs: {
      help: '/leaguer/api/userLeaguer/pubs/login/help',
    },
  },
  group: {
    selectByStatus: '/marketing/api/selectByStatus',
    list: '/leaguer/api/order/manage/teamPage',
    detail: '/leaguer/api/order/manage/teamQuery',
  },
  contacts: {
    // 常用联系人
    getContactsList: '/up/api/user/contacts/list',
    saveContacts: '/up/api/user/contacts',
    deleteContacts: '/up/api/user/contacts/',
    selectContactsById: '/up/api/user/contacts/',
  },
  invoices: {
    pageList: '/marketing/api/marketElectronicInvoices/pageInvoiceReq',
    saveInvoiceInfo: '/marketing/api/marketElectronicInvoices/createInvoiceReq',
    detail: '/marketing/api/marketElectronicInvoices/detailInvoiceReq',
    rate: '/marketing/api/marketElectronicInvoices/rateInvoiceReq',
    enabled: '/marketing/api/marketElectronicInvoices/enabledInvoiceReq',
    selectServiceUse: '/marketing/api/market/selectServiceUse',
  },
  // 积分商城
  bonus: {
    commodityList: '/leaguer/api/bonus/grid',
    commodityDetail: '/leaguer/api/bonus/detail/',
    userBonusInfo: '/leaguer/api/bonus/leaguer/bonus',
    userBonusDetail: '/leaguer/api/bonus/leaguer/bonus/record',
    checkEnableExchange: '/leaguer/api/bonus/checkIsEnableExchange/',
    getOverdueRemind: '/leaguer/api/pubs/leaguer/web/bonus/getOverdueRemind',
    queryLog: '/leaguer/api/pubs/leaguer/web/bonus/queryLog',
  },
  location: {
    getCode: '/admin/api/area/selectByAreaName',
    getAreaByPosition: '/admin/api/area/getAreaByLatAndLng',
  },
  collect: {
    getCorpCode: '/merchant/api/merchantInfo/getCorpCode',
    page: '/leaguer/api/favorite/grid',
    del: '/leaguer/api/favorite/del',
  },
  common: {
    getCorpCode: '/merchant/api/merchantInfo/getCorpCode',
  },
  sqfx: {
    queryProduct: '/sendinfo-promotion/cMarketingProductService/queryProductByCache', // 产品推广列表接口
    listAddress: '/sendinfo-promotion/cMaterialService/listAddressPage', // 活动推广列表接口
    listAddressMappingPosterUrls: '/sendinfo-promotion/cMaterialService/listAddressMappingPosterUrls', // 查询地址关联海报 生成海报
    queryPromotionOrder: '/sendinfo-promotion/cOrderService/queryPromotionOrder', // C端查询推广订单列表
    queryPromotionOrderDetail: '/sendinfo-promotion/cOrderService/queryPromotionOrderDetail', // C端查询推广订单详情信息
    queryInvitationOrder: '/sendinfo-promotion/cOrderService/queryInvitationOrder', // C端查询推广C端查询邀请订单列表列表
    queryInvitationOrderDetail: '/sendinfo-promotion/cOrderService/queryInvitationOrderDetail', // C端查询推广订单详情C端查询邀请订单详情信息
    queryPromotionResultDetailPage: '/sendinfo-promotion/cPromotionResultDetailService/queryPromotionResultDetailPage', // C端获取待提现订单列表
    queryPromotionStatistics: '/sendinfo-promotion/cPromotionResultDetailService/queryPromotionStatistics', // C端获总订单数，总佣金
    saveSettlement: '/sendinfo-promotion/cSettlementService/saveSettlement', // C端提现
    queryCSettlementPage: '/sendinfo-promotion/cSettlementService/queryCSettlementPage', // 分页获取提现记录
    queryCSettlementDetailPage: '/sendinfo-promotion/cSettlementService/queryCSettlementDetailPage', // 分页获取提现明细
    queryWxAuthUrl: '/sendinfo-promotion/cWxParamService/queryWxAuthUrl', // 获取微信收款授权地址
    queryPersonalInfo: '/sendinfo-promotion/cPersonalService/queryPersonalInfo', // 获取微信收款授权地址
    updateBasePersonalInfo: '/sendinfo-promotion/cPersonalService/updateBasePersonalInfo', // 更新个人信息资料
    saveWxName: '/sendinfo-promotion/cWxParamService/saveWxName', // 保存微信收款真实姓名
    queryCWxParam: '/sendinfo-promotion/cWxParamService/queryCWxParam', // C端查询微信收款信息
    queryProductShare: '/sendinfo-promotion/cMarketingProductService/queryProductShare', // C端查询产品分享接口
    getPromoterByPromoteCode: '/sendinfo-promotion/userService/getPromoterByPromoteCode', // 根据推广码获取推广员信息
    checkUserPromoter: '/leaguer/api/promoter/checkUserPromoter', // 判断小鲸用户否为新全员系统推广员
    checkBindingMobile: '/leaguer/api/userLeaguer/checkBindingMobile', // 判断小鲸用户是否绑定手机号
    sendCheckCode: '/leaguer/api/userLeaguer/sendCheckCode', // 发送短信验证码
    bindingMobile: '/leaguer/api/userLeaguer/bindingMobile', // 用户绑定手机号
    checkCorpInviteUser: '/merchant/api/merchantWholeMarket/checkCorpInviteUser', // 判断是否是景区邀请注册白名单用户
    createUserAuth: '/leaguer/api/promoter/createUserAuth', // 注册成为全员推广员
    getInvitePoster: '/sendinfo-promotion/userInviteService/getInvitePoster', // 邀请海报链接
    pageInviteRecord: '/sendinfo-promotion/userInviteService/pageInviteRecord', // 我的邀请列表
    getMerchantPattern: '/merchant/api/merchantWholeMarket/getMerchantPattern', // 判断小鲸店铺全员推广新老模式
    getCorpConfig: '/leaguer/api/promoter/getCorpConfig',
    queryDistributionPromoterProductByCache: '/sendinfo-promotion/cMarketingProductService/queryDistributionPromoterProductByCache',
    queryUserInfo: '/sendinfo-promotion/cPersonalService/queryUserInfo',
    bindMobile: '/sendinfo-promotion/cPersonalService/bindPersonalMobile',
    pageLockFansRecord: '/sendinfo-promotion/lockFansService/pageLockFansRecord', // 我的邀请-锁粉记录
    queryUserGradeInfo: '/sendinfo-promotion/userGradeService/queryUserGradeInfo', // 获取推广员等级信息
    queryAddressQrCode: '/sendinfo-promotion/cMaterialService/queryAddressQrCode', // 查询活动推广二维码或小程序码
    isIdCardDisplay: '/sendinfo-promotion/cWxParamService/isIdCardDisplay', // 结算账户授权后身份证显示
    queryRestrictPromoterInvitation: '/sendinfo-promotion/cPersonalService/queryRestrictPromoterInvitation', // 查询推广员邀请禁用
    queryIsOpenServiceCharge: '/sendinfo-promotion/cPromotionResultDetailService/queryIsOpenServiceCharge', // 查询提现手续费开关
    queryProductByPromoteCode: '/sendinfo-promotion/cMarketingProductService/queryProductByPromoteCode',
    queryPersonalPolymerizeCode: '/sendinfo-promotion/cPersonalService/queryPersonalPolymerizeCode',
    queryOldQyyxLoginInfo: '/sendinfo-promotion/cPersonalService/queryOldQyyxLoginInfo', // 查询老全员迁移信息
    queryCFreshNoticeInfo: '/sendinfo-promotion/cNoticeInfoService/queryCFreshNoticeInfo', // 获取最新公告
    queryCNoticeInfoPage: '/sendinfo-promotion/cNoticeInfoService/queryCNoticeInfoPage', // 获取公告列表
    freshCNoticeInfo: '/sendinfo-promotion/cNoticeInfoService/freshCNoticeInfo', // 变更公告状态为已读
    queryCNoticeInfoDetail: '/sendinfo-promotion/cNoticeInfoService/queryCNoticeInfoDetail',
    selectSysParamByCode: '/sendinfo-promotion/cPersonalService/selectSysParamByCode',
    queryDistributorOptionalProduct: '/sendinfo-promotion/cMarketingProductService/queryDistributorOptionalProduct', // 分销商获取产品列表
    queryPromoterPage: '/sendinfo-promotion/DistributorService/queryPromoterPage', // 推广员列表
    getPromoterInfo: '/sendinfo-promotion/DistributorService/getPromoterInfo', // 推广员详情
    listCorpWhiteListPage: '/sendinfo-promotion/DistributorService/listCorpWhiteListPage', // 邀请白名单列表
    saveCorpWhiteList: '/sendinfo-promotion/DistributorService/saveCorpWhiteList', // 新增白名单
    delCorpWhiteList: '/sendinfo-promotion/DistributorService/delCorpWhiteList', // 删除白名单
    isSettlement: '/sendinfo-promotion/cSettlementService/isSettlement', // 提现配置
    getIncomingPartsUrl: '/sendinfo-promotion/cSettlementService/getIncomingPartsUrl', // 获取推广员易生支付进件地址
    getIncomingPartsStatus: '/sendinfo-promotion/cSettlementService/getIncomingPartsStatus', // 获取易生支付进件状态
    selectBankCardInfo: '/sendinfo-promotion/cSettlementService/selectBankCardInfo', // 获取易生支付进件绑卡信息
    selectYSPayAstrict: '/sendinfo-promotion/cSettlementService/selectYSPayAstrict', // 获取当前店铺是否使用易生支付
    yeePayAccountInfo: '/sendinfo-promotion/cSettlementService/yeePayAccountInfo', // 获取易宝支付账户信息
    yeePayApplyWithdrawal: '/sendinfo-promotion/cSettlementService/yeePayApplyWithdrawal', // 易宝账户提现
    yeePayIncomingPartsStatus: '/sendinfo-promotion/cSettlementService/yeePayIncomingPartsStatus', // 获取易宝绑卡状态
    yeePayIncomingPartsUrl: '/sendinfo-promotion/cSettlementService/yeePayIncomingPartsUrl', // 获取易宝进件地址
    yeePayApplyWithdrawalRecords: '/sendinfo-promotion/cSettlementService/yeePayApplyWithdrawalRecords', // 获取提现记录
    yeePayApplyWithdrawalRecordById: '/sendinfo-promotion/cSettlementService/yeePayApplyWithdrawalRecordById', // 获取提现记录详情
    zCentPayIncomingPartsFlag: '/sendinfo-promotion/cSettlementService/zCentPayIncomingPartsFlag', // 获取众薪分佣进件标识
    zCentPayIncomingPartsUrl: '/sendinfo-promotion/cSettlementService/zCentPayIncomingPartsUrl', // 获取众薪进件地址
    zCentPayIncomingPartsStatus: '/sendinfo-promotion/cSettlementService/zCentPayIncomingPartsStatus', // 获取众薪进件状态
    zCentPayBankCardInfo: '/sendinfo-promotion/cSettlementService/zCentPayBankCardInfo', // 众薪绑卡信息
    promoterRegisterGroups: '/sendinfo-promotion/cPersonalService/promoterRegisterGroups', // 分组信息查询
    selectAppointConfig: '/sendinfo-promotion/cMaterialService/selectAppointConfig', // 获取综合配置项
  },
};
