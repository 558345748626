<template>
  <div class="app-container">
    <keep-alive>
      <router-view
        v-if="$route.meta.keepAlive"
      ></router-view>
    </keep-alive>

    <router-view
      v-if="!$route.meta.keepAlive"
    ></router-view>
    <!-- 导航栏 -->
    <NavModule v-if="navModuleShow"></NavModule>
  </div>
</template>
<script>
import './assets/styles/base.less';
import './assets/styles/common.less';
import { mapGetters } from 'vuex';
import { qyyxUrl } from '@/common/config.js';
import VueCookies from 'vue-cookies';
import { getUrlQuery } from '@/utils';
import NavModule from '@/components/navModule';

export default {
  name: 'App',
  components: {
    NavModule,
  },
  computed: {
    ...mapGetters('sqfx', ['isThirdPart']),
    code() {
      return this.$route.query;
    },
    navModuleShow() {
      const { tag } = this.$route.meta;
      if (tag === 'sqfx') {
        return false;
      } else {
        return !this.isThirdPart;
      }
    },
  },
  watch: {
    code() {
      // 获取cookie中的spread_code，spread_code来源两种：1、上面代码存储 2、node页面存储
      const spread_code_local = VueCookies.get(
        `spread_code_${ localStorage.getItem('xjsc_vue_2018_12_19_mid')}`,
      );
      if (spread_code_local) {
        const local_code = spread_code_local.split('-')[1];
        if (local_code) {
          // 保证节点的唯一性
          VueCookies.set(`qyyx_code_${ local_code}`, spread_code_local);
          const idObject = document.getElementById('statis-qyyx');
          if (idObject !== null) {
            // js已加载过
            window[`_bdhm_loaded_${ local_code}`] = undefined;
            idObject.parentNode.removeChild(idObject);
          }
          const hm = document.createElement('script');
          hm.id = 'statis-qyyx';
          hm.src = `${qyyxUrl }/static/h-ui/js/pro.js?${ local_code}`;
          const s = document.getElementsByTagName('script')[0];
          s.parentNode.insertBefore(hm, s);
        }
      }
    },
  },
  created() {
    this.setLotsUnitCookie();
    // vuex储存店铺id
    this.initMid();
  },
  methods: {
    getUrlValue(name) { // 获取url里的参数
      return (
        (new RegExp(`[?|&]${ name }=([^&;]+?)(&|#|;|$)`).exec(
          location.href,
        // eslint-disable-next-line no-sparse-arrays
        ) || [, ''])[1].replace(/\+/g, '%20')
        || ''
      );
    },
    setLotsUnitCookie() { // 全局（许忠林要求）注入联盟cookie，用于分润
      const mkPromoteCode = this.getUrlValue('mkPromoteCode');
      const m_id = this.getUrlValue('m_id');
      if (typeof mkPromoteCode !== 'undefined' && mkPromoteCode && typeof m_id !== 'undefined' && m_id) {
        VueCookies.set(`mk_promote_code${ m_id}`, mkPromoteCode, (2 * 60 * 1000), null, window.location.hostname);
        VueCookies.set('mk_promote_code', mkPromoteCode, (2 * 60 * 1000), null, window.location.hostname);
      }
    },
    initMid() {
      const query = getUrlQuery(window.location.href);
      if (query.m_id) {
        this.$store.commit('updateMid', query.m_id);
      }
    },
  },
};
</script>
<style lang="scss">
[aspectratio] {
  position: relative;
}
[aspectratio]::before {
  content: '';
  display: block;
  width: 1px;
  margin-left: -1px;
  height: 0;
}

[aspectratio-content] {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.app-container {
  height: 100%;
}

.pswp--open {
  z-index: 99999!important;
}

.iconfont-svg {
  width: 1em; height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
.overflow {
  overflow: hidden;
  height: 100%;
}
.router-fade-enter-active,
.router-fade-leave-active {
  transition: opacity 0.3s;
}
.router-fade-enter,
.router-fade-leave-active {
  opacity: 0;
}

.m-b-10 {
  margin-bottom: 10px;
}

.f-b {
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
}

.f-b-btn {
  height: 49px;
  line-height: 49px;
  text-align: center;
  color: #fff;
  font-size: 16px;
}

.add-sub {
  display: flex;
  .handle-btn {
    height: 29px;
    line-height: 29px;
    display: inline-block;
    width: 44px;
    border: 1px solid #f05b47;
    text-align: center;
    color: #f05b47;
    font-size: 18px;
  }
  .sub {
    width: 39px;
    border-right: none;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  .add {
    width: 38px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .no-num {
    border-color: #ffc9c9;
    color: #ffc9c9;
  }
}
</style>

<style lang="less">
@import "./vux/styles/1px.less";
@import "./assets/styles/reset.css";

html,
body {
  width: 100%;
  height: 100%;
  -webkit-text-size-adjust: none;
}

html {
  min-height: 100%;
  height: auto;
}

body {
  background-color: #fbf9fe;
  font-family: -apple-system, BlinkMacSystemFont, 'PingFang SC',
    'Helvetica Neue', Helvetica, Segoe UI, Arial, Roboto, 'miui',
    'Hiragino Sans GB', 'Microsoft Yahei', sans-serif;
  font-size: 12px;
  min-height: 100%;
  height: auto;
  position: relative;
}

input[type="text"],
input[type="password"] {
  outline: medium;
}
input[type="submit"],
input[type="reset"],
input[type="button"],
button,
input[type="number"] {
  -webkit-appearance: none;
}

.clearfix:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
  font-size: 0;
  line-height: 0;
}
.clearfix {
  display: block;
}

.body-ce {
  background-color: #cecece;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="text"]::-webkit-inner-spin-button,
input[type="password"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  border-radius: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.fr {
  float: right;
}
.fl {
  float: left;
}
.tr {
  text-align: right;
}
.ofh {
  overflow: hidden;
}
.ml10 {
  margin-left: 10vw;
}
.mt10 {
  margin-top: 10vw;
}
.w100 {
  width: 100%;
}

.color1 {
  color: #999;
}
.none {
  display: none;
}
button {
  outline: none;
  border: 0;
}

html {
  font-size: 37.5PX;
  font-size: 10vw;
}

@media screen and (max-width: 320px) {
  html {
    font-size: 32PX;
  }
  html .app-container {
    width: 320PX;
  }
}

@media screen and (min-width: 540px) {
  html {
    font-size: 54PX;
  }
  html .app-container {
    width: 540PX;
    margin: 0 auto;
  }
}

</style>
